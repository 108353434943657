import React,{useState,useEffect} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import JobsFilterSidebar from '../../components/JobsFilterSidebar';
import JobsFilterTop from '../../components/JobsFilterTop';
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap'

const tooglefilter = () => { 
    var toggleelem = document.querySelector('.link-siltertoggler');
    var toggleelemdiv = document.querySelector('.sidebar-filters');
    if(toggleelemdiv.classList.contains('opensidebar')){
     toggleelemdiv.classList.remove("opensidebar");
    }else{
    toggleelemdiv.classList.add("opensidebar");
    }
  }

const JobsPageBody = () => {

    const [allPostList, setAllPostList] = useState([]);
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [totalPageLength, setTotalPageLength] = useState(1000);
    const [cityList, setCityList] = useState([]);
    
    const [locationStateName, setLocationStateName] = useState([]);
    const [jobFilterOption, setJobFilterOption] = useState([]);
    const [recJobstypeOpt, setRecJobstypeOpt] = useState([]);
    //recruiter_jobstype

    const [jobFilterEmpType, setJobFilterEmpType] = useState([]);
    const [jobIndustry, setJobIndustry] = useState([]);
    const [jobFilterCategoryType, setJobFilterCategoryType] = useState([]);

    const [responce, setResponce] = useState([]);
    const [appliedJobs, setAppliedJobs] = useState([]);
    const [favoriteJobs, setFavoriteJobs] = useState([]);
    
    const [jobsAdvanceFilterCheck, setJobsAdvanceFilterCheck] = useState({
        job_category: [],
        jobs_employment_type: [],
    });

    const [searchByJobTitle, setSearchByJobTitle] = useState('');
    const [searchByJobSkills, setSearchByJobSkills] = useState('');
    const [searchByJobStateId, setSearchByJobStateId] = useState('');

    const [jobIndustryCheck, setJobIndustryCheck] = useState([]);
    const [jobCategoryCheck, setJobCategoryCheck] = useState([]);
    const [jobsEmployementCheck, setJobsEmployementCheck] = useState([]);
    const [jobStatusForJoinCheck, setJobStatusForJoinCheck] = useState([]);
    //const delay = (ms) => new Promise(res => setTimeout(res, ms));

    const [hideAndSeekIndustry, sethideAndSeekIndustry] = useState('show');
    const [hideAndSeekJoiningStatus, sethideAndSeekJoiningStatus] = useState('not');
    const [hideAndSeekJobType, sethideAndSeekJobType] = useState('not');
    const [hideAndSeekJobCategory, sethideAndSeekJobCategory] = useState('not');

    const auth = localStorage.getItem('userlogin');
    
    useEffect(() => {
        
        (async () => {
            // try {
            //     const queryParameters = new URLSearchParams(window.location.search);
            //     const candidate = queryParameters.get("candidate_id");
            //     if(candidate){
            //         search_and_get_results2('','','',candidate)
            //     } else {
            //         search_and_get_results2()
            //     }
            // } catch (error) { console.log(error); }

            try {
                const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/get_jobs_location");
                if(states_list.data.status == 1){ 
                    setLocationStateName(states_list.data.data.stateid_and_name)
                    setJobIndustry(states_list.data.data.job_industry)
                    setJobFilterEmpType(states_list.data.data.jobs_employment_type) 
                    setJobFilterCategoryType(states_list.data.data.job_category) 
                    setRecJobstypeOpt(states_list.data.data.recruiter_jobstype) 
                    setJobFilterOption(states_list.data.data) 
                }
            } catch (error) { console.log(error);}
          
            let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
            if(userdata){
                try {
                    const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/total_applied_jobs/"+userdata.id);
                    if(states_list.data.status == 1){
                        setAppliedJobs(states_list.data.data.applied_id)
                        setFavoriteJobs(states_list.data.data.favorite_id)
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            const queryParameters = new URLSearchParams(window.location.search)
            const skills = queryParameters.get("skills");
            const location = queryParameters.get("location");


            // if(skills){
            //     setSearchByJobSkills(skills)
            //      //search_and_ajax_results(skills ,  location)
            // }
        })();

    }, []);


    //isProgress
    const [isProgress, setIsProgress] = useState(false);
    const applied_jobs = (e) => {
        let userdata  = JSON.parse(localStorage.getItem("userloginSession"));

        if(userdata.usertype == 3){

            if(userdata.user_resume){

                setIsProgress(true);
                const  API =  'https://us.jobchatbox.com/api/home/api/jobs_apply';
                const formData = new FormData();
                formData.append('job_id', e.currentTarget.id);
                formData.append('user_id', userdata.id );
                (async () => {
                    try {
                        let res = await fetch(API , {
                            method: "POST",
                            body: formData ,
                        });
                        let resJson = await res.json();
                        (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                        if(resJson.status){
                            setIsProgress(false);
                            setAppliedJobs(resJson.data.applied_id)
                            setFavoriteJobs(resJson.data.favorite_id)
                        }
                    } catch (err) {
                        console.log(err);
                    }
                })();
            } else {
                notify('warning','Please Add resume in your profile.')
            }
        } else {
            notify('warning','You have no access for apply this job.')
        }
    }

    const favorite_jobs = (e) => {
        let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        if(userdata.usertype == 3){
            setIsProgress(true);
            const  API =  'https://us.jobchatbox.com/api/home/api/jobs_apply';
            const formData = new FormData();
            formData.append('job_id', e.currentTarget.id);
            formData.append('user_id', userdata.id );
            formData.append('joblisting_type', 'favorite' );
            (async () => {
                try {
                    let res = await fetch(API , {
                        method: "POST",
                        body: formData ,
                    });
                    let resJson = await res.json();
                    (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                    if(resJson.status){
                        setIsProgress(false);
                        setAppliedJobs(resJson.data.applied_id)
                        setFavoriteJobs(resJson.data.favorite_id)
                    }
                } catch (err) {
                    console.log(err);
                }
            })();
        } else {
            notify('warning','You have no access for add job in favorite list.')
        }
        
    }

    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    const checkJobApplied = (val)  => { return appliedJobs.some(item => val === item); }
    const checkJobAddedinFavorite = (val)  => { return favoriteJobs.some(item => val === item); }


    const search_and_get_results2 = async (title='',jobcategory='',jobstype='',recruiter_id='',skills='',state_id='' , status_for_join = '',jon_industry='',pageShow=1) => {

        (async () => {
            if(pageShow < totalPageLength){
                await axios
                    .get(`https://us.jobchatbox.com/api/home/api/get_job_by_name/${(title) ? title : 'no_title'}?jobcategory=${(jobcategory) ?  jobcategory.join(','): ''}&jobstype=${ (jobstype) ? jobstype.join(','): ''}&recruiter_id=${recruiter_id}&skills=${skills}&state_id=${state_id}&status_for_join=${status_for_join}&jon_industry=${jon_industry}&page=${pageShow}`)
                    .then((response) => {
                        if(response.data.status == 1){ 
                            setAllPostList(response.data.data.jobs) ; 
                            setTotalResultCount(response.data.data.total_result)
                            setTotalPageLength(response.data.data.jobs_page_count)
                        } else {
                            setAllPostList([])
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setIsLoading(false);
            }
            
        })();
        
    }

    const SearchByJobs = async (title) => { setSearchQuery(title) }
    
    const jobsSearchForm = async (event) => {
        event.preventDefault();
        setSearchByJobSkills(event.target.skills.value)
        setSearchByJobStateId(event.target.state_id.value)
        search_and_get_results2(event.target.skills.value ,'','','','',event.target.state_id.value )
    } 


    {/*search function for the sorting jobs */}
    const sortByPost  = async (type) => {
        (async () => {
            try {
                const getres = await axios.get("https://us.jobchatbox.com/api/home/api/get_job_by_short/"+type);
                if(getres.data.status == 1){ 
                    setAllPostList(getres.data.data.jobs) ; 
                    setTotalResultCount(getres.data.data.total_result)
                } else {
                    setAllPostList([])
                }
            } catch (error) { console.log(error); }
        })();
    } 

    {/*search function for the sitebar jobs */}
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState('noItem');
    const [pageShow, setPageShow] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isScrollClassAdd, setIsScrollClassAdd] = useState(false);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 500);
        return () => {
        clearTimeout(timerId);
        };
    }, [searchQuery]);

    useEffect(()  => {
        const jobCategoryCheckParam = Object.keys(jobCategoryCheck).filter((option) => jobCategoryCheck[option]);
        const jobsEmployementCheckParam = Object.keys(jobsEmployementCheck).filter((option) => jobsEmployementCheck[option]);
        const jobStatusForJoinCheckParam = Object.keys(jobStatusForJoinCheck).filter((option) => jobStatusForJoinCheck[option]);
        const jobIndustryCheckParam = Object.keys(jobIndustryCheck).filter((option) => jobIndustryCheck[option]);
       // console.log(jobStatusForJoinCheckParam)
        {/* 'title', 'jobcategory' , 'jobstype' , 'recruiter_id' , 'skills' ,'state_id' ,''*/}

        const queryParameters = new URLSearchParams(window.location.search);
        const candidate = queryParameters.get("candidate_id");
       
        
        search_and_get_results2(debouncedQuery, jobCategoryCheckParam,jobsEmployementCheckParam , candidate  ,searchByJobSkills , searchByJobStateId , jobStatusForJoinCheckParam ,jobIndustryCheckParam,pageShow);
      
    }, [debouncedQuery, jobCategoryCheck , jobsEmployementCheck,jobStatusForJoinCheck , jobIndustryCheck , pageShow]);

    const jobIndustryHandleChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobIndustryCheck({ ...jobIndustryCheck, [item]: isChecked });
    };
    
    const jobHandleChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobCategoryCheck({ ...jobCategoryCheck, [item]: isChecked });
    };

    const jobEmpHandleChange = async (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobsEmployementCheck({ ...jobsEmployementCheck, [item]: isChecked });
    };
    
    
    const jobStatusForJoin = async (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobStatusForJoinCheck({ ...jobStatusForJoinCheck, [item]: isChecked });
    };


    {/**infinte scroll**/}


    const handleScroll = () => {
        // const scrollY = window.scrollY;
        // const visibleHeight = window.innerHeight;
        // const pageHeight = document.body.scrollHeight;
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        //setIsScrollClassAdd
        setIsScrollClassAdd(false);
        if(scrollTop  >  800){
            setIsScrollClassAdd(true);
        }

        if ((scrollTop + clientHeight +1) >= scrollHeight && !isLoading) {
            setPageShow(page => page + 1);
            setIsLoading(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [allPostList , isLoading]);

    return(
            <>
            <ToastContainer />
            <section className="section-box-2 pt-40 mb-40">
                <div className="container">
                <div className="banner-hero banner-single banner-single-bg jobbanner">
                    <div className="block-banner text-center">
                  
                    <div className="block-banner bannerform">
                        <form onSubmit={jobsSearchForm} >
                            <div className="form-find mt-30 wow animate__animated animate__fadeIn d-flex" data-wow-delay=".2s">
                            
                                <input className="form-input input-keysearch mr-10" type="text" name="skills" placeholder="Job Title" />
                                <div className="select2 banner_location">
                                    <select className="form-input mr-10 select-active" name="state_id">
                                    <option value="">Select</option>
                                        {
                                            locationStateName.map((item,index) => (
                                                <option value={item.state_id}>{item.city_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="box-industry">
                                <select className="form-input mr-10 select-active input-industry">
                                    <option value="0" selected="selected">Recruiters by Location</option>
                                    {
                                        locationStateName.map((item,index) => (
                                            <option value={item.state_id}>{item.city_name}</option>
                                        ))
                                    }
                                </select>
                                </div>
                                
                                <button type="submit" className="btn btn-default btn-find font-sm">Search</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="section-box mt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            
                            {
                                jobFilterCategoryType ? 
                                <>
                                {
                                    <div className={isScrollClassAdd ? 'jobs_left_scroll' : 'jobs_left_scroll_stop'}>
                                        <div className="sidebar-shadow none-shadow mb-30">
                                            <div className="filter-block head-border mb-30 mobilefilter">
                                                <h5><span className="link-siltertoggler" onClick={tooglefilter}><i class="fa fa-filter" aria-hidden="true"></i> Filter</span></h5>
                                            </div>
                                            <div className="sidebar-filters">
                                                <div className="filter-block head-border mb-30">
                                                    <h5>Advance Filter <a className="link-reset" href="#">Reset</a></h5>
                                                </div>
                                                
                                                <div className="filter-block mb-0">
                                                    <div className="form-group select-style select-style-icon">
                                                        <input   name="jobsname"  onChange={(e) => SearchByJobs(e.target.value) }   placeholder="Search by jobs" />
                                                    </div>
                                                </div>
                                                
                                                <div className="filter-block mb-0">
                                                    <h5 onClick={() => sethideAndSeekIndustry( hideAndSeekIndustry == 'show' ? 'not' : 'show')}  className=" cursor_pointer medium-heading mb-10">
                                                        By Industry <i class={`fa fa-chevron-${hideAndSeekIndustry == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                                    </h5>
                                                    { hideAndSeekIndustry == 'show' &&    <div className="form-group">
                                                            <ul className="list-checkbox">
                                                                {
                                                                    
                                                                    jobIndustry.map((item,index) => (
                                                                        <li key={item.job_industry} >
                                                                            <label className="cb-container">
                                                                            <input name="job_category[]" onChange={jobIndustryHandleChange} type="checkbox" value={item.job_industry}  />
                                                                            <span className="text-small">{item.industry_name}</span>
                                                                            <span className="checkmark"></span>
                                                                            </label><span className="number-item">{item.total_count}</span>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div> }
                                                </div>

                                                <div className="filter-block mb-0">
                                                    {/* <h5 className="medium-heading mb-10">Job Category</h5> */}
                                                    <h5 onClick={() => sethideAndSeekJobCategory( hideAndSeekJobCategory == 'show' ? 'not' : 'show')}  className=" cursor_pointer medium-heading mb-10">
                                                        Job Category <i class={`fa fa-chevron-${hideAndSeekJobCategory == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                                    </h5>
                                                    { hideAndSeekJobCategory == 'show' && <div className="form-group">
                                                        <ul className="list-checkbox">
                                                            {
                                                                
                                                                jobFilterCategoryType.map((item,index) => (
                                                                    <li key={item.job_category} >
                                                                        <label className="cb-container">
                                                                        <input name="job_category[]" onChange={jobHandleChange} type="checkbox" value={item.job_category}  />
                                                                        <span className="text-small">{item.category_name}</span>
                                                                        <span className="checkmark"></span>
                                                                        </label><span className="number-item">{item.total_count}</span>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    }
                                                </div>

                                                <div className="filter-block mb-0"> 
                                                    {/* <h5 className="medium-heading mb-15">Job type</h5> */}
                                                    <h5 onClick={() => sethideAndSeekJobType( hideAndSeekJobType == 'show' ? 'not' : 'show')}  className=" cursor_pointer medium-heading mb-10">
                                                        Job type <i class={`fa fa-chevron-${hideAndSeekJobType == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                                    </h5>
                                                    { hideAndSeekJobType == 'show' && <div className="form-group">
                                                        <ul className="list-checkbox">
                                                            {
                                                                jobFilterEmpType.map((item,index) => (

                                                                    <li key={item.employment_id} >
                                                                        <label className="cb-container">
                                                                        <input name="employment_type[]" onChange={jobEmpHandleChange}  type="checkbox" value={item.employment_id}  />
                                                                        <span className="text-small">{item.employment_name}</span>
                                                                        <span className="checkmark"></span>
                                                                        </label><span className="number-item">{item.total_count}</span>
                                                                    </li>

                                                                ))
                                                            }
                                                        </ul>
                                                    </div> }
                                                </div>
                                                <div className="filter-block mb-0">
                                                    {/* <h5 className="medium-heading mb-15">Status For Join</h5> */}
                                                    <h5 onClick={() => sethideAndSeekJoiningStatus( hideAndSeekJoiningStatus == 'show' ? 'not' : 'show')}  className=" cursor_pointer medium-heading mb-10">
                                                    Status for Join <i class={`fa fa-chevron-${hideAndSeekJoiningStatus == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                                    </h5>
                                                    { hideAndSeekJoiningStatus == 'show' &&    <div className="form-group">
                                                            <ul className="list-checkbox">
                                                                {
                                                                    recJobstypeOpt.map((item,index) => (

                                                                        <li key={item.employment_id} >
                                                                            <label className="cb-container">
                                                                            <input name="employment_type[]" onChange={jobStatusForJoin}  type="checkbox" value={item.job_type_value}  />
                                                                            <span className="text-small">{item.job_type_value}</span>
                                                                            <span className="checkmark"></span>
                                                                            </label><span className="number-item">{item.total_count}</span>
                                                                        </li>

                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                }
                                </>
                                : '' 
                            }
                            
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
                            <div className="content-page"> 
                            
                                {/* Top filter strat */}
                                <div className="box-filters-job">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-5"><span className="text-small text-showing">Showing <span className="" ><strong>0-10</strong>of</span> <strong>{totalResultCount} </strong>Jobs</span></div>
                                        <div className="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                                            <div className="display-flex2">
                                                <div className="box-border mr-10 display_none"><span className="text-sortby">Show:</span>
                                                <div className="dropdown dropdown-sort">
                                                    <button className="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i className="fi-rr-angle-small-down"></i></button>
                                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                                    <li><a className="dropdown-item active" href="#">10</a></li>
                                                    <li><a className="dropdown-item" href="#">12</a></li>
                                                    <li><a className="dropdown-item" href="#">20</a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                                <div className="box-border"><span className="text-sortby">Sort by:</span>
                                                <div className="dropdown dropdown-sort">
                                                    <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span><i className="fi-rr-angle-small-down"></i></button>
                                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                                        <li><Link onClick={(e) => sortByPost('allpost')  } className="dropdown-item active" href="#">All Post</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('3months')  } className="dropdown-item active" href="#">3 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('2months')  } className="dropdown-item active" href="#">2 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('1months')  } className="dropdown-item active" href="#">1 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('15days')  } className="dropdown-item active" href="#">15 Days</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('7days')  } className="dropdown-item active" href="#">1 Week</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('today')  } className="dropdown-item active" href="#">Today</Link></li>
                                                    </ul>
                                                </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Top filter end */}
                            

                                <div className="row display-list">
                                
                                {
                                    allPostList.length ? 
                                    <>
                                    {
                                        allPostList.map((jobItem,index) => (
                                            <>
                                            <div key={jobItem.id} className="col-xl-12 col-12">
                                                <div className="card-grid-2 hover-up"><span className="flash"></span>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="card-grid-2-image-left pb-5">
                                                            <div className="image-box"> 
                                                                <img className="profile_pic" src={`https://us.jobchatbox.com/api/uploads/users/${jobItem.companydata.profile_pic}`} alt="jcbapp"  />
                                                            </div>
                                                            <div className="right-info"> 
                                                                <Link className="name-job text-capitalize" to={`/recruiter-details?candidate=${jobItem.companydata.unique_id}&jobid=${jobItem.id}`} >{jobItem.companydata.company_name}</Link>
                                                                <span className="location-small">{jobItem.prefered_state_location}</span> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-start text-md-end pr-60 col-md-12 col-sm-12">
                                                        <div className="pl-10 mb-1 mt-1 float_left text-left overflowelips">
                                                                {
                                                                    jobItem.job_skills.map((skill_item,index2) => (
                                                                        (index2 < 4)  ? <><Link key={index2+1} className="btn btn-grey-small mr-5 text-left text-capitalize mb-1" to={`/jobs-search?skills_tag=${skill_item}`} >{skill_item}</Link></> : ''
                                                                    ))
                                                                }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-block-info">
                                                    <h5><Link to={`/job-details?jobid=${jobItem.id}`} className='text-capitalize'>{jobItem.job_title}</Link></h5>
                                                    <div className="mt-5 mb-10">
                                                        <span className="card-briefcase">{jobItem.employment_type}</span>
                                                        <span className="card-time"><span>{jobItem.create_date}  ago</span></span>
                                                    </div>
                                                    <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Status :</strong>{jobItem.jobtype}</p></div>
                                                        <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                                                        <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                                                        <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-6"><span className="card-text-price">{jobItem.salary_range} </span></div>
                                                        <div className="col-lg-6 col-6 text-end">
                                                            {
                                                                auth ? 
                                                                <>
                                                                    {   checkJobApplied(jobItem.id) ? 
                                                                    
                                                                        <><button className="btn btn-applied-jobs" >Applied</button></> : 
                                                                        <>
                                                                        {
                                                                            checkJobAddedinFavorite(jobItem.id) ? 
                                                                            <><button className="btn btn-applied-jobs" >Added in Favorite</button></> : 
                                                                            <>
                                                                                <button disabled={isProgress}  className="btn btn-apply-now" onClick={favorite_jobs} id={jobItem.id} >Favorite Job</button>
                                                                                <button disabled={isProgress} className="btn btn-apply-now ml-5" onClick={applied_jobs} id={jobItem.id} >Apply now</button>
                                                                            </>
                                                                        }
                                                                        </>
                                                                    }
                                                                
                                                                
                                                                </> : 
                                                                <><Link className="btn btn-apply-now" to={'/login?jobsid='+jobItem.id}  >Apply now</Link></>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </>
                                            ))
                                    }
                                    </>
                                    :'Jobs is not found'
                                }
                                {isLoading && <div>Loading...</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
}

export default JobsPageBody;