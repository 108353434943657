import React,{useState,useEffect} from "react";
import axios from "axios";
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

const RefundPolicy  = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const [allPostData, setAllPostData] = useState([]);
    const [baseUrl, setBaseurl] = useState('');
    useEffect(() => { 
       (async () => {
          try {
                const getdata = await axios.get("https://us.jobchatbox.com/api/cms/pages/key/refund_policy");
                setAllPostData(getdata.data.data.pages_data);
                setBaseurl(getdata.data.data.base_url);
          } catch (error) { console.log(error); }
       })();
    }, []);

    return (
        <>
      <PageTopHeaderSec pageTitle='Refund & Refund Policy' PageLink='refund-policy'  />
         <>
         <section className="privacypolicysec">
                <div className="container">
                <div className="mb-20" dangerouslySetInnerHTML={{__html: allPostData.page_description}} ></div>

                    {/* <div className="">
                        <h4>Refund policy</h4>
                        <p>We're so convinced you'll absolutely love our services, that we're willing to offer a 30 day risk-free money back guarantee. If you are not satisfied with the service for any reason you can get a refund within 30 days of making a purchase. Please keep in mind that even though we offer a full money back guarantee, we will issue a refund only for the unused portion of the service.</p>
                        <h4>Additional services</h4>
                        <p>Please note that any additional services, custom work or technical support are non-refundable as our time cannot be recovered.</p>
                        <h4>Contacting us</h4>
                    </div> */}
                </div>
          </section>
         </>
      <SubscribeModule />
        </>
    );
  };
export default RefundPolicy;