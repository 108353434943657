import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Nav, NavLink, NavMenu } from "../../front/components/NavbarElements";
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';

import PostCreateFormModal from '../common/post_create_form_modal';
import { Modal, Button } from 'react-bootstrap' ;

import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import '../../../public/dashboard.css';
//import '../../../src/dashboard.css';
const hideFooter = true;

class JobSeekerDashboard extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            postdata_array: [] ,
            my_groups_array: [] ,
            shared_groupsid_array: [] ,
            my_connection_array: [] ,
            shred_connectionid_array: [] ,
            postdata_array: [] ,
            similerJobsAppliedId: [] ,
            errors : {}  ,
            DataisLoaded : true  ,
            isModalShow : false ,
            isResumeModalShow : false ,
            isResumeShareBtn : false ,
            isPageLoading : false ,
            isSharePostShareSubmitLoading : false ,
            isSharePostModel : false ,
            shareConnectionOrGroupStatus : false ,
            shareTypeJobOrPost : false ,
            shareConnectionOrGroupStatusId : false ,
            shareConnectionOrGroupStatusMaster : false ,
            isScrollClassAdd : false ,
            currentPageNumber : 1 ,
            isPostCreateModel : false , closePostCreateModel : false ,
            resumeFormUpload : {
                ru_post_id : 0 ,
                ru_post_type : 0 ,
                ru_uniqueKey : 0 ,
            }
        };
    }
 
    initModal = () => {  let ddd =  (this.state.isModalShow) ? false : true ; return this.setState({isModalShow: ddd})  }

    fetchPostLoopData = (POST_AND_JOBSA_API)  => {
        fetch(POST_AND_JOBSA_API)
            .then((res) => res.json())
            .then((json) => {
                    if(json.status != 0){
                        if(json.data.total_count > 0){
                            const {postdata_array} = this.state ;
                            this.setState({ 
                                postdata_array : [...postdata_array, ...json.data.results] ,
                                isPageLoading: false
                            });

                            console.log('.....')
                            this.setState({ my_groups_array : json.data.group_and_connection.boom_groups});
                            this.setState({ my_connection_array : json.data.group_and_connection.total_connections});
                        }
                    }
                },
                (error) => {
                    this.setState({ DataisLoaded: false ,});
                }
            )
    }
    
    componentDidMount() {
        window.scrollTo(0, 0)
        const queryParameters = new URLSearchParams(window.location.search)
        const candidate = queryParameters.get("candidate_id");
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        //let POST_AND_JOBSA_API  =  "https://us.jobchatbox.com/api/home/api/post_and_jobs/";
        let POST_AND_JOBSA_API  =  "https://us.jobchatbox.com/api/home/api/get_post_and_jobs/"+storedNames.login_token;
        if(candidate){
            POST_AND_JOBSA_API  =  "https://us.jobchatbox.com/api/home/api/post_and_jobs_for_timeline/"+candidate;
        } 
        console.log(POST_AND_JOBSA_API);
        this.setState({ isPageLoading: true });
        this.fetchPostLoopData(POST_AND_JOBSA_API);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() { window.removeEventListener('scroll', this.handleScroll); }

    handleScroll = () => {
       
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        // console.log(scrollTop + clientHeight +1);
        this.setState({ isScrollClassAdd: false });
        
        // if(scrollTop  >  500){
        //     this.setState({ isScrollClassAdd: true });
        // }
        
         
         //isScrollClassAdd
         //console.log(clientHeight);
        if ((scrollTop + clientHeight) >= scrollHeight && !this.state.isPageLoading) {
            //setPageShow(page => page + 1);
            // /currentPageNumber
            const  { currentPageNumber } =  this.state;
            this.setState({ isPageLoading: true });
            this.setState({ currentPageNumber: (currentPageNumber+1) });

            var storedNames = JSON.parse(localStorage.getItem("userlogin"));
            let POST_AND_JOBSA_API  =  "https://us.jobchatbox.com/api/home/api/get_post_and_jobs/"+storedNames.login_token+"?page="+(currentPageNumber+1);

            this.fetchPostLoopData(POST_AND_JOBSA_API);
            //setIsLoading(true);
        }
    };

    removePostFromDash = (postID , postType , uniqueKey)   => {
        if (window.confirm('Do you want to remove post from list ?') == true) {
            let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
            (async () => {
                try {
               
                const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/remove_post/"+userdata.login_token+"/"+postID+"/"+postType);
                if(responceData.data.status == 1){
                    console.log(responceData);
                    let element = document.getElementById(`section_${uniqueKey}`);
                    element.classList.add("display_none");
                    //notify('success',resJson.message);
                    toast.success(responceData.data.message)
                    //uniqueKey
                   //setAppliedJobs(states_list.data.data.applied_jobs)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }
    

    closePostCreateModel = () => {  this.setState({isPostCreateModel:false});}
    jobsPostModalShow = () => {  this.setState({isPostCreateModel:true});}
    editPostFromDash = (postID , postType , uniqueKey)   => {
            let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
            (async () => {
                try {
                    const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/edit_post/"+userdata.login_token+"/"+postID+"/"+postType);
                    if(responceData.data.status == 1){
                         this.setState({isPostCreateModel:true});
                        // let element = document.getElementById(`section_${uniqueKey}`);
                        // element.classList.add("display_none");
                        // toast.success(responceData.data.message)
                    }
                } catch (error) { console.log(error); }
            })();
    }

    //editPostFromDash
    
    /* Resume upload by modal*/
    sharePostResume = (postID , postType , uniqueKey)   => {
        
        this.setState({ isResumeModalShow: true })
        let uploadvalue =   this.state.resumeFormUpload ;
        uploadvalue['ru_post_id'] =  postID ;
        uploadvalue['ru_post_type'] =  postType ;
        uploadvalue['ru_uniqueKey'] =  uniqueKey ;
    }

    postResumeSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        let uploadvalue =   this.state.resumeFormUpload ;
        //isResumeShareBtn

        this.setState({isResumeShareBtn: true});
        formData.append('upload_resume', event.target.upload_resume.files[0]);
        formData.append('login_token', storedNames.login_token);
        formData.append('post_id', uploadvalue.ru_post_id);
        formData.append('post_type', uploadvalue.ru_post_type);
        try {
            let res = await fetch("https://us.jobchatbox.com/api/user/api/post_upload_resume", {
                method: "POST",
                body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                this.setState({isResumeShareBtn: false});
                if(resJson.status){ 
                    this.notify('success',resJson.message) ; this.setState({ isResumeModalShow: false });
                    
                    this.setState(prevState => ({
                        similerJobsAppliedId: [...prevState.similerJobsAppliedId, uploadvalue.ru_post_id]
                    }));
                    //setSimilerJobsAppliedId([...similerJobsAppliedId, uploadvalue.ru_post_id]);
                    document.getElementById(`shared_resume_${uploadvalue.ru_uniqueKey}`).innerHTML = `Shared`;
                }  else { this.notify('warning',resJson.message) ; }
            } else {
            }
        } catch (err) { 
            console.log(err);
        }
    }
    
    closeResumeModal = () => this.setState({ isResumeModalShow: false });
    
    /* Resume upload by modal*/

    //isSharePostModel   


    commentHtmlSet  = (htmlcontData , postID , postType) => {
        var htmlcont  = '';
        htmlcontData.map((commdata ,  index)  =>{
            htmlcont  +=  `<div class="media-block">
                <a class="media-left" href="#"><img class="img-circle img-sm" alt="Profile Picture" src="${commdata.user_profile_pic}" /></a>
                <div class="media-body">
                    <div class="mar-btm">
                        <span href="#" class="btn-link text-semibold media-heading box-inline">${commdata.user_firstname}</span>
                        <p class="text-muted text-sm">${commdata.dayago}</p>
                    </div>
                    <p>${commdata.pMessage}</p>
                    <div class="pad-ver display_none">
                        <div class="btn-group">
                            <a onclick="likeCommentItem(${commdata.id} , ${commdata.post_id} , ${commdata.page_type})"  class="btn btn-sm btn-default btn-hover-success" href="#"><i class="fa fa-thumbs-up"></i></a>
                            <a onclick="likeCommentItem(${commdata.id} , ${commdata.post_id} , ${commdata.page_type})"  class="btn btn-sm btn-default btn-hover-danger" href="#"><i class="fa fa-thumbs-down"></i></a>
                        </div>
                    </div>
                </div>
            </div> <hr />`;

        }) ;
        document.getElementById(`comment_${postType}_${postID}`).innerHTML = htmlcont ;
    }

    hideAndShowComment  = (postID , postType) => {
    
        let element = document.getElementById(`comment_section_${postType}_${postID}`);
        if (element.classList.contains('display_none')) 
        {
            var postvalue = document.getElementById(`comment_value_${postID}`).value; 
            let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
            const  API =  `https://us.jobchatbox.com/api/user/api/commnets_list`;
            const formData = new FormData();
            formData.append('postid', postID);
            formData.append('page_type', postType);
            formData.append('logintoken', userdata.login_token );
            (async () => {
                try {
                    let res = await fetch(API , {
                        method: "POST",
                        body: formData ,
                    });
                    let resJson = await res.json();
                    if(resJson.status ==  1){
                       // alert()
                        //console.log(resJson.data.comments);
                        this.commentHtmlSet(resJson.data.comments, postID , postType )
                    }
                } catch (err) {
                    console.log(err);
                }
            })();
            element.classList.remove("display_none");
        } else {
            element.classList.add("display_none");
        }
    } 
    
    
    likeCommentItem  = (commentId , postID , postType) => {
        alert()
    } 

    dislikeCommentItem  = (commentId , postID , postType) => {

    } 

    likeItem = (postid,postType)  => {
      
        let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        const  API =  `https://us.jobchatbox.com/api/user/api/likeposts`;
        const formData = new FormData();
        formData.append('postid', postid);
        formData.append('page_type', postType );
        formData.append('logintoken', userdata.login_token );
        (async () => {
            try {
                let res = await fetch(API , {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if(resJson.status ==  1){
                    if(postType == 'post'){
                        document.getElementById(`post_${postid}`).innerHTML = `(${resJson.data.totallikes})`;
                    } else {
                        document.getElementById(`job_${postid}`).innerHTML = `(${resJson.data.totallikes})`;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }
    
    postComment = (postID,postType)  => {
        var postvalue = document.getElementById(`comment_value_${postID}`).value; 
        let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        const  API =  `https://us.jobchatbox.com/api/user/api/add_comment_to_posts`;
        const formData = new FormData();
        formData.append('postid', postID);
        formData.append('page_type', postType);
        formData.append('message', postvalue);
        formData.append('logintoken', userdata.login_token );
        (async () => {
            try {
                let res = await fetch(API , {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if(resJson.status ==  1){
                    //console.log(resJson.data.comments);
                    document.getElementById(`comment_value_${postID}`).value = "";
                    this.commentHtmlSet(resJson.data.comments, postID , postType )
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }


    /*------------- share post ----------------*/
    closeSharePostModal = () => this.setState({ isSharePostModel: false });
    postshare  =  (postID , callType , postType , masterPostId) => {
        this.setState({ shareConnectionOrGroupStatusId: postID })
        this.setState({ shareConnectionOrGroupStatus: callType })
        this.setState({ shareTypeJobOrPost: postType })
        this.setState({ shareConnectionOrGroupStatusMaster: masterPostId })
        this.setState({ isSharePostModel: true })
    }

    sortByPost  = async (type,userType) => {
        (async () => {
            try {
                const getres = await axios.get("https://us.jobchatbox.com/api/home/api/post_and_jobs?shortby="+type+'&usertype='+userType);
                if(getres.data.status == 1){ 

                   if(getres.data.data.results.total_count !=  0){
                    this.setState({ postdata_array : getres.data.data.results});
                   } else {
                    this.setState({ postdata_array : []});
                   }
                   
                   // 
                    //setAllPostList(getres.data.data.jobs) ; 
                    //setTotalResultCount(getres.data.data.total_result)
                } else {
                    //setAllPostList([])
                }
            } catch (error) { console.log(error); }
        })();
    }

    shareConnectionAndGorupSubmit   = (event)  => {
        event.preventDefault();
        const formData = new FormData(event.target);
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        let sharegroupformdata = {};

        this.setState({ isSharePostShareSubmitLoading: true });
        formData.forEach((value, name) => {
            if (name.endsWith('[]')) {
                console.log('if');
                const inputName = name.slice(0, -2); 
                if (!sharegroupformdata[inputName]) { sharegroupformdata[inputName] = [value];} 
                else { sharegroupformdata[inputName].push(value); }
            } else { 
                sharegroupformdata[name] = value; 
                console.log('if');
            }
        });
        formData.append('login_token', storedNames.login_token);
        formData.append('share_type', this.state.shareConnectionOrGroupStatus);
        formData.append('post_id', this.state.shareConnectionOrGroupStatusId);
        formData.append('post_type', this.state.shareTypeJobOrPost);
        formData.append('post_or_job_matertableid', this.state.shareConnectionOrGroupStatusMaster);
        (async () => {
            try {
                let res = await fetch("https://us.jobchatbox.com/api/user/api/share_groupandconnection", {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if(resJson.status){ 
                        this.notify('success',resJson.message) ; 
                        this.setState({ isSharePostModel: false });
                    }  else { this.notify('warning',resJson.message) ; }
                } 

                this.setState({ isSharePostShareSubmitLoading: false });
            } catch (err) { 
                console.log(err);
                this.setState({ isSharePostShareSubmitLoading: false });
            }
        })();
        
    }

    /*--------------------------------------------*/
    notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    render() {
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        
        const  JobsTypeHtml  = (attrData) => {            

            //console.log(attrData.data.resume_shared);
           // console.log(attrData);
            let user_profile_pic = `https://us.jobchatbox.com/api/uploads/users/${(attrData.userdata.profile_pic ? attrData.userdata.profile_pic : '')}`;
            let candidate_url =  (attrData.userdata.usertype == 6) ? 'recruiter-details' : 'jobseeker-details';
           
            const job_image  =  attrData.data.job_image ? attrData.data.job_image  : '';
            const job_attachment  =  attrData.data.job_attachment ? attrData.data.job_attachment  : '';

            const [expanded, setExpanded] = useState(false);
            const maxLength = 200;
            const toggleExpanded = () => {  setExpanded(!expanded); };
            const  content_description  =  attrData.data.description ;
            const truncatedDesc = (content_description.length <= maxLength || expanded)  ?  content_description : (content_description.substring(0, maxLength) + '...' ) ;


            return (
                <>
                    <div key={attrData.index_key}  id={`section_${attrData.index_key}`} className="jobsickerbox">
                        <div className="card-grid-2">
                            <div className="card-grid-2-image-left">
                                <div className="card-grid-2-image-rd">
                                <NavLink  to={`/${candidate_url}?candidate=${attrData.userdata.unique_id}`}>
                                    <figure><img alt="jcbapp" src={user_profile_pic} /></figure>
                                </NavLink>
                                </div>
                                <div className="card-profile pt-5 mr-10">
                                <NavLink className="text-capitalize"  to={`/${candidate_url}?candidate=${attrData.userdata.unique_id}&jobid=${attrData.data.id}`}>
                                        <h5> {attrData.userdata.firstname}</h5> 
                                </NavLink>
                                    <NavLink to={`${attrData.userdata.usertype == 6 ? '/recruiters' : '/jobseeker'}`}  ><h6 className="roundtab text-white">{attrData.userdata.usertype == 6 ? 'Recruiter' : 'JobSeeker'}</h6></NavLink>
                                    <NavLink to={`/mytimeline?candidate_id=${attrData.userdata.unique_id}`}  ><h6 className="roundtab ml-5 text-white">Timeline</h6></NavLink>
                                    <NavLink to={`/jobs?candidate_id=${attrData.userdata.unique_id}`}  >
                                    {attrData.days_ago ? <><span className="font-xs color-text-mutted">{attrData.days_ago} ago {(attrData.userdata.usertype == 6) ? '(Active Jobs: '+attrData.userdata.total_active_jobs+')':''}</span></> : ''}
                                    </NavLink>
                                </div>

                                {
                                    (attrData.data.resume_shared == 0) ? 
                                    <>
                                        <div  onClick={() => this.sharePostResume(attrData.data.id , 'job' ,attrData.index_key )} class="recrutersharerbtn ml-auto">
                                            <button class="btn btn-default btn-small " disabled={this.state.similerJobsAppliedId.includes(attrData.data.id)}>
                                                <i class="fa fa-file-text" aria-hidden="true"></i>
                                                <span id={`shared_resume_${attrData.index_key}`}>{this.state.similerJobsAppliedId.includes(attrData.data.id) ? 'Shared' : 'Share Resume'}</span> 
                                            </button>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div  class="recrutersharerbtn ml-auto">
                                            <button class="btn btn-default btn-small ">
                                                <i class="fa fa-file-text" aria-hidden="true"></i> Shared
                                            </button>
                                        </div>
                                    </>
                                }
                                
                                { 
                                    storedNames.login_token ==  attrData.userdata.login_token ?
                                    <><div className="dashboarddelet ml-0">
                                        <Link onClick={() => this.removePostFromDash(attrData.data.id , 'job' , attrData.index_key)} ><i className="fa fa-trash" aria-hidden="true"></i></Link></div></> :
                                    ''
                                }
                            </div>
                            <div className="card-block-info">
                            <div className="mb-20 d-flex align-items-center">  
                                <p className="font-xs color-text-paragraph-2 preferedloca"><i className="fa fa-map-marker" aria-hidden="true"></i><b>Jobs Location: </b> {attrData.data.prefered_state_location} </p>
                                <div className="ml-auto"><span className="roundtab text-right">{attrData.data.jobtype}</span></div>
                            </div>
                                <h5><Link to={`/job-details?jobid=${attrData.data.id}`} className='text-capitalize'>{attrData.data.job_title}</Link></h5>
                                
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-xs-12 message_paragraph para_jop_post_3">
                                        <div className="take">
                                            <p className="m-0 color-text-paragraph-2">
                                                <span className="lable_para" >Job Location :</span>
                                                {attrData.data.location_id}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 message_paragraph para_jop_post_4">
                                        <div className="take">
                                            <p className="m-0 color-text-paragraph-2">
                                                <span  className="lable_para">No.of Vacancies :</span> 
                                                {attrData.data.no_of_posts}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 message_paragraph para_jop_post_88">
                                        <div className="take">
                                            <p className="m-0 color-text-paragraph-2">
                                                <span  className="lable_para">Job Industry :</span> 
                                                {attrData.data.industry_data}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xs-12 message_paragraph para_jop_post_88">
                                        <div className="take">
                                            <p className="m-0 color-text-paragraph-2">
                                                <span  className="lable_para">Job Function :</span> 
                                                {attrData.data.job_function} 
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-xs-12 message_paragraph para_jop_post_88">
                                        <div className="take">
                                            <p className="m-0 color-text-paragraph-2">
                                                <span  className="lable_para">Skills : </span>
                                                {attrData.data.job_skills}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    job_image ?
                                    <><img  src={job_image}  /></>
                                    :''
                                }
                                <span  className="lable_para">Description : </span>
                                {/* <p className="font-xs color-text-paragraph-2" dangerouslySetInnerHTML={{ __html: attrData.data.description }}></p> */}
                                <p className="font-xs color-text-paragraph-2 mb-5" dangerouslySetInnerHTML={{ __html: truncatedDesc }}></p>
                                {(content_description.length > maxLength) ? <a  className='moreinforin_loop' onClick={toggleExpanded}>{expanded ? 'Show Less' : 'More'}</a> : ''}
                                 
                                {
                                    job_attachment ? 
                                    <>
                                    <div class="mt-30 d-flex">
                                        <a  download target='_blank' class="btn btn-default btn-small " href={job_attachment}>
                                            <i class="fa fa-file-text" aria-hidden="true"></i>Attachment 
                                        </a>
                                    </div>
                                    </>
                                    : ''
                                }
                                
                                
                                <div className="employers-info align-items-center justify-content-center mt-15">
                                    <div className="row">
                                        <div className="col-4">
                                            
                                            <Link onClick={ () => this.likeItem(attrData.data.id , 'job')} id={attrData.data.id} datatype="job" className="d-flex align-items-center">
                                                <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                                <span className="color-text-mutted ml-10">Like </span> 
                                                <span className='ml-5 likes' id={`job_${attrData.data.id}`}>({attrData.totallikes})</span>
                                            </Link>
                                        </div>
                                        <div className="col-4">
                                            <div className="dropdown">
                                                <a href="javascript:void(0)" className="d-flex justify-content-center align-items-center dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-share" aria-hidden="true"></i>
                                            <span className="color-text-mutted ml-10">Share</span></a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li  key={`post_${attrData.index_key}_1`} ><Link onClick={ () => this.postshare(attrData.data.id , 'connection' , 'job' , attrData.job_or_post_master_id) } className="dropdown-item" href="#">Connections</Link></li>
                                            <li  key={`post_${attrData.index_key}_2`} ><Link onClick={ () => this.postshare(attrData.data.id , 'group' , 'job' , attrData.job_or_post_master_id) } className="dropdown-item" href="#">Groups</Link></li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <Link onClick={ () => this.hideAndShowComment(attrData.data.id , 'job')}  className="d-flex justify-content-end " id="" ><i className="fa fa-comment-o" aria-hidden="true"></i>
                                                <span className="color-text-mutted ml-10 dropdown-toggle">Comments</span>
                                                <span className='ml-5 likes' id={`job_comment_${attrData.data.id}`}>({attrData.totalcomments})</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="commentdown display_none" id={`comment_section_job_${attrData.data.id}`}>
                                                <div className="commentwrap bootdey">
                                                    <div className="col-md-12 bootstrap snippets">
                                                        <div className="panel">
                                                            <div className="panel-body" id={`comment_form_${attrData.data.id}`}>
                                                                <textarea id={`comment_value_${attrData.data.id}`}  className="form-control" rows="2" placeholder="What are you thinking?"></textarea>
                                                                <div className="mar-top commenttopsec clearfix">
                                                                    <button  onClick={ () => this.postComment(attrData.data.id , 'job')}  className="btn btn-sm btn-primary pull-right " type="submit"><i className="fa fa-paper-plane fa-fw"></i> Post</button>
                                                                    <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-video-camera"></i></a>
                                                                    <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-camera"></i></a>
                                                                    <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-paperclip"></i></a>
                                                                </div>
                                                            </div>
                                                            <div className="panel-body1" id={`comment_job_${attrData.data.id}`}></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                 </>
            )
        }

        const   PostTypeHtml  = (attrData) => {

            //console.log(attrData);
            let user_profile_pic = `https://us.jobchatbox.com/api/uploads/users/${(attrData.userdata.profile_pic ? attrData.userdata.profile_pic : '')}`;
           // let location = (attrData.data.prefered_location != '...') ? attrData.data.prefered_location : attrData.userdata.user_prefered_location ;
            let location = (attrData.data.prefered_state_location != '...') ? attrData.data.prefered_state_location : attrData.userdata.user_prefered_location ;
            let downloadlink  = (attrData.data.fileName) ?  'https://us.jobchatbox.com/api/uploads/groups/'+attrData.data.fileName : '#' ;
            let candidate_url =  (attrData.userdata.usertype == 6) ? 'recruiter-details' : 'jobseeker-details';
            //console.log(attrData.data);
            const [expanded, setExpanded] = useState(false);
            const maxLength = 200;
            const toggleExpanded = () => {  setExpanded(!expanded); };
            const  content_description  = attrData.data.pMessage ;
            const truncatedDesc = (content_description.length <= maxLength || expanded)  ?  content_description : (content_description.substring(0, maxLength) + '...' ) ;

            return (
                <>
                <div key={attrData.index_key} id={`section_${attrData.index_key}`} className="jobsickerbox recuruterbox">
                    <div className="card-grid-2">
                        <div className="card-grid-2-image-left">
                            <div className="card-grid-2-image-rd">
                                <NavLink  to={`/${candidate_url}?candidate=${attrData.userdata.unique_id}`}>
                                <figure><img alt="jcbapp" src={user_profile_pic} /></figure>
                                </NavLink>
                            </div>
                            <div className="card-profile pt-5 mr-10">
                                <NavLink  to={`/${candidate_url}?candidate=${attrData.userdata.unique_id}`}>
                                <h5 className='text-capitalize'> {attrData.userdata.firstname}</h5>
                                </NavLink>
                                <NavLink to={`${attrData.userdata.usertype == 6 ? '/recruiters' : '/jobseeker'}`}  ><h6 className="roundtab text-white">{attrData.userdata.usertype == 6 ? 'Recruiter' : 'JobSeeker'}</h6></NavLink>
                                    <NavLink to={`/mytimeline?candidate_id=${attrData.userdata.unique_id}`}  ><h6 className="roundtab ml-5 text-white">Timeline</h6></NavLink>
                                   
                                <NavLink to={`/jobs?candidate_id=${attrData.userdata.unique_id}`}  >
                                    <span className="font-xs color-text-primary">{attrData.days_ago ? attrData.days_ago+' ago' : ''} {(attrData.userdata.usertype == 6) ? '(Active Jobs: '+attrData.userdata.total_active_jobs+')':''} </span>
                                </NavLink>

                            </div>
                            
                            {
                                (attrData.userdata.usertype == 6  &&  attrData.data.ispostjob ==  1) ? 
                                <>
                                {
                                    (attrData.data.ispostjob == 1) ? 
                                    <>
                                    <div onClick={() => this.sharePostResume(attrData.data.id , 'post' ,attrData.index_key )}  className="recrutersharerbtn ml-auto">
                                        <button className="btn btn-default btn-small " disabled={this.state.similerJobsAppliedId.includes(attrData.data.id)} ><i className="fa fa-file-text" aria-hidden="true"></i> {this.state.similerJobsAppliedId.includes(attrData.data.id) ? 'Shared' : 'Share Resume'}</button>
                                    </div>
                                    </>
                                    :
                                    <>
                                        <div  className="recrutersharerbtn ml-auto">
                                            <button className="btn btn-default btn-small "><i className="fa fa-file-text" aria-hidden="true"></i> Shared</button>
                                        </div>
                                    </>
                                    
                                }
                                </>
                                : ''
                            }
                            { 
                                storedNames.login_token ==  attrData.userdata.login_token ?
                                <><div className={(attrData.userdata.usertype == 6  &&  attrData.data.ispostjob ==  1) ? 'dashboarddelet ml-auto ml-0' : 'dashboarddelet ml-auto'}>
                                <Link onClick={() => this.removePostFromDash(attrData.data.id , 'post' ,attrData.index_key )} ><i className="fa fa-trash text-success1" aria-hidden="true"></i></Link>
                                <Link onClick={() => this.editPostFromDash(attrData.data.id , 'post' ,attrData.index_key )} ><i className="fa fa-edit  text-danger1" aria-hidden="true"></i></Link>
                                </div></>
                                : ''
                            }
                        </div>
                        <div className="card-block-info">
                            <div className="mb-20 d-flex align-items-center">
                                {
                                  ((attrData.data.ispostjob ==  1) &&  (location)) ? <><p className="font-xs color-text-paragraph-2 preferedloca"><i className="fa fa-map-marker" aria-hidden="true"></i>{location}</p></> : ''
                                }
                                {
                                    (attrData.data.jobtype) ? 
                                    <><div className="ml-auto">
                                    <span className="roundtab text-right">{attrData.data.jobtype}</span>
                                    </div></> 
                                    : ''
                                }
                            </div>

                            {/* <p className="font-xs color-text-paragraph-2 mb-5" dangerouslySetInnerHTML={{ __html: attrData.data.pMessage }}></p> */}
                            <p className="font-xs color-text-paragraph-2 mb-5" dangerouslySetInnerHTML={{ __html: truncatedDesc }}></p>
                            {(content_description.length > maxLength) ? <a  className='moreinforin_loop' onClick={toggleExpanded}>{expanded ? 'Show Less' : 'More'}</a> : ''}
                            {
                                (attrData.images_data.isImage == 1) ? <><img className='dashboard_img width-100' src={downloadlink} /></> : <></>
                            }
                            
                            {
                                (attrData.images_data.isImage != 1  && attrData.data.ispostjob == 1 &&  attrData.data.fileName) ? 
                                <>
                                <div className="mt-30 d-flex">
                                    <a download className="btn btn-default btn-small " href={downloadlink}><i className="fa fa-file-text" aria-hidden="true"></i>
                                   { attrData.userdata.usertype == 6 ? 'Attachment' : 'Resume' } </a>
                                </div>
                                </>
                                : ''
                            }
                        
                            <div className="employers-info align-items-center justify-content-center mt-15">
                                <div className="row">
                                    <div className="col-4">
                                        <Link onClick={ () => this.likeItem(attrData.data.id , 'post')}  id={attrData.data.id} datatype="post" className="d-flex align-items-center">
                                        <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                        <span className="color-text-mutted ml-10">Like </span> 
                                        <span className='ml-5 likes' id={`post_${attrData.data.id}`}>({attrData.totallikes})</span>
                                        </Link>
                                    </div>
                                    <div className="col-4">
                                        <div className="dropdown">
                                            <a href="#" className="d-flex justify-content-center align-items-center dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-share" aria-hidden="true"></i>
                                        <span className="color-text-mutted ml-10">Share</span></a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li  key={`post_${attrData.index_key}_1`} ><Link onClick={ () => this.postshare(attrData.data.id , 'connection' , 'post' , attrData.job_or_post_master_id) } className="dropdown-item" href="#">Connections</Link></li>
                                            <li  key={`post_${attrData.index_key}_2`} ><Link onClick={ () => this.postshare(attrData.data.id , 'group' , 'post' , attrData.job_or_post_master_id) } className="dropdown-item" href="#">Groups</Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <Link onClick={ () => this.hideAndShowComment(attrData.data.id  , 'post')}  className="d-flex justify-content-end"   ><i className="fa fa-comment-o" aria-hidden="true"></i>
                                                <span className="color-text-mutted ml-10 dropdown-toggle">Comments</span>
                                                <span className='ml-5 likes' id={`post_comment_${attrData.data.id}`}>({attrData.totalcomments})</span>
                                        </Link>  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                  
                                        <div className="commentdown display_none"  id={`comment_section_post_${attrData.data.id}`} >
                                            <div className="commentwrap bootdey">
                                                <div className="col-md-12 bootstrap snippets">
                                                    <div className="panel">
                                                        <div className="panel-body" id={`comment_form_${attrData.data.id}`}>
                                                            <textarea id={`comment_value_${attrData.data.id}`}  className="form-control" rows="2" placeholder="What are you thinking?"></textarea>
                                                            <div className="mar-top commenttopsec clearfix">
                                                                <button  onClick={ () => this.postComment(attrData.data.id , 'post')}  className="btn btn-sm btn-primary pull-right" type="submit"><i className="fa fa-paper-plane fa-fw"></i> Post</button>
                                                                <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-video-camera"></i></a>
                                                                <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-camera"></i></a>
                                                                <a className="btn btn-trans btn-icon display_none" href="#"><i className="fa fa-paperclip"></i></a>
                                                            </div>
                                                        </div>
                                                        <div className="panel-body1" id={`comment_post_${attrData.data.id}`}>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        }

        return(
            <>
                <ToastContainer />
                {/* main body content */}
                <div className="dashboardsec pt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <LeftSidebar />
                            </div>
                            <div className="col-lg-6">

                                {/*<Button variant="success" onClick={this.initModal}>Open Modal</Button>*/}

                                {
                                    (storedNames.usertype == 6) ?
                                    <>
                                    <div className='sticky_middle'>
                                        <div className="seekerdashboardtop recurutertop d-flex">
                                            {/* <a href="#" data-bs-toggle="modal" data-bs-target="#addJobsPostModal" >
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i> Start A Post
                                            </a> */}
                                            <Link onClick={this.jobsPostModalShow} >
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i> Start A Post
                                            </Link>
                                            
                                            <Link to="/post-create"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  Post A Job</Link>
                                        </div>
                                        <div className="seekerdashboardtop recurutertop d-flex">
                                            <Link to="/groups"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Write A Conversations In Group</Link>
                                            <select onChange={(e) => this.sortByPost(e.target.value,6)  }  className="form-input form-control1  select_jobs_bysort" data-select2-id="1" tabIndex="-1" aria-hidden="true">
                                                <option selected={true} value="0" data-select2-id="3">POST SORT BY</option>
                                                <option value="">All Jobs & Post</option>
                                                <option value="all">All Post</option>
                                                <option value="3months">3 Months</option>
                                                <option value="2months">2 Months</option>
                                                <option value="1months">1 Months</option>
                                                <option value="15days">15 Days</option>
                                                <option value="7days">1 Week</option>
                                                <option value="today">Today</option>
                                            </select>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                        <div className="seekerdashboardtop d-flex seekerdash sticky_middle">
                                            <Link onClick={this.jobsPostModalShow} >
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i> Start A Post
                                            </Link>
                                            <Link to="/groups"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Write A Conversations In Group</Link>
                                            <select onChange={(e) => this.sortByPost(e.target.value,3)  }  className="form-input form-control1  select_jobs_bysort" data-select2-id="1" tabIndex="-1" aria-hidden="true">
                                                <option selected={true} value="0" data-select2-id="3">JOBS SORT BY</option>
                                                <option value="">All Jobs & Post</option>
                                                <option value="all">All Jobs</option>
                                                <option value="3months">3 Months</option>
                                                <option value="2months">2 Months</option>
                                                <option value="1months">1 Months</option>
                                                <option value="15days">15 Days</option>
                                                <option value="7days">1 Week</option>
                                                <option value="today">Today</option>
                                            </select>
                                        </div>

                                    </>
                                }


                                <div className="jobsickerdashboard_btm">
                                    {
                                        (this.state.postdata_array.length > 0)  ?      
                                            this.state.postdata_array.map((jonseekerdata,index) => 
                                            <>
                                            {
                                            jonseekerdata.connect_with == 'job' ? 
                                            <div key={jonseekerdata.unique_key}><JobsTypeHtml  job_or_post_master_id={jonseekerdata.job_or_post_master_id}  totallikes={jonseekerdata.totallikes} index_key={jonseekerdata.unique_key} images_data={jonseekerdata.images_data} data={jonseekerdata.jobdata} userdata={jonseekerdata.userdata} image={jonseekerdata.images_data} base_url={jonseekerdata.base_url} totalcomments={jonseekerdata.totalcomments}  days_ago={jonseekerdata.create_date} /></div> : 
                                            <div key={jonseekerdata.unique_key}><PostTypeHtml  job_or_post_master_id={jonseekerdata.job_or_post_master_id}   totallikes={jonseekerdata.totallikes}  index_key={jonseekerdata.unique_key} images_data={jonseekerdata.images_data} data={jonseekerdata.jobdata} userdata={jonseekerdata.userdata} image={jonseekerdata.images_data} base_url={jonseekerdata.base_url} totalcomments={jonseekerdata.totalcomments}   days_ago={jonseekerdata.create_date} /></div>
                                            }
                                            </>
                                            ) 
                                        : <div>No Data avaliable</div>
                                    }

                                    {this.state.isPageLoading && <div>Loading...</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className={this.state.isScrollClassAdd == true ? 'scroll_right_class sticky' : 'not_scroll sticky'}>
                                <RightSidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----model------ */}
                {/* <div className="modal fade" id="addJobsPostModal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content apply-job-form">
                            <button className="btn-close" onClick={this.initModal} id="postCreateCloseBtn" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body pl-30 pr-30 pt-50">
                            <PostCreateFormModal /> 
                            </div>
                        </div>
                    </div>
                </div> */}
                
                <Modal show={this.state.isPostCreateModel} onHide={this.closePostCreateModel}>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Resume Upload</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <PostCreateFormModal /> 
                    </Modal.Body>
                </Modal>

                {/* ----model------ */}
                <Modal show={this.state.isResumeModalShow} onHide={this.closeResumeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resume Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <>
                       <form onSubmit={this.postResumeSubmit} className="form-horizontal login-frm" name="resetForm" id="resetForm" method="POST" autocomplete="off" enctype="multipart/form-data" novalidate="novalidate">
                            <div className="col-md-12">
                                <div className="form-group login-grp">
                                    <label>Upload Resume</label>
                                    <input type="file" required={true} className="form-control login-ctrl" id="upload_resume" name="upload_resume" accept=".doc, .docx,.pdf"  />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mt-2">
                                    <input type="hidden"  id="ru_post_id" name="ru_post_id" value="0" />
                                    <input type="hidden"  id="ru_post_type" name="ru_post_type" value="0" />
                                    <input type="hidden"  id="ru_user_id" name="ru_user_id" value="0" />
                                    <button type="submit" disabled={this.state.isResumeShareBtn} className="btn btn-brand-1 hover-up w-100">Submit</button>
                                </div>
                            </div>                                
                        </form>
                       </>
                    </Modal.Body>
                </Modal> 

                {/* ----share post contents------ */}
                <Modal show={this.state.isSharePostModel} onHide={this.closeSharePostModal}>
                    <Modal.Header closeButton  >
                        <Modal.Title>Share with {this.state.shareConnectionOrGroupStatus == 'group' ? 'groups' : 'connections'} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <>
                          {
                            (this.state.shareConnectionOrGroupStatus == 'group') ?
                            <>
                             {
                             this.state.my_groups_array.length  ? 
                                <>
                                    <form onSubmit={this.shareConnectionAndGorupSubmit}>
                                        <table className='table mt-5 table-striped border'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Group Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.my_groups_array.map((item,index) => (
                                                    <>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td className='text-capitalize'>{item.groupTitle}</td>
                                                        <td>
                                                            <input type="checkbox" name={`sharepost[]`}  value={item.id}  ></input>
                                                        </td>
                                                    </tr>
                                                    </>
                                                ))
                                            }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colspan="3" className='text-right'>
                                                    <button type="submit" disabled={this.state.isSharePostShareSubmitLoading} className="btn btn-brand-1 hover-up w-100">Share post to groups</button>
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </form>
                                </>
                                : 
                                <><p className='text-center'>You have no groups for share this posts</p></>
                            }
                            </>
                            :
                            <>
                            {
                                this.state.my_connection_array.length  ? 
                                <>
                                    <form onSubmit={this.shareConnectionAndGorupSubmit}>
                                        <table className='table mt-5 table-striped border'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Connection</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.my_connection_array.map((item,index) => (
                                                    <>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td><span  className='text-capitalize'>{item.first_name+' '+item.last_name}</span> {'('+item.email+')'}</td>
                                                        <td>
                                                            <input type="checkbox" name={`sharepost[]`}  value={item.id} ></input>
                                                        </td>
                                                    </tr>
                                                    </>
                                                ))
                                            }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colspan="3" className='text-right'>
                                                    <button type="submit" disabled={this.state.isSharePostShareSubmitLoading} className="btn btn-brand-1 hover-up w-100">Share post to connections</button>
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </form>
                                </>
                                : 
                                <><p className='text-center'>You have no connections for share this post</p></>
                            }
                            </>
                        }
                       </>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}
export default JobSeekerDashboard;