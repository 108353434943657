import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

import { useParams } from "react-router";
import axios from "axios";

const View_applied_profile = () => {

   //let { userid } = useParams();

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [userData, setUserData] = useState([]);
   const [userEduData, setUserEduData] = useState([]);
   const [userWorkExpData, setUserWorkExpData] = useState([]);
   const [userLanguageData, setUserLanguageData] = useState([]);
   const [appliedJobId, setAppliedJobId] = useState(0);
   var   applied_job_id = 0 ;
   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      var applied_job_id2 = queryParameters.get("applied_job_id");
      var candidate_id = queryParameters.get("candidate_id");
      setAppliedJobId(applied_job_id2);
      (async () => { 
          try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/viewprofile/"+candidate_id);
               if(responceData.data.status == 1){
                  setUserData(responceData.data.data.userdata);
                  setUserEduData(responceData.data.data.edu_data);
                  setUserWorkExpData(responceData.data.data.expe_data);
                  setUserLanguageData(responceData.data.data.language_data);
               }
            } catch (error) {
               console.log(error);
            }
      })();

   }, []);

    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
               <div className="col-lg-9">
                  <div className="section-box resume-sec viewappliedjobsec">
                     <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Details</h5>
                           <Link to="/appliedjobs"  className="btn btn-default btn-small ml-auto">Back</Link>
                        </div>
                        
                        <div className="grayborderbox mb-20  personaldetail">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-user-o" aria-hidden="true"></i>
                                 Personal Details
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline mt-0">
                                       <div className="item-timeline">
                                          <div className="timeline-info d-flex">
                                             <div className="viewappliedjobprofile"> 
                                                <img alt="jcbapp" src={userData.profile_pic_url} />
                                             </div>
                                             <div className="row">
                                                <div className="col-md-6">
                                                   <h6 className="color-brand-1 ">Name : <span className="f-w-n">{userData.firstname}</span></h6>
                                                   <h6 className="color-brand-1">Email : <span className="f-w-n">{userData.email}</span></h6>
                                                </div>
                                                <div className="col-md-6">
                                                   <h6 className="color-brand-1">Mobile Number : <span className="f-w-n">{userData.mobile}</span></h6>
                                                   <h6 className="color-brand-1 mb-10">Location : <span className="f-w-n">{userData.address}</span></h6>
                                                   <div></div>
                                                </div>
                                                <div className="col-md-12">
                                                   <Link to={`/job-seeker-interview?id=${userData.unique_id}&applied_job_id=${appliedJobId}`} className="btn btn-default btn-brand icon-tick">Interview Process</Link>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="grayborderbox mb-20 profilesummary">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-list-alt" aria-hidden="true"></i>
                                 Profile Summary!
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline mt-0">
                                       <div className="item-timeline ">
                                          <div className="row w-100">
                                             <div className="col-md-6">
                                                <h6 className="color-brand-1 ">Job Title :<span className="f-w-n">{userData.job_title}</span></h6>
                                                <h6 className="color-brand-1">Total Experience :<span className="f-w-n">{userData.total_expyear} Years {userData.total_expmonths} Months</span></h6>
                                             </div>
                                             <div className="col-md-6">
                                                <h6 className="color-brand-1">Annual Salary :<span className="f-w-n">{userData.total_salary}</span></h6> 
                                                <h6 className="color-brand-1 mb-10">Job Industry :<span className="f-w-n">{userData.job_industry}</span></h6>
                                                <div></div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="grayborderbox mb-20 keyskills">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-life-ring" aria-hidden="true"></i>
                                 Key Skills
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline mt-0">
                                       <div className="item-timeline">
                                          <div className="timeline-info">
                                             <div className="d-flex">
                                                {userData.key_skills_string}
                                                {/* <h6 className="roundtab mr-5">HTML </h6>
                                                <h6 className="roundtab mr-5">CSS</h6>
                                                <h6 className="roundtab mr-5">JQuery</h6> */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        {/* <div className="grayborderbox mb-20 keyskills">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-laptop" aria-hidden="true"></i>
                                 IT Skills
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline mt-0">
                                       <div className="item-timeline">
                                          <table className="table table-responsive">
                                             <thead>
                                                <tr>
                                                   <th>Skills</th>
                                                   <th>Version</th>
                                                   <th>Last Used</th>
                                                   <th>Experience</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>
                                                      Manual					        
                                                   </td>
                                                   <td>
                                                      hgjkgk					        
                                                   </td>
                                                   <td>
                                                      2007					        
                                                   </td>
                                                   <td>
                                                      10 Years 5 Months					        
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}

                        <div className="grayborderbox mb-20">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-briefcase" aria-hidden="true"></i>
                                 Work &amp; Experience
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline  workexpetience">
                                       {
                                          userWorkExpData.length  ? 
                                          <>
                                          {
                                             userWorkExpData.map((item,index) => (
                                                <>
                                                 <div className="item-timeline">
                                                   <div className="timeline-year"> <span>{item.start_form} - {item.start_to}</span></div>
                                                   <div className="timeline-info">
                                                      <h6 className="color-brand-1 mb-5 text-capitalize">{item.organization_name}</h6>
                                                      <p className="color-text-paragraph-2 mb-10">{item.description}</p>
                                                      {/* <div className="d-flex">
                                                         <h6 className="roundtab mr-5">Available to join in 1 month</h6>
                                                      </div> */}
                                                   </div>
                                                </div>
                                                </>
                                             ))
                                          }
                                         
                                          </>
                                          : ''
                                       }
                                       

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="grayborderbox mb-20">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-graduation-cap" aria-hidden="true"></i>
                                 Education Background
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline  workexpetience">

                                       {

                                          userEduData.length ? 
                                          <>
                                             {
                                                userEduData.map((item,index) => (
                                                   <>
                                                   <div className="item-timeline">
                                                      <div className="timeline-year"> <span>{item.from_year} - {item.to_year}</span></div>
                                                      <div className="timeline-info">
                                                         <h6 className="color-brand-1 mb-5">{item.degree}</h6>
                                                         <p className="color-text-paragraph-2 mb-0 ml-10">{item.school_or_collage_name}</p>
                                                      </div>
                                                   </div>
                                                   </>
                                                ))
                                             }
                                             
                                          </> 
                                          : ''

                                       }
                                       
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="grayborderbox mb-20 keyskills">
                           <div className="box-padding">
                              <h5 className="icon-edu"><i className="fa fa-globe" aria-hidden="true"></i>
                                 Languages
                              </h5>
                              <div className="row mt-20">
                                 <div className="col-lg-12">
                                    <div className="box-timeline mt-0">
                                       <div className="item-timeline">
                                          <table className="table  table-responsive">
                                             <thead>
                                                <tr>
                                                   <th>Languages</th>
                                                   <th>Read</th>
                                                   <th>Write</th>
                                                   <th>Speak</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                             {
                                                          userLanguageData.length ?
                                                          <>
                                                            {
                                                               userLanguageData.map((item,index) => (
                                                                  <>
                                                                  <tr>
                                                                     <td> {item.language}	</td>
                                                                  
                                                                     <td>
                                                                        {
                                                                           item.read  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                     <td>
                                                                        {
                                                                           item.write  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                     <td>
                                                                        {
                                                                           item.speak  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                  </tr>
                                                                  </>
                                                               ))
                                                               
                                                            }
                                                          </>
                                                          : ''
                                                        }
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        {
                           userData.user_resume_url ? 
                           <>
                           <a  className="btn btn-default btn-brand icon-tick" target="_blank" href={userData.user_resume_url} download={`${userData.firstname}_resume`}>
                           <i className="fa fa-download mr-10" aria-hidden="true"></i>
                           Download Resume
                           </a>
                           </>
                           : ''
                        }
                        
                       
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
      </>
    );
  };
export default View_applied_profile;