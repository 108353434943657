import React ,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import { Modal, Button , Col, Row, Form , InputGroup  , FloatingLabel} from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Row from 'react-bootstrap/Row';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import axios from "axios";


const Groups = () => {

  useEffect(() => {  window.scrollTo(0, 0) }, [])
  const [validated, setValidated] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [textLoadingTitle, setTextLoadingTitle] = useState('Loading....');
  const [btnTitle, setBtnTitle] = useState('Create Group');
  const [groupData, setGroupData] = useState({'groupTitle' : '' ,'groupDesc' : '' ,'groupImage' : '' });

  const [groupsArr, setGroupsArr] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [requestGroups, setRequestGroups] = useState([]);
  const [newRequestGroups, setNewRequestGroups] = useState([]);
  //const [requestGroupsId, setRequestGroupsId] = useState([]);
  var storedNames = JSON.parse(localStorage.getItem("userlogin"));
  const [isResumeModalShow , isSetResumeModalShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
    const [groupIdValue , setGroupIdValue] = useState(false);
    const [isGroupCreate , setIsGroupCreate] = useState(true);
    const [groupDataArray , setGroupDataArray] = useState({
      groupTitle : '',
      groupDesc : '',
      groupImage : '',
    });
  

    const allGroupsListCall = (searchItem) => {
          (async () => {
            try {
            const states_list = await axios.get(`https://us.jobchatbox.com/api/user/api/groups/${storedNames.login_token}/1?search=${searchItem}`);
              if(states_list.data.status == 1){
                  setGroupsArr(states_list.data.data.groups);
                  setMyGroups(states_list.data.data.my_groups);
                  setRequestGroups(states_list.data.data.groupsrequests);
                  setNewRequestGroups(states_list.data.data.newrequest);
                  setTextLoadingTitle('Data Not Found');
              } else {
                setTextLoadingTitle('Data Not Found');
              }
            } catch (error) {
              console.log(error);
              setTextLoadingTitle('Data Not Found');
            }
        })();
    }
    useEffect(() => {
      allGroupsListCall(searchValue)
    },[]);                                                               

    const reload = () => {
      window.location.reload(false);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
          setCheckDisabled(true);
          setBtnTitle('Please Wait...');
          const formData = new FormData();
          //console.log(tagsSkillsArray);
         // var storedNames = JSON.parse(localStorage.getItem("userlogin"));
            formData.append('user_id', storedNames.id);
            formData.append('login_token', storedNames.login_token);
            formData.append('groupTitle', event.target.groupTitle.value);
            formData.append('groupDesc', event.target.groupDesc.value);
            if(groupIdValue){
              formData.append('groupId', groupIdValue);
            }
            formData.append('groupImage', event.target.groupImage.files[0] ? event.target.groupImage.files[0] : '' );

            console.log(formData);
            try {
               let res = await fetch("https://us.jobchatbox.com/api/user/api/group_create", {
                  method: "POST",
                  body: formData ,
               });
               let resJson = await res.json();

               console.log(resJson)

               if (res.status === 200) {
                  setCheckDisabled(false);
                  setBtnTitle('Create Group');
                  (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;
                  if(resJson.status == 1){
                    allGroupsListCall(searchValue)
                    closeResumeModal()
//                    setTimeout(function() { reload();}, 1000);
                  }     
               } else {
                    notify('error','Some error occured.');
               }
            } catch (err) {
               console.log(err);
            }
        }
        setValidated(true);  
    }

    const group_trash  = (e) => {

      if (isDeleting) {
        return; // Prevent multiple delete requests simultaneously
      }
      setIsDeleting(true);
      if (window.confirm('Do you want to remove group from list ?') == true){
        (async () => {
            try {
              //alert(e.target.id)
            const states_list = await axios.get(`https://us.jobchatbox.com/api/user/api/group_deactive/${storedNames.login_token}/${e.target.id}?search=${searchValue}`);
            if(states_list.data.status == 1){
                (states_list.data.status == 1) ? notify('success',states_list.data.message) : notify('warning',states_list.data.message) ;
                setGroupsArr(states_list.data.data.groups);
                setMyGroups(states_list.data.data.my_groups);
                setRequestGroups(states_list.data.data.groupsrequests);
                setTimeout(() => {
                    setIsDeleting(false);
                }, 1000);
                
            }
            } catch (error) {
            console.log(error);
            }
        })();
      }
    }

    const request_to_join  = (e) => {
      
        (async () => {
            try {
            const states_list = await axios.get(`https://us.jobchatbox.com/api/user/api/save_group_request/${storedNames.login_token}/${e.target.id}?search=${searchValue}`);
            if(states_list.data.status == 1){
              (states_list.data.status  == 1 ) ? notify('success',states_list.data.message) : notify('warning',states_list.data.message) ;
                setGroupsArr(states_list.data.data.groups);
                setMyGroups(states_list.data.data.my_groups);
                setRequestGroups(states_list.data.data.groupsrequests);
            }
            } catch (error) {
            console.log(error);
            }
        })();
    }
    
    const cancel_to_join  = (e) => {
      if (window.confirm('Do you want to cancel group request ?') == true){
        (async () => {
            try {
            const states_list = await axios.get(`https://us.jobchatbox.com/api/user/api/cancel_group_request/${storedNames.login_token}/${e.target.id}?search=${searchValue}`);
            if(states_list.data.status == 1){
              (states_list.data.status  == 1 ) ? notify('success',states_list.data.message) : notify('warning',states_list.data.message) ;
                setGroupsArr(states_list.data.data.groups);
                setMyGroups(states_list.data.data.my_groups);
                setRequestGroups(states_list.data.data.groupsrequests);
            }
            } catch (error) {
            console.log(error);
            }
        })();
      }
    }

    const searchGroups = (e) => {
       setSearchValue(e.target.value);
       //console.log(e.target.value.length)
      //if(e.target.value.length >= 3 ){
         allGroupsListCall(e.target.value)
      //}
    }

    const checkGroupRequested = (val) => {
        return requestGroups.some(item => val === item.groupid);
    }

    const getRandNo  = () => {
       return   Math.random();
    }

    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };


    /*************/

    

    

    const groupdata = (groupid) => {
        (async () => {
          try {
          const group_data = await axios.get(`https://us.jobchatbox.com/api/user/api/group_details/${storedNames.login_token}/${groupid}`);
            if(group_data.data.status == 1){ 

              setGroupDataArray(
                {
                  groupTitle : group_data.data.data.groupdata.groupTitle  ,
                  groupDesc : group_data.data.data.groupdata.groupDesc  ,
                  groupImage : group_data.data.data.groupdata.groupImage
                }
              )
              setBtnTitle('Update Group')
              isSetResumeModalShow(true);
              setGroupIdValue(groupid);
              setIsGroupCreate(false);
            } else {
              setTextLoadingTitle('Data Not Found');
            }
          } catch (error) { console.log(error);setTextLoadingTitle('Data Not Found') }
      })();
    }

    const editModelShow = (id)   => {
      groupdata(id)
    }
    
    const addModelShow = ()   => {
      setBtnTitle('Create Group')
      setGroupDataArray(
        {
          groupTitle : ''  ,
          groupDesc : '' ,
          groupImage : ''
        }
      )
      isSetResumeModalShow(true);
      setGroupIdValue(false);
      setIsGroupCreate(true);
    }

    const  closeResumeModal = () => {
      isSetResumeModalShow(false)
    };

    const groupDataUpdateFun = (e) => {
      const { name, value } = e.target;
      setGroupDataArray((prevData) => ({ ...prevData, [name]: value }));
    }

    

    const acceptGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      (async () => {
         try {
            const resdata = await axios.get("https://us.jobchatbox.com/api/user/api/accept_group_request/"+storedNames.login_token+"/"+notificationID);
            // console.log(resdata.data.data.notifications);
            if(resdata.data.status == 1){
               //setNewRequestGroups(resdata.data.data.results) ;
             allGroupsListCall(searchValue)
               notify('info',resdata.data.message)
            } else  if(resdata.data.status == 2){
               notify('warning',resdata.data.message)
            } else {
               notify('error',resdata.data.message)
            }
         } catch (err) {
               console.log(err);
         }
      })();
   }

   const declineGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      if(window.confirm("Want to decline group request?")){
        (async () => {
          try {
              const resdata = await axios.get("https://us.jobchatbox.com/api/user/api/decline_group_request/"+storedNames.login_token+"/"+notificationID);
              if(resdata.data.status == 1){
                 //setNewRequestGroups(resdata.data.data.results) ;
                //allGroupsListCall();
                allGroupsListCall(searchValue)
                notify('info',resdata.data.message)
              } else  if(resdata.data.status == 2){
                notify('warning',resdata.data.message)
              } else {
                notify('error',resdata.data.message)
              }
          } catch (err) {
                console.log(err);
          }
        })();
      }
   }


    return (
      <>
         <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                      <div className="row">
                        <div className="col-12">
                          <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>Groups</h5> 
                            <span onClick={addModelShow} className="btn btn-default btn-small ml-auto">Add +</span>
                          </div>
                        </div>
                      </div>
                      <div className="connectionbockwrap">
                        <div className="list-tabs grouptabs">
                            <ul className="nav nav-tabs mb-20" role="tablist">
                              <li key='tab-job-1' ><a id="nav-tab-job-1" href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="false" className="active">My Groups ({myGroups.length})</a></li>
                              <li key='tab-job-2' ><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="false" >I Requested  ({requestGroups.length})</a></li>
                              <li key='tab-job-21' ><a id="nav-tab-job-21" href="#tab-job-21" data-bs-toggle="tab" role="tab" aria-controls="tab-job-21" aria-selected="false" >New Request  ({newRequestGroups.length})</a></li>
                              <li key='tab-job-3' ><a id="nav-tab-job-3" href="#tab-job-3" data-bs-toggle="tab" role="tab" aria-controls="tab-job-3" aria-selected="true" >Other Groups  ({groupsArr.length})</a></li>
                            </ul>
                            <div className="topsearch">
                              <form className="form-search">
                                <input  onChange={searchGroups} className="searchboxx" type="text" value={searchValue} placeholder="Search" />
                                <button className="btn btn-default font-heading display_none">Search</button>
                              </form>
                            </div>
                        </div>
                        <div className="tab-content grouptabcon" id="myTabContent-1">
                            {/*myGroups*/}
                            <div className="tab-pane fade active show" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                              <div className="row">
                                {
                                   myGroups.length ?
                                   <>
                                   {
                                    myGroups.map((item,index) => (
                                        <>
                                        <div key={index+1} id={`item_1_${index+1}`} className="col-xl-4 col-md-6 col-sm-12 mb-20">
                                          <div className="groupbox">
                                            <div className="groupbox-top d-flex align-item-center">
                                              <div className="groupboximg">
                                                <Link to={`/group-details?id=${item.id}`}  >
                                                <img alt="jcbapp" src={item.groupImage} />
                                                </Link> 
                                              </div>
                                                
                                              <div className="groupname text-center">
                                                  {/* to={`/group-details/${item.id}`} */}
                                                    <Link className='text-group_title' to={`/group-details?id=${item.id}`}  >
                                                    {item.groupTitle}
                                                  </Link> 
                                                  <Link to={`/group-details?id=${item.id}`}  >
                                                  <span className="font-xs">{item.total_members} Members</span>
                                                  </Link>
                                                  <Link className="btn btn-default btn-small ml-auto" to={'/invite-member?id='+item.id}>Invite Members</Link>
                                              </div>
                                            </div>
                                            <div className="groupinfo">
                                                <h6 className="text-center">
                                                  <Link className='text-capitalize' to={(item.userdetails.usertype == 3) ? `/jobseeker-details?candidate=${item.userdetails.unique_id}` : `/recruiter-details?candidate=${item.userdetails.unique_id}`}  >
                                                  {item.userdetails.firstname}
                                                  </Link> 
                                                </h6>
                                                <div className="mt-5">
                                                  <div className="text-center">
                                                    <Link className="btn btn-tags-sm mb-10 mr-5" to={(item.userdetails.usertype == 3) ? '/jobseeker' : '/recruiters'} >{(item.userdetails.usertype == 3) ? 'Jobseeker' : 'Recruiter'}</Link>
                                                  </div>
                                                </div>
                                                <div className="employers-info align-items-center justify-content-center mt-30">
                                                  <div className="row align-items-end d-flex">
                                                    <div className="col-9">
                                                      <span className="d-flex align-items-center"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                      <span className="font-sm color-text-mutted">{item.userdetails.user_state} {item.userdetails.user_city}</span>
                                                      </span>
                                                      {item.userdetails.designation ? <><span className="d-flex align-items-center"><i className="fa fa-crosshairs" aria-hidden="true"></i><span className="font-sm color-text-mutted">{item.userdetails.designation}</span></span></> : <></>}
                                                      <Link className="font-sm color-text-mutted" to={`/group-conversation?groupid=${item.id}`}><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Conversations</Link>
                                                    </div>
                                                      <div className="col-3 text-right">  
                                                        <Link onClick={(e) => editModelShow(item.id)}  id={item.id} className="ml-auto deletegrp btn btn-default btn-small"  ><i id={item.id} className="fa fa-pencil-square-o" aria-hidden="true"></i></Link>
                                                        <Link onClick={group_trash}  disabled={isDeleting ? true : false } id={item.id} className={` ${isDeleting ? 'disabled' : ''} ml-auto deletegrp btn btn-default btn-small`}><i id={item.id} className="fa fa-trash-o" aria-hidden="true"></i></Link>
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                        </>
                                    )) 
                                   }
                                   </>
                                   : <><h3 className='text-center' >{textLoadingTitle}</h3> </> 
                                }
                                
                              </div>
                            </div>
                             {/*requestGroups*/}
                            <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2"  >
                              <div className="row">
                              {
                                   requestGroups.length ?
                                   <>
                                   {
                                    requestGroups.map((item,index) => (
                                        <>
                                        <div key={index+1} id={`item_1_${index+1}`} className="col-xl-4 col-md-6 col-sm-12 mb-20">
                                          <div className="groupbox">
                                            <div className="groupbox-top d-flex align-item-center">
                                              <div className="groupboximg">
                                                <Link className='text-group_title' to={`/group-details?id=${item.groupdata.id}`} >
                                                <img alt="jcbapp" src={item.groupImage} />
                                                </Link> 
                                              </div>
                                              <div className="groupname text-center">
                                                  <Link className='text-group_title' to={`/group-details?id=${item.groupdata.id}`} >
                                                      {item.groupdata.groupTitle }
                                                  </Link>  
                                                  <Link to={`/group-details?id=${item.groupdata.id}`} >
                                                  <span className="font-xs">{item.total_members} Members</span>
                                                  </Link> 

                                                
                                              </div>
                                            </div>
                                            <div className="groupinfo">
                                                <h6 className="text-center">
                                                <Link className='text-capitalize' to={(item.userdetails.usertype == 3) ? `/jobseeker-details?candidate=${item.userdetails.unique_id}` : `/recruiter-details?candidate=${item.userdetails.unique_id}`}  >
                                                  {item.userdetails.firstname}
                                                  </Link> 
                                                </h6>
                                                  
                                                  <div className="mt-5">
                                                    <div className="text-center">
                                                      <a className="btn btn-tags-sm mb-10 mr-5 display_none" href="jobs-grid.html">Admin</a>
                                                      <Link className="btn btn-tags-sm mb-10 mr-5" to={(item.userdetails.usertype == 3) ? '/jobseeker' : '/recruiters'} >{(item.userdetails.usertype == 3) ? 'Jobseeker' : 'Recruiter'}</Link>
                                                    </div>
                                                  </div>
                                                  <div className="employers-info align-items-center justify-content-center mt-30">
                                                    <div className="row align-items-end d-flex">
                                                      <div className="col-12">
                                                        <span className="d-flex align-items-center"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                        <span className="font-sm color-text-mutted">{item.userdetails.user_state} {item.userdetails.user_city}</span>
                                                        </span>
                                                        {item.userdetails.designation ? <><span className="d-flex align-items-center"><i className="fa fa-crosshairs" aria-hidden="true"></i><span className="font-sm color-text-mutted">{item.userdetails.designation}</span></span></> : <></>}
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                                <div className="text-center mt-20"><button onClick={cancel_to_join}  id={item.id} className="btn btn-default font-heading">Widraw</button></div>
                                            </div>  
                                          </div>
                                        </div>
                                        </>
                                    )) 
                                   }
                                   </>
                                   : <><h3 className='text-center'>{textLoadingTitle}</h3> </> 
                                }
                                  
                              </div>
                            </div>
                            {/*new request Groups*/}
                            <div className="tab-pane fade" id="tab-job-21" role="tabpanel" aria-labelledby="tab-job-21"  >
                              <div className="row">
                              {
                                   newRequestGroups.length ?
                                   <>
                                   {
                                    newRequestGroups.map((item,index) => (
                                        <>
                                        <div id={item.id} class="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                          <div class="dropdown-list-image mr-3">
                                            <img class="rounded-circle imgsrc" src={item.userdata.profile_pic_url} alt="" /></div>
                                          <div class="font-weight-bold mr-3 paddingleft_25px">
                                              <div className="small mb-2" dangerouslySetInnerHTML={{__html: item.nMessage}}></div>
                                              <button type="button" onClick={acceptGroupRequest} id={item.id} className="btn btn-outline-primary btn-sm mr-10">Accept</button>
                                              <button type="button" onClick={declineGroupRequest} id={item.id}   className="btn btn-outline-primary btn-sm ">Decline</button>
                                          </div>
                                          <span class="ml-auto notificationlast">
                                              <div class="text-right text-muted pt-1">{item.dayago} </div>
                                          </span>
                                        </div>                                        </>
                                    )) 
                                   }
                                   </>
                                   : <><h3 className='text-center'>{textLoadingTitle}</h3> </> 
                                  }
                                  
                              </div>
                            </div>
                              {/*groupsArr*/}
                            <div className="tab-pane fade" id="tab-job-3" role="tabpanel" aria-labelledby="tab-job-3">
                                <div className="row">
                                {
                                   groupsArr.length ?
                                   <>
                                   {
                                    groupsArr.map((item,index) => (
                                        <>
                                        <div  key={index+1} id={`item_1_${index+1}`} className="col-xl-4 col-md-6 col-sm-12 mb-20">
                                          <div className="groupbox">
                                            <div className="groupbox-top d-flex align-item-center">
                                              <div className="groupboximg">
                                                <img alt="jcbapp" src={item.groupImage} /></div>
                                              <div className="groupname text-center">
                                                  <Link  className='text-group_title' to={`/group-details?id=${item.id}`}  >
                                                  {item.groupTitle}
                                                  </Link>
                                                  <span className="font-xs">{item.total_members} Members</span>
                                              </div>
                                            </div>
                                            <div className="groupinfo">
                                                <h6 className="text-center">
                                                <Link className='text-capitalize' to={(item.userdetails.usertype == 3) ? `/jobseeker-details?candidate=${item.userdetails.unique_id}` : `/recruiter-details?candidate=${item.userdetails.unique_id}`}  >
                                                  {item.userdetails.firstname}
                                                  </Link> 
                                                </h6>
                                                <div className="mt-5">
                                                  <div className="text-center">
                                                    <Link className="btn btn-tags-sm mb-10 mr-5" to={(item.userdetails.usertype == 3) ? '/jobseeker' : '/recruiters'} >{(item.userdetails.usertype == 3) ? 'Jobseeker' : 'Recruiter'}</Link>
                                                  </div>
                                                </div>
                                                <div className="employers-info align-items-center justify-content-center mt-30">
                                                  <div className="row align-items-end d-flex">
                                                    <div className="col-12">
                                                      <span className="d-flex align-items-center"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                      <span className="font-sm color-text-mutted">{item.userdetails.user_state} {item.userdetails.user_city}</span>
                                                      </span>
                                                      {item.userdetails.designation ? <><span className="d-flex align-items-center"><i className="fa fa-crosshairs" aria-hidden="true"></i><span className="font-sm color-text-mutted">{item.userdetails.designation}</span></span></> : <></>}
                                                    </div>
                                                      {/* <div className="col-12 text-right">
                                                        <span onClick={request_to_join}  id={item.id} className="ml-auto btn btn-default btn-small cursor_pointer">Request to join</span>
                                                      </div> */}
                                                      {
                                                        checkGroupRequested(item.id) ?
                                                        <><div className="text-center mt-20"><button  onClick={request_to_join}  id={item.id}  className="btn btn-success font-heading">Requested</button></div></> 
                                                        : 
                                                        <><div className="text-center mt-20"><button  onClick={request_to_join}  id={item.id}  className="btn btn-default font-heading">Request to join</button></div></>
                                                      }
                                                      

                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                        </>
                                    )) 
                                   }
                                   </>
                                   : <><h3 className='text-center'>{textLoadingTitle}</h3> </> 
                                  }
                                </div>
                            </div>
                        </div>
                        
                      </div>
                  </div>
               </div>
            </div>
         </div>


         <Modal show={isResumeModalShow} onHide={closeResumeModal}>
            
            <Modal.Body>
                <>
                    <button className="btn-close" type="button"  onClick={closeResumeModal} data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="text-center">
                        {
                          isGroupCreate ? 
                          <>
                          <p className="font-sm text-brand-2">Create Group </p>
                          <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your new group</h3>
                          <p className="font-sm text-muted mb-30">Please fill in below information and create new group.</p>
                          </>
                          :
                          <>
                          
                           </>
                        }
                      </div>
                    <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="post_create_form" className="login-register text-start" autoComplete="off">
                        <div className="form-group">
                            <label className="form-label">Group Name *</label>
                            <Form.Control
                              required={true}
                              type="text"
                              name="groupTitle"
                              onChange={groupDataUpdateFun}
                              value={groupDataArray.groupTitle}
                              defaultValue= {groupData.groupTitle}
                            />
                        </div>
                      
                        <div className="form-group">      
                          <label className="form-label">Group Description *</label>                
                          <Form.Control as="textarea" 
                            required={true} 
                            rows={3} name="groupDesc" 
                            id="group_action_form"  
                            onChange={groupDataUpdateFun}
                            style={{minHeight:  '100px'}} 
                            value={groupDataArray.groupDesc}
                            defaultValue={groupData.groupDesc}
                           />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Group Image {isGroupCreate ? '*' : ''}</label>
                            {
                              groupDataArray.groupImage ?
                              <>
                              <br/>
                               <img  className={'group-image'} className={'groupImage'} src={groupDataArray.groupImage} />
                              </>
                              : 
                              <></>
                            }
                            <Form.Control
                              required={ isGroupCreate ? true : false }
                              type="file"
                              name="groupImage"
                              defaultValue= {groupData.groupImage}
                            />
                        </div>
                        
                        <div className="form-group">
                            <button disabled={checkDisabled} className="btn btn-default hover-up w-100" type="submit" name="login">{btnTitle}</button>
                        </div>
                        <div className="text-muted text-center">Do you need support? 
                          <Link to="/contactus">Contact Us</Link>
                        </div>
                    </Form>
                </>
            </Modal.Body>
        </Modal>

         <div className="modal fade" id="Modalgroup" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content apply-job-form">
                  <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  <div className="modal-body pl-30 pr-30 pt-50">
                      <div className="text-center">
                        <p className="font-sm text-brand-2">Create Group </p>
                        <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your new group</h3>
                        <p className="font-sm text-muted mb-30">Please fill in below information and create new group.</p>
                      </div>
                      <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="post_create_form" className="login-register text-start" autoComplete="off">
                        <div className="form-group">
                            <label className="form-label">Group Name *</label>
                            <Form.Control
                              required={true}
                              type="text"
                              name="groupTitle"
                              defaultValue= {groupData.groupTitle}
                            />
                        </div>
                      
                        <div className="form-group">      
                          <label className="form-label">Group Description *</label>                
                          <Form.Control as="textarea" required={true} rows={3} name="groupDesc" id="group_action_form"  style={{minHeight:  '100px'}} defaultValue={groupData.groupDesc} />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Group Image *</label>
                            <Form.Control
                              required={true}
                              type="file"
                              name="groupImage"
                              defaultValue= {groupData.groupImage}
                            />
                        </div>
                        
                        <div className="form-group">
                            <button disabled={checkDisabled} className="btn btn-default hover-up w-100" type="submit" name="login">{btnTitle}</button>
                        </div>
                        <div className="text-muted text-center">Do you need support? 
                          <Link to="/contactus">Contact Us</Link>
                        </div>
                      </Form>
                  </div>
                </div>
            </div>
          </div>
      </>
    );
  };
export default Groups;