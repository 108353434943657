import React,{useEffect,useState} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link,useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';

import axios from "axios";


const Sector_and_skills = () => {

    const [validated, setValidated] = useState(false);
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    const navigate = useNavigate();
    const [sectorDetailsArray, setSectorDetailsArray] = useState({
        'main_title' : '' ,
        'short_desc' : '' ,
        'sector_id' : 0 ,
     });
    
     

     useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const sector_id = queryParameters.get("id");
        
        if(typeof(sector_id)  == 'string'){
            (async () => {
                try {
                const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/get_sector/"+storedNames.login_token+'/'+sector_id);
                  if(responceData.data.status == 1){
                     //console.log(responceData.data.data);
                     let getdata =  responceData.data.data.single_sectors;
                     setSectorDetailsArray({
                        'main_title' : getdata.main_title ,
                        'short_desc' : getdata.short_desc ,
                        'sector_id' : getdata.id ,
                     })
                  }
                } catch (error) {
                  console.log(error);
                }
            })();
        }
        
     }, []);


     const addSectorChange  = (event) => {
        let fields =  event.target.name ;
        if(fields == 'main_title'){
            sectorDetailsArray.main_title = event.target.value ;
           setSectorDetailsArray({ ...sectorDetailsArray })
        }
        if(fields == 'short_desc'){
            sectorDetailsArray.short_desc = event.target.value ;
           setSectorDetailsArray({ ...sectorDetailsArray })
        }
     }

    const addSectorDetails = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
           event.stopPropagation();
        } else {
           const formData = new FormData();
           formData.append('logintoken', storedNames.login_token );
           formData.append('main_title', event.target.main_title.value );
           formData.append('short_desc', event.target.short_desc.value );
           formData.append('sector_id', event.target.sector_id.value );
           formData.append('page_type', 1 );
           try {
              let res = await fetch("https://us.jobchatbox.com/api/user/api/add_sector", {
                 method: "POST",
                 body: formData ,
              });
              let resJson = await res.json();
              if (res.status === 200) {
                 if(resJson.status  == 1){
                    toast.success(resJson.message);
                     setSectorDetailsArray({
                        'main_title' : '' ,
                        'short_desc' : '' ,
                        'sector_id' : 0 ,
                     });
                     setTimeout(function() { navigate('/sectors');}, 1000)
                 } 
              } 
           } catch (err) {
              console.log(err);
           }
         }
         setValidated(true);
     }

    return (
      <>
        <ToastContainer />
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div> 
              <div className="col-lg-9">
                <div className="section-box jobpost-sec">
                    <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>Add Sectors And Skills</h5>
                            <Link to="/sectors" className="btn btn-default btn-small ml-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</Link>
                        </div>
                        <div className="grayborderbox mb-30">
                            <div className="box-padding">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Form noValidate validated={validated}   onSubmit={addSectorDetails} name="addEductinInProfileForm" >
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                            <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Levels I hire for</label>
                                                <Form.Control
                                                    value={sectorDetailsArray.main_title}
                                                    required
                                                    type="text"
                                                    name="main_title"
                                                    placeholder="Levels I hire for:"
                                                    onChange={addSectorChange}
                                                />
                                            </div>
                                            </div>
                                            
                                            <div className="col-lg-12">
                                            <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Mid Level, Top Mangement Level</label>
                                                <Form.Control as="textarea" rows={3}  onChange={addSectorChange} name="short_desc" style={{height: '100px'}} value={sectorDetailsArray.short_desc} placeholder="Mid Level, Top Mangement Level" />
                                            </div>
                                            
                                                <div className="form-group mb-10 display_none"> <a href="#" className="btn btn-default btn-small ml-auto">Add More</a> </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                            <div className="col-lg-12">
                                                <div className="form-group mt-10 mb-0">
                                                    <input type="hidden" name="sector_id" value={sectorDetailsArray.sector_id} ></input>
                                                    <button className="btn btn-default btn-brand icon-tick">Submit</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Sector_and_skills;