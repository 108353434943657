import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Job_Seeker_Interview = () => {
   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [userDataLoad, setUserDataLoad] = useState(0);
   const [appliedJobId, setAppliedJobId] = useState(0);
   const [userData, setUserData] = useState([]);
   const [jobData, setJobData] = useState([]);
   const [userFormData, setUserFormData] = useState({
                                                      'cPerson_name' : '',
                                                      'cPerson_number' : '',
                                                      'interview_location' : '',
                                                      'interview_date' : '',
                                                      'interview_time' : '',
                                                      'candidate_name' : '',
                                                      'job_title' : '',

                                                      'cPerson_name_status_1' : '',
                                                      'cPerson_number_status_1' : '',
                                                      'interview_location_status_1' : '',
                                                      'interview_date_status_1' : '',
                                                      'interview_time_status_1' : '',

                                                      'interview_date_status_2' : '',
                                                      'interview_time_status_2' : '',

                                                      'interview_date_status_3' : '',
                                                      'interview_time_status_3' : '',

                                                      'cPerson_name_status_4' : '',
                                                      'cPerson_number_status_4' : '',
                                                      'interview_location_status_4' : '',
                                                      'interview_date_status_4' : '',
                                                      'interview_time_status_4' : '',

                                                   });

   useEffect(() => {
     
      (async () => { 
          try {
            const queryParameters = new URLSearchParams(window.location.search)
            const candidate = queryParameters.get("id");
            const applied_job_id = queryParameters.get("applied_job_id");
            setAppliedJobId(applied_job_id);

               const responceData = await axios.get("https://us.jobchatbox.com/api/userdetails/"+candidate);
               if(responceData.data.status == 1){
                  setUserData(responceData.data.data.results);
                  setUserDataLoad(1)
               }

               const resJobdata = await axios.get("https://us.jobchatbox.com/api/home/api/get_job_by_appliedjob_id/"+applied_job_id);
               if(resJobdata.data.status == 1){
                  let applied_job  = resJobdata.data.data.applied_job;
                  let job_status_data  = resJobdata.data.data.job_status_data;
                  let interview_status_data  = resJobdata.data.data.interview_status_data;
                  setUserFormData({
                     'cPerson_name' : job_status_data.cPerson_name ,
                     'cPerson_number' : job_status_data.cPerson_number ,
                     'interview_location' : job_status_data.interview_location ,
                     'interview_schedule_link' : job_status_data.interview_schedule_link ,
                     'interview_date' : job_status_data.interview_date ,
                     'interview_time' : job_status_data.interview_time ,

                     'cPerson_name_status_1' : interview_status_data.interview_status_data_1.cPerson_name ,
                     'cPerson_number_status_1' : interview_status_data.interview_status_data_1.cPerson_number ,
                     'interview_location_status_1' : interview_status_data.interview_status_data_1.interview_location ,
                     'interview_date_status_1' : interview_status_data.interview_status_data_1.interview_date ,
                     'interview_time_status_1' : interview_status_data.interview_status_data_1.interview_time ,

                     'interview_date_status_2' : interview_status_data.interview_status_data_2.interview_date ,
                     'interview_time_status_2' : interview_status_data.interview_status_data_2.interview_time ,


                     'interview_date_status_3' : interview_status_data.interview_status_data_3.interview_date ,
                     'interview_time_status_3' : interview_status_data.interview_status_data_3.interview_time ,


                     'cPerson_name_status_4' : interview_status_data.interview_status_data_4.cPerson_name ,
                     'cPerson_number_status_4' : interview_status_data.interview_status_data_4.cPerson_number ,
                     'interview_location_status_4' : interview_status_data.interview_status_data_4.interview_location ,
                     'interview_date_status_4' : interview_status_data.interview_status_data_4.interview_date ,
                     'interview_time_status_4' : interview_status_data.interview_status_data_4.interview_time ,
                     'position_applied_status_4' : applied_job.job_title ,
                     'position_applied_status_5' : applied_job.job_title ,
                     'position_applied_status_6' : applied_job.job_title ,
                     'page_type' : job_status_data.page_type ,
                     'interview_date_status_6' : interview_status_data.interview_status_data_6.interview_date ,
                     'job_title' : applied_job.job_title ,
                  });

                  console.log(interview_status_data.interview_status_data_6.interview_date)
                  setJobData(applied_job);
               }
            } catch (error) {
               console.log(error);
            }
      })();

   }, []);


   const [interview_time_am_or_pm, set_interview_time_am_or_pm] = useState('');

   const formatTimeWithAmPm = (time) => {
      const [hours, minutes] = time.split(':');
      const parsedHours = parseInt(hours, 10);
      const amPm = parsedHours >= 12 ? 'PM' : 'AM';
      const formattedHours = parsedHours % 12 || 12;
      return  `${formattedHours}:${minutes} ${amPm}`;
      //set_interview_time_am_or_pm(dddd);
   };

   /*-------------------------------*/

   

   const addInterviewFormChange1 = (e) => {
      let newSetValue =  e.target.value  ;
      if(e.target.name == 'cPerson_number_status_1'){
         const inputValue = e.target.value;
          newSetValue = inputValue.replace(/[^0-9+]/g, '');
          if(newSetValue.length > 12){
            return false ;
          }
      }
      if(e.target.name == 'interview_time_status_1'){
         set_interview_time_am_or_pm(formatTimeWithAmPm(e.target.value)) ;
      }
      setUserFormData({ ...userFormData, [e.target.name]: newSetValue });
   }

   //interview_time_am__or_pm
   const addInterviewFormData1 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
      
      formData.append('cPerson_name', event.target.cPerson_name_status_1.value);
      formData.append('cPerson_number', event.target.cPerson_number_status_1.value);
      formData.append('interview_location', event.target.interview_location_status_1.value);
      formData.append('interview_date', event.target.interview_date_status_1.value);
      formData.append('interview_time', event.target.interview_time_status_1.value);
      formData.append('interview_time_am_or_pm', interview_time_am_or_pm);
      formData.append('pagetype', 1);
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('applied_job_id', appliedJobId);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }
   
   /*-------------------------------*/
   const addInterviewFormChange2 = (e) => {
      let newSetValue =  e.target.value  ;
      if(e.target.name == 'cPerson_number_status_2'){
         const inputValue = e.target.value;
          newSetValue = inputValue.replace(/[^0-9+]/g, '');
          if(newSetValue.length > 12){
            return false ;
          }
      }
      if(e.target.name == 'interview_time_status_2'){
         set_interview_time_am_or_pm(formatTimeWithAmPm(e.target.value)) ;
      }
      if(e.target.name == 'interview_time_status_3'){
         set_interview_time_am_or_pm(formatTimeWithAmPm(e.target.value)) ;
      }
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }
   const addInterviewFormData2 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
     // formData.append('candidate_name', event.target.candidate_name_status2.value);
     // formData.append('job_title', event.target.job_title_status2.value);
      formData.append('interview_date', event.target.interview_date_status_2.value);
      formData.append('interview_time', event.target.interview_time_status_2.value);
      formData.append('interview_time_am_or_pm', interview_time_am_or_pm);
      formData.append('pagetype', 2);
      formData.append('applied_job_id', appliedJobId);
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }

   /*-------------------------------*/
   const addInterviewFormChange3 = (e) => {
      let newSetValue =  e.target.value  ;
      
      if(e.target.name == 'cPerson_number_status_3'){
         const inputValue = e.target.value;
          newSetValue = inputValue.replace(/[^0-9+]/g, '');
          if(newSetValue.length > 12){
            return false ;
          }
      }
      
      if(e.target.name == 'interview_time_status_3'){
         set_interview_time_am_or_pm(formatTimeWithAmPm(e.target.value)) ;
      }
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }
   const addInterviewFormData3 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
     // formData.append('candidate_name', event.target.candidate_name_status3.value);
     // formData.append('job_title', event.target.job_title_status3.value);
      formData.append('interview_date', event.target.interview_date_status_3.value);
      formData.append('interview_time', event.target.interview_time_status_3.value);
      formData.append('interview_time_am_or_pm', interview_time_am_or_pm);
      formData.append('pagetype', 3);
      formData.append('applied_job_id', appliedJobId);
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('interview_schedule_link', event.target.interview_schedule_link.value);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }

   /*-------------------------------*/
   const addInterviewFormChange4 = (e) => {
      let newSetValue =  e.target.value  ;
      if(e.target.name == 'cPerson_number_status_4'){
         const inputValue = e.target.value;
          newSetValue = inputValue.replace(/[^0-9+]/g, '');
          if(newSetValue.length > 12){
            return false ;
          }
      }
      if(e.target.name == 'interview_time_status_4'){
         set_interview_time_am_or_pm(formatTimeWithAmPm(e.target.value)) ;
      }
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }
   const addInterviewFormData4 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
      
      formData.append('cPerson_name', event.target.cPerson_name_status_4.value);
      formData.append('cPerson_number', event.target.cPerson_number_status_4.value);
      formData.append('interview_location', event.target.interview_location_status_4.value);
      formData.append('interview_date', event.target.interview_date_status_4.value);
      formData.append('interview_time', event.target.interview_time_status_4.value);
      formData.append('job_title', event.target.position_applied_status_4.value);
      formData.append('interview_time_am_or_pm', interview_time_am_or_pm);
      //position_applied_status_4
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('pagetype', 4);
      formData.append('applied_job_id', appliedJobId);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }

   /*-------------------------------*/
   const addInterviewFormChange5 = (e) => {
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }
   const addInterviewFormData5 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
      formData.append('job_title', event.target.position_applied_status_5.value);

      formData.append('pagetype', 5);
      formData.append('applied_job_id', appliedJobId);
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }


   /*-------------------------------*/
   const addInterviewFormChange6 = (e) => {
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }

   const addInterviewFormChange7 = (e) => {
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }


   const addInterviewFormData6 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
      formData.append('job_title', event.target.position_applied_status_6.value);
      formData.append('interview_date', event.target.interview_date_status_6.value);

      formData.append('pagetype', 6 );
      formData.append('applied_job_id', appliedJobId);
      formData.append('interview_status', event.target.interview_status.value);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }

   const addInterviewFormData7 = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData();
      formData.append('hired_date', event.target.hired_date.value);
      formData.append('applied_job_id', appliedJobId);
      formData.append('pagetype', 7);
      formData.append('job_id', jobData.id);
      formData.append('login_token', storedNames.login_token);
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/update_interview_process", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            (resJson.status) ?  toast.success(resJson.message) :  toast.warning(resJson.message)   ;
         } else {
         }
      } catch (err) {
         console.log(err);
      }
   }
   //addInterviewFormData5


  

   const addInterviewFormChange = (e) => {
      setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
   }

   const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };



  

    return (
      <>
      <ToastContainer />
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
              <div className="section-box jobpost-sec">
                     <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                        <h5>Schedule an interview for - {(userDataLoad ==  1) ? userData.firstname : ''}</h5>
                        <a href="/view-applied-profile" className="btn btn-default btn-small ml-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</a>
                     </div>
                   
                        <ul className="nav nav-tabs" id="emailtabs" role="tablist">
                          <li className={userFormData.page_type == 1 ? 'nav-item current_active' : 'nav-item'}    role="presentation">
                            <button className="nav-link active" id="emailtab1" data-bs-toggle="tab" data-bs-target="#emailtab1con" type="button" role="tab" aria-controls="emailtab1con" aria-selected="true"><img src="front/assets/imgs/interview.png" />Arrange An Interview</button>
                          </li>
                          <li className={userFormData.page_type == 2 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab2" data-bs-toggle="tab" data-bs-target="#emailtab2con" type="button" role="tab" aria-controls="emailtab2con" aria-selected="false"><img src="front/assets/imgs/call-shadule.png" />Schedule Phone Call</button>
                          </li>
                          <li className={userFormData.page_type == 3 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab3" data-bs-toggle="tab" data-bs-target="#emailtab3con" type="button" role="tab" aria-controls="emailtab3con" aria-selected="false"><img src="front/assets/imgs/skype.png" />Skype Interview</button>
                          </li>
                          <li className={userFormData.page_type == 4 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab4" data-bs-toggle="tab" data-bs-target="#emailtab4con" type="button" role="tab" aria-controls="emailtab4con" aria-selected="false"><img src="front/assets/imgs/facetoface.png" />Face To Face Interview</button>
                          </li>
                          <li className={userFormData.page_type == 5 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab5" data-bs-toggle="tab" data-bs-target="#emailtab5con" type="button" role="tab" aria-controls="emailtab5con" aria-selected="false"><img src="front/assets/imgs/rejected.png" />Rejected</button>
                          </li>
                          <li className={userFormData.page_type == 6 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab6" data-bs-toggle="tab" data-bs-target="#emailtab6con" type="button" role="tab" aria-controls="emailtab6con" aria-selected="false"><img src="front/assets/imgs/offer.png" />Offer Letter</button>
                          </li>
                          <li className={userFormData.page_type == 7 ? 'nav-item current_active' : 'nav-item'} role="presentation">
                            <button className="nav-link" id="emailtab7" data-bs-toggle="tab" data-bs-target="#emailtab7con" type="button" role="tab" aria-controls="emailtab7con" aria-selected="false"><img src="front/assets/imgs/hired.png" />Hired</button>
                          </li>
                        </ul>

                        <div className="tab-content" id="emailtabpanelwrap">
                          
                          {
                           (userDataLoad ==  1 && appliedJobId > 0) ? 
                           <>
                              <div className="tab-pane fade show active" id="emailtab1con" role="tabpanel" aria-labelledby="emailtab1">
                                 <form onSubmit={addInterviewFormData1}  className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       <div className="col-md-6">

                                           {/* <input type="time" value={time} onChange={handleTimeChange} pattern="[0-9]{2}:[0-9]{2}" /> */}


                                       <div className="form-group">
                                          <label className="form-label" for="firstname-1">Candidate Name</label>
                                          <input className="form-control readonly_text" id="firstname-1" type="readonly" value={userData.firstname+' '+userData.lastname}  placeholder="Please Enter Candidate Name" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="cPerson_name-1">Contact Person Name*</label>
                                          <input className="form-control"  required={true} id="cPerson_name-1" type="text" onChange={addInterviewFormChange1} name="cPerson_name_status_1"  value={userFormData.cPerson_name_status_1}  placeholder="Please Enter Client Name" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="contact_person_number">Contact Person Number*</label>
                                          <input className="form-control"  required={true} id="contact_person_number" type="text" onChange={addInterviewFormChange1}  name="cPerson_number_status_1" value={userFormData.cPerson_number_status_1} placeholder="Please Enter Contact Number" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="position_applied">Position Applied For</label>
                                          <input className="form-control readonly_text" id="position_applied" type="readonly"  name="job_title" value={userFormData.job_title}   placeholder="Position Applied For" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="interview_location">Location*</label>
                                          <input className="form-control"  required={true} id="interview_location" type="text" onChange={addInterviewFormChange1}  name="interview_location_status_1" value={userFormData.interview_location_status_1} placeholder="Please Enter Location" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="interview_date">Date*</label>
                                          <input className="form-control"  required={true} id="interview_date" type="date"  min={getTodayDate()}  onChange={addInterviewFormChange1} name="interview_date_status_1" value={userFormData.interview_date_status_1} placeholder="Please Enter Date" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="interview_time">Time{ userFormData.interview_time_status_1 ? '('+formatTimeWithAmPm(userFormData.interview_time_status_1)+')' : '' }*</label>
                                          <input className="form-control"  required={true} id="interview_time" type="time" onChange={addInterviewFormChange1}  name="interview_time_status_1"  value={userFormData.interview_time_status_1} placeholder="Please Enter Time" />
                                       </div>
                                       </div>
                                       <div className="col-md-12">
                                       <div className="form-group d-flex ">
                                          <input type="hidden" name="interview_status" value="1" />
                                          <input type="hidden" name="pagetype" value="1" />
                                          <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                       </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab2con" role="tabpanel" aria-labelledby="emailtab2">
                                 <form onSubmit={addInterviewFormData2} className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="candidate_name-1">Candidate Name</label>
                                             <input className="form-control readonly_text"  name="candidate_name" value={userData.firstname+' '+userData.lastname}  id="candidate_name-1"  type="readonly" placeholder="Please Enter Candidate Name" />
                                          </div>   
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="job_title-1">Position Applied For</label>
                                             <input className="form-control readonly_text"  name="job_title" value={userFormData.job_title} onChange={addInterviewFormChange2}  id="job_title-1" type="readonly" placeholder="Position Applied For" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_date-2">Date</label>
                                             <input className="form-control" name="interview_date_status_2" value={userFormData.interview_date_status_2} onChange={addInterviewFormChange2}  id="interview_date-2" type="date"  min={getTodayDate()}  placeholder="Please Enter Date" />
                                          </div>   
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_time-1">Time{ userFormData.interview_time_status_2 ? '('+formatTimeWithAmPm(userFormData.interview_time_status_2)+')' : '' }</label>
                                             <input className="form-control" name="interview_time_status_2" value={userFormData.interview_time_status_2} onChange={addInterviewFormChange2} id="interview_time-1" type="time"  placeholder="Please Enter Time" />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                       <div className="form-group d-flex ">
                                          <input type="hidden" name="interview_status" value="2" />
                                          <input type="hidden" name="pagetype" value="1" />
                                          <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                       </div>
                                       </div>

                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab3con" role="tabpanel" aria-labelledby="emailtab3">
                                 <form onSubmit={addInterviewFormData3} className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="candidate_name-1">Candidate Name</label>
                                             <input className="form-control"  name="candidate_name" value={userFormData.candidate_name} id="candidate_name-1" onChange={addInterviewFormChange} type="text" placeholder="Please Enter Candidate Name" />
                                          </div>   
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="job_title-1">Position Applied For</label>
                                             <input className="form-control"  name="job_title" value={userFormData.job_title}  id="job_title-1" type="text" onChange={addInterviewFormChange} placeholder="Position Applied For" />
                                          </div>
                                       </div>

                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_date-2">Date</label>
                                             <input className="form-control" name="interview_date_status_3" value={userFormData.interview_date_status_3} onChange={addInterviewFormChange2}  id="interview_date-2" type="date"  min={getTodayDate()}  placeholder="Please Enter Date" />
                                          </div>   
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_time-1">Time{ userFormData.interview_time_status_3 ? '('+formatTimeWithAmPm(userFormData.interview_time_status_3)+')' : '' }</label>
                                             <input className="form-control" name="interview_time_status_3" value={userFormData.interview_time_status_3} onChange={addInterviewFormChange2} id="interview_time-1" type="time"  placeholder="Please Enter Time" />
                                          </div>
                                       </div>

                                       <div className="col-md-12">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_schedule_link-1">Interview Schedule Link</label>
                                             <input className="form-control" maxLength={300} name="interview_schedule_link" value={userFormData.interview_schedule_link} onChange={addInterviewFormChange2} id="interview_schedule_link" type="text"  placeholder="Interview Schedule Link" />
                                          </div>
                                       </div>

                                       <div className="col-md-12">
                                          <div className="form-group d-flex ">
                                             <input type="hidden" name="interview_status" value="3" />
                                              <input type="hidden" name="pagetype" value="1" />
                                             <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                          </div>
                                       </div>

                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab4con" role="tabpanel" aria-labelledby="emailtab4">
                                 <form onSubmit={addInterviewFormData4}  className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="input-1">Candidate Name</label>
                                          <input className="form-control readonly_text" id="input-1" type="readonly" value={userData.firstname+' '+userData.lastname}  placeholder="Please Enter Candidate Name" />
                                       </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="input-1">Contact Person Name*</label>
                                             <input className="form-control"  required={true} id="input-1" type="text" onChange={addInterviewFormChange4} name="cPerson_name_status_4"  value={userFormData.cPerson_name_status_4}  placeholder="Please Enter Client Name" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="contact_person_number">Contact Person Number*</label>
                                             <input className="form-control"  required={true} id="contact_person_number" type="text" onChange={addInterviewFormChange4}  name="cPerson_number_status_4" value={userFormData.cPerson_number_status_4} placeholder="Please Enter Contact Number" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="position_applied">Position Applied For</label>
                                             <input className="form-control" id="position_applied" type="text" required={true} onChange={addInterviewFormChange4}  name="position_applied_status_4" value={userFormData.position_applied_status_4} placeholder="Position Applied For" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_location">Location*</label>
                                             <input className="form-control"  required={true} id="interview_location" type="text" onChange={addInterviewFormChange4}  name="interview_location_status_4" value={userFormData.interview_location_status_4} placeholder="Please Enter Location" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_date">Date*</label>
                                             <input className="form-control"  required={true} id="interview_date" type="date"  min={getTodayDate()}  onChange={addInterviewFormChange4} name="interview_date_status_4" value={userFormData.interview_date_status_4} placeholder="Please Enter Date" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="interview_time">Time{ userFormData.interview_time_status_4 ? '('+formatTimeWithAmPm(userFormData.interview_time_status_4)+')' : '' }*</label>
                                             <input className="form-control"  required={true} id="interview_time" type="time" onChange={addInterviewFormChange4}  name="interview_time_status_4"  value={userFormData.interview_time_status_4} placeholder="Please Enter Time" />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="form-group d-flex ">
                                             <input type="hidden" name="interview_status" value="4" />
                                             <input type="hidden" name="pagetype" value="1" />
                                             <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab5con" role="tabpanel" aria-labelledby="emailtab5">
                                 <form onSubmit={addInterviewFormData5} className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="input-1">Candidate Name</label>
                                             <input className="form-control readonly_text" type="readonly" value={userData.firstname+' '+userData.lastname} placeholder="Please Enter Candidate Name" />
                                          </div>
                                       </div>
                                       
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="position_applied">Position Applied For</label>
                                             <input className="form-control" id="position_applied" type="text" required={true} onChange={addInterviewFormChange5}  name="position_applied_status_5" value={userFormData.position_applied_status_5} placeholder="Position Applied For" />
                                          </div>
                                       </div>

                                       <div className="col-md-12">
                                          <div className="form-group d-flex ">
                                             <input type="hidden" name="interview_status" value="5" />
                                             <input type="hidden" name="pagetype" value="1" />
                                             <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab6con" role="tabpanel" aria-labelledby="emailtab6">
                                 <form onSubmit={addInterviewFormData6} className="emailtemplateform text-start mt-20 pb-30" action="#">
                                    <div className="row">
                                       
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="input-1">Candidate Name</label>
                                             <input className="form-control readonly_text" type="readonly" value={userData.firstname+' '+userData.lastname} placeholder="Please Enter Candidate Name" />
                                          </div>
                                       </div>
                                       
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="position_applied">Position Applied For</label>
                                             <input className="form-control" id="position_applied" type="text" required={true} onChange={addInterviewFormChange6}  name="position_applied_status_6" value={userFormData.position_applied_status_5} placeholder="Position Applied For" />
                                          </div>
                                       </div>

                                       <div className="col-md-6">
                                          <div className="form-group">
                                             <label className="form-label" for="input-1">Date</label>
                                             <input className="form-control" id="input-1" type="date"  min={getTodayDate()}  onChange={addInterviewFormChange6} name="interview_date_status_6" placeholder="Please Enter Date" />
                                          </div>
                                       </div>
                                       
                                       <div className="col-md-12">
                                          <div className="form-group d-flex ">
                                             <input type="hidden" name="interview_status" value="6" />
                                             <input type="hidden" name="pagetype" value="1" />
                                             <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>

                              <div className="tab-pane fade" id="emailtab7con" role="tabpanel" aria-labelledby="emailtab7">
                                 <form onSubmit={addInterviewFormData7}  className="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div className="row">
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="input-1">Candidate Name</label>
                                          <input className="form-control readonly_text" id="input-1" type="readonly" value={userData.firstname+' '+userData.lastname} placeholder="Please Enter Candidate Name" />
                                       </div>
                                       </div>
                                       
                                       <div className="col-md-6">
                                       <div className="form-group">
                                          <label className="form-label" for="input-1">Date</label>
                                          <input className="form-control" id="input-1" name="hired_date" type="date"  min={getTodayDate()} onChange={addInterviewFormChange7} placeholder="Please Enter Date" />
                                       </div>
                                       </div>

                                       <div className="col-md-12">
                                       <div className="form-group d-flex ">
                                          <button className="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                       </div>
                                       </div>
                                       </div>
                                 </form>
                                 
                              </div>
                           </>
                           :''

                          }
                          


                        </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Job_Seeker_Interview;