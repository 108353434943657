import React,{useState,useEffect} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//const MyStyle = {visibility: visible , animationDelay:' 0.1s' , animationName: fadeInUp }
const MyStyle = '';

const SubscribeModule = () => {
    
    const [subscription, setAllPostList] = useState(''); 

    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('email_id', event.target.email_id.value);
      try {
        let res = await fetch("https://us.jobchatbox.com/api/cms/subscription", {
           method: "POST",
           body: formData ,
        });
        let resJson = await res.json();
        if (res.status === 200) {
          (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;    
        } else {
        notify('error','Some error occured.');
        }
     } catch (err) {
        console.log(err);
     }
      // alert();
      
    }

    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    return(
            <>
              <ToastContainer />
              <section className="section-box mt-80 mb-30 mb-20 subscriptiosec">
                <div className="container">
                  <div className="box-newsletter box-newsletter-3">
                    <div className="row">
                      <div className="col-xl-12 text-center">
                        <div className="d-inline-block">
                          <h2 className="color-white mt-30">Read, Subscribe, Shine!</h2>
                          <p className="mt-10 font-lg color-white">Jobchatbox newsletter keeps you ahead of the job market.</p>
                          <div className="box-form-newsletter mt-30">
                            <form   onSubmit={handleSubmit} className="form-newsletter">
                              <input className="input-newsletter" required={true} name="email_id" type="email"  placeholder="Enter your email here" />
                              <button className="btn btn-default font-heading icon-send-letter">Subscribe</button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> 
            </>
        );
}

export default SubscribeModule;