import React , {useState, useEffect } from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Button from 'react-bootstrap/Button'; 
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


import {Link, useNavigate} from 'react-router-dom';

import { EditorState,ContentState , convertToRaw , convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//input tags 
//import { InputTags } from 'react-bootstrap-tagsinput'

//import './style.css';
import { WithContext as ReactTags } from 'react-tag-input';
import axios from "axios";

   const suggestions = [];
   const KeyCodes = { comma: 188, enter: 13};
   const delimiters = [KeyCodes.comma, KeyCodes.enter];
   const PostCreateCommonForm = () => {

   const [validated, setValidated] = useState(false);
   const [loading, setLoading] = useState(false);
   const [btnText, setBtntxt] = useState('Submit Post');
   const [textareaDefault, setTextareaDefault] = useState('Attain the position of \'Java Developer\' to implement my exceptional software development skills using new and improved technological tools and techniques for the development of the organization.');


   const [allStateList, setAllStateList] = useState([]);
   const [allCityList, setAllCityList] = useState([]);

   const [jsJobsTypeData, setjsJobsType] = useState([]);
   const [jsLocationData, setjsLocationData] = useState([]);
   const [employmentType, setEmploymentType] = useState([]);
   const [salaryRange, setSalaryRange] = useState([]);
   const [seniorityList, setSeniorityList] = useState([]);
   const [industryType, setIndustryType] = useState([]);
   const [jobsFunctions, setJobsFunctions] = useState([]);

   const [displayNone, setDisplayNone] = useState(false);
   const [stateListArray, setStateListArray] = useState([]);
   const [cityListArray, setCityListArray] = useState([]);
   const [preferedLocation_1, setPreferedLocation_1] = useState([]);
   const [preferedLocation_2, setPreferedLocation_2] = useState([]);
   const [preferedLocation_3, setPreferedLocation_3] = useState([]);

   const [jobFormValue, setJobFormValue] = useState({
            'job_id' : 0,
            'job_title' : '',
            'job_status' : '',
            'number_of_vacancies' : '',
            'job_skills' : '',
            'state_id' : '',
            'city_id' : '',
            'zipcode' : '',
            'location_id' : '',
            'prefered_state_1' : '',
            'prefered_state_2' : '',
            'prefered_state_3' : '',
            'prefered_city_1' : '',
            'prefered_city_2' : '',
            'prefered_city_3' : '',
            'salary_range' : '',
            'job_industry' : '',
            'job_function' : '',
            'job_employement' : '',
            'seniority_level' : '',
            'job_deadline' : '',
            'job_image' : '',
            'job_attachment' : '',
            'short_desc' : '',
            'description' : '',
            'deadLineDate' : '',
         });

   const [jobDescription, setJobDescription] = useState([]);
   const [editorState, setEditorState] = useState(() => 
                  EditorState.createWithContent(
                     ContentState.createFromBlockArray(
                     convertFromHTML(jobFormValue.description)
                     )
                  )
   );

   useEffect(() => {
      let html = convertToHTML(editorState.getCurrentContent());
      setJobDescription(html);

   }, [editorState]);
   
   const jobseeker_jobstype = async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setjsJobsType(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const jobseeker_location = async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setjsLocationData(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const employment_type = async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setEmploymentType(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const salary_range = async url => {
      const fetchData = async () => {
          try { const response = await fetch(url); const json = await response.json(); setSalaryRange(json.data.results);
          } catch (error) { console.log("error", error); }
      };
      fetchData();
   };
   
   const seniority_list = async url => {
      const fetchData = async () => {
          try { const response = await fetch(url); const json = await response.json(); setSeniorityList(json.data.results);
          } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const industry_type = async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setIndustryType(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const jobs_functions = async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setJobsFunctions(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };


   const getAllStateList= async url => {
      const fetchData = async () => {
         try { const response = await fetch(url); const json = await response.json(); setAllStateList(json.data.results);
         } catch (error) { console.log("error", error); }
      };
      fetchData();
   };

   const [backpage, set_backpage] = useState('');
   //const [backpage, set_backpage] = useState('');
   //jobid
      
   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      set_backpage(queryParameters.get("backpage"));
      //user/api/job_details/job_details

     // const queryParameters = new URLSearchParams(window.location.search)
      const jobid = queryParameters.get("jobid");
      if(jobid  !=  null){
        // alert(jobid);
        (async () => {
               try {
               const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/job_details/null/"+jobid);
                  if(responceData.data.status == 1){
                     //console.log(responceData.data.data.job_detail);
                     let job_detail =  responceData.data.data.job_detail ;

                     let prefered_state_1 =  '';
                     let prefered_state_2 =  '';
                     let prefered_state_3 =  '';

                     let prefered_city_1 =  '';
                     let prefered_city_2 =  '';
                     let prefered_city_3 =  '';

                     
                    if(job_detail.prefered_location){
                      let preferedLocationObj  =  JSON.parse(job_detail.prefered_location);
                      let preferedStateObj  =  JSON.parse(job_detail.prefered_state);
                      if(preferedStateObj){
                        prefered_state_1 =  preferedStateObj[0].label
                        prefered_state_2 =  preferedStateObj[1].label
                        prefered_state_3 =  preferedStateObj[2].label

                        prefered_city_1 =  preferedLocationObj[0].Value;
                        prefered_city_2 =  preferedLocationObj[1].Value;
                        prefered_city_3 =  preferedLocationObj[2].Value;
                        //prefered_city_1
                        getCityByStateCodeData(prefered_state_1,'usa_city_list','1');
                        getCityByStateCodeData(prefered_state_2,'usa_city_list','2');
                        getCityByStateCodeData(prefered_state_3,'usa_city_list','3');
                      }
                    }
                    getCityByStateCodeData(job_detail.state_id ,'usa_city_list','0');


                    // console.log(job_detail);
                     setJobFormValue({
                        'job_id' : job_detail.id ,
                        'job_title' : job_detail.job_title ,
                        'job_status' :  job_detail.jobtype  ,
                        'number_of_vacancies' : job_detail.no_of_posts ,
                        'job_skills' : '',
                        'state_id' : job_detail.state_id   ,
                        'city_id' : job_detail.city_id  ,
                        'zipcode' : job_detail.zip_code ,
                        'location_id' : '',
                        'prefered_state_1' : prefered_state_1,
                        'prefered_state_2' : prefered_state_2,
                        'prefered_state_3' : prefered_state_3,
                        'prefered_city_1' : prefered_city_1,
                        'prefered_city_2' : prefered_city_2,
                        'prefered_city_3' : prefered_city_3,
                        'salary_range' : job_detail.salary_range ,
                        'job_industry' : job_detail.job_industry  ,  
                        'job_function' : job_detail.job_category ,
                        'job_employement' : job_detail.employment_type ,
                        'seniority_level' : job_detail.seniority_level ,
                        'short_desc' : job_detail.short_desc ,
                        'job_deadline' : job_detail.job_deadline_date  ,
                        'job_image' : job_detail.job_image  ,
                        'job_attachment' : job_detail.job_attachment  ,
                     })

                     console.log(job_detail.job_skills_array);
                     setTagsRequireSkills(job_detail.job_skills_array)
                     setEditorState(EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                        convertFromHTML(job_detail.description)
                        )
                     ))
                     
                  }
               } catch (error) {
                  console.log(error);
               }
         })();
      }

      var storedNames = JSON.parse(localStorage.getItem("userlogin"));
      if(storedNames.usertype == 3){
         jobseeker_jobstype("https://us.jobchatbox.com/api//home/api/jobseeker_jobstype")
      } 
      if(storedNames.usertype == 6){
         jobseeker_jobstype("https://us.jobchatbox.com/api//home/api/recruiter_jobstype")
      } 
      jobseeker_location("https://us.jobchatbox.com/api//home/api/locations");
      employment_type("https://us.jobchatbox.com/api//home/api/employment_type");
      salary_range("https://us.jobchatbox.com/api//home/api/salary_range_list");
      seniority_list("https://us.jobchatbox.com/api//home/api/seniority_list");
      industry_type("https://us.jobchatbox.com/api//home/api/industry_type_list");
      jobs_functions("https://us.jobchatbox.com/api//home/api/jobs_functions_list");
      getAllStateList("https://us.jobchatbox.com/api/home/api/states_list");
      (async () => {
            try {
            const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/states_list");
            if(states_list.data.status == 1){
                  setStateListArray(states_list.data.data.results)
            }
            } catch (error) {
                  console.log(error);
            }
      })();
         
   }, []);

   const  getCityByStateCodeData  =  (statecode='0',apitype='usa_city_list',argPass) => {
      (async () => {
            try {
            const city_list = await axios.get("https://us.jobchatbox.com/api/home/api/"+apitype+"/"+statecode);
            if(city_list.data.status == 1){ 
                  //setCityListArray(city_list.data.data.results)
                  if(argPass == 1){ 
                  setPreferedLocation_1(city_list.data.data.results)
                  } else if(argPass == 2){
                  setPreferedLocation_2(city_list.data.data.results)
                  } else if(argPass == 3){
                  setPreferedLocation_3(city_list.data.data.results)
                  } else {
                     setCityListArray(city_list.data.data.results)
                  }
            }
            } catch (error) {
            console.log(error);
            }
      })();
   }

   const getCityByStateCode = (e) => {
      getCityByStateCodeData(e.target.value,'usa_city_list','0');
   } 

   const getCityByStateCodeMulti1 = (e) => {
      getCityByStateCodeData(e.target.value,'usa_city_list','1');
   } 

   const getCityByStateCodeMulti2 = (e) => {
      getCityByStateCodeData(e.target.value,'usa_city_list','2');
   } 

   const getCityByStateCodeMulti3 = (e) => {
      getCityByStateCodeData(e.target.value,'usa_city_list','3');
   }
   const navigate = useNavigate();
     
   const notify = (status_type,message) => {
         switch (status_type) {
            case 'warning':
               toast.warning(message)  
            break;

            case 'success':
               toast.success(message)  
            break;

            case 'info':
               toast.info(message)  
            break;

            case 'error':
               toast.error(message)  
            break;
         }
   };

   const handleFileUpload = () => {
      //alert();
   }

   const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {

        
          const formData = new FormData();
          const tagsSkillsArray = [];
          tagsRequieSkills.forEach(function (item) {
            tagsSkillsArray.push(item.id);
         });
          //console.log(tagsSkillsArray);
          var storedNames = JSON.parse(localStorage.getItem("userlogin"));
          formData.append('job_title', event.target.job_title.value);
          formData.append('jobtype', event.target.jobtype.value);
          formData.append('jobskills', tagsSkillsArray);
          formData.append('number_of_vacancies', event.target.number_of_vacancies.value);
          formData.append('state_id', event.target.state_id.value);
          formData.append('city_id', event.target.city_id.value);
          formData.append('location_id', event.target.location_id.value);
          formData.append('zipcode', event.target.zipcode.value);
          formData.append('salary_range', event.target.salary_range.value);
          formData.append('job_industry', event.target.job_industry.value);
          formData.append('job_function', event.target.job_function.value);
          formData.append('employment_type', event.target.employment_type.value);
          formData.append('seniority_level', event.target.seniority_level.value);
          formData.append('job_id', event.target.job_id.value);
          formData.append('short_desc', event.target.short_desc.value);
          formData.append('job_description', jobDescription );
          formData.append('login_token', storedNames.login_token);
          formData.append('job_deadline', event.target.job_deadline.value);
          formData.append('job_image', event.target.job_image.files[0]);
          formData.append('job_attachment', event.target.job_attachment.files[0]);
         var input = document.getElementsByName('user_prefered_state[]');
         var user_prefered_array =  [];
         for (var i = 0; i < input.length; i++) {
            let a = input[i];
            user_prefered_array[i] = a.value
         }
         
         var input2 = document.getElementsByName('prefered_location[]');
         var prefered_location_array =  [];
         for (var i = 0; i < input2.length; i++) {
               let a = input2[i];
               prefered_location_array[i] = a.value
         }

         formData.append('user_prefered_state', user_prefered_array);
         formData.append('user_prefered_location', prefered_location_array ); 
          setLoading(true);
          setBtntxt('Please Wait...');
            try {
               let res = await fetch("https://us.jobchatbox.com/api/jobseeker/jobpost_action", {
                  method: "POST",
                  body: formData ,
               });
               let resJson = await res.json();
               if (res.status === 200) {
               (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;
                  if(resJson.status == 1){

                     let redirect =   (backpage == 'mypost') ? '/my-job-post' : '/dashboard' ;
                     setTimeout(function() { navigate(redirect);}, 1000)
                  }     
               } else {
               notify('error','Some error occured.');
               }
            } catch (err) {
               console.log(err); 
            }
        }
        setValidated(true);  
   }

   //*******input tags ****** */
   const [tagsRequieSkills, setTagsRequireSkills] = React.useState([]);
   
   const handleDelete = i => {
      setTagsRequireSkills(tagsRequieSkills.filter((tag, index) => index !== i));
   };

   const handleAddition = tag => {
      setTagsRequireSkills([...tagsRequieSkills, tag]);
   };

   const handleDrag = (tag, currPos, newPos) => {
   const newTags = tagsRequieSkills.slice();
   newTags.splice(currPos, 1);
   newTags.splice(newPos, 0, tag);
   // re-render
   setTagsRequireSkills(newTags);
   };
   const handleTagClick = index => {
      console.log('The tag at index ' + index + ' was clicked');
   };

   const handleInput = (e) => {
      if(e.target.name != 'job_image'){
         setJobFormValue({ ...jobFormValue, [e.target.name]: e.target.value });
      }
   }
   const today = new Date().toISOString().split('T')[0];
   return (
      <>
           <ToastContainer />
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                     <div className="section-box jobpost-sec">
                           <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                              <h5>Post Job</h5>
                              <Link to={(backpage == 'mypost') ? '/my-job-post' : '/dashboard'}   className="btn btn-default btn-small ml-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>
                              Back To Job Listing</Link>
                           </div>
                           <div className="grayborderbox mb-30">
                              <div className="box-padding">
                                 <div className="row">
                                  
                                    <div className="col-lg-12">
                                        <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="post_create_form" className="login-register text-start" autoComplete="off">
                                       <div className="row">

                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Job Title*</label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="job_title"
                                                    onChange={handleInput}
                                                    value ={jobFormValue.job_title}
                                                    placeholder="Enter Job Title"
                                                />
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign">
                                                <label className="font-sm color-text-mutted mb-5">Status For Join*</label>
                                                <Form.Select required className="form-control no-pad" name="jobtype" id="jobtype" aria-label="Default select example">
                                                <option value="" >Select</option> 
                                                    {
                                                    jsJobsTypeData.map((item,index) => (
                                                        <option  selected={jobFormValue.jobtype == item.index ? true :false}  key={index} value={item.index}>{item.job_type_value}</option>
                                                    ))
                                                    }
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Number of Vacancies*</label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    onChange={handleInput}
                                                    value ={jobFormValue.number_of_vacancies}
                                                    name="number_of_vacancies"
                                                    defaultValue=""
                                                    placeholder="Enter Number of Vacancies"
                                                />
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Required Skills*:</label>
                                            
                                                <div className='reactTags'>
                                                <ReactTags
                                                         required
                                                         tags={tagsRequieSkills}
                                                         suggestions={suggestions}
                                                         delimiters={delimiters}
                                                         handleDelete={handleDelete}
                                                         handleAddition={handleAddition}
                                                         handleDrag={handleDrag}
                                                         handleTagClick={handleTagClick}
                                                         name="jobs_skills"
                                                         inputFieldPosition="bottom"
                                                         placeholder="Enter Skills"
                                                         autocomplete
                                                />
                                                </div>
                                                

                                             </div>
                                          </div>

                                          {/* Location or address */}
                                          <div className='col-md-6 mb-0'  >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">State*</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      onChange={getCityByStateCode} 
                                                      name="state_id" 
                                                      id="state_id" >
                                                      <option  selected={true} value="" >Select</option> 
                                                      {
                                                      stateListArray.map((item,index) => (
                                                         <option   selected={jobFormValue.state_id == item.state_code ? true :false}  key={index} value={item.state_code}>{item.state_name}</option>
                                                      ))
                                                      }   
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className='col-md-6 mb-0'   >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">City*</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      name="city_id" 
                                                      id="city_id" 
                                                      aria-label="Default select example">
                                                      <option  selected={true} value="" >Select</option> 
                                                         {
                                                         cityListArray.map((item,index) => (
                                                            <option  selected={jobFormValue.city_id == item.id ? true :false} key={index} value={item.id}>{item.city_name}</option>
                                                         ))
                                                         }
                                                </Form.Select> 
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Zipcode</label>
                                                <input className="form-control" onChange={handleInput}  value ={jobFormValue.zipcode} type="text" name="zipcode" maxLength="8" placeholder="Zipcode" />
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign">
                                                <label className="font-sm color-text-mutted mb-5">Job Location</label>
                                                <input className="form-control" onChange={handleInput}  value ={jobFormValue.location_id} type="text" name="location_id"  placeholder="Location or Address" />
                                             </div>
                                          </div>


                                          {/* ********************************************** */}
                                          {/* Location for address prefered */}
                                          <div  className='col-md-6 mb-0' >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 1*</label>
                                                <Form.Select 
                                                      required 
                                                      className="form-control no-pad" 
                                                      onChange={getCityByStateCodeMulti1} 
                                                      name="user_prefered_state[]" 
                                                      id="user_prefered_state" >
                                                      <option  selected={true} value="" >Select</option> 
                                                      {
                                                      stateListArray.map((item,index) => (
                                                         <option  selected={jobFormValue.prefered_state_1 == item.state_code ? true :false}  key={index} value={item.state_code}>{item.state_name}</option>
                                                      ))
                                                      }   
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className='col-md-6 mb-0'  >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 1*</label>
                                                <Form.Select 
                                                      required
                                                      className="form-control no-pad" 
                                                      name="prefered_location[]" 
                                                      id="prefered_location" 
                                                      aria-label="Default select example">
                                                      <option  selected={true} value="" >Select</option> 
                                                         {
                                                            preferedLocation_1.map((item,index) => (
                                                               <option selected={jobFormValue.prefered_city_1 == item.id ? true :false}  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                            ))
                                                         }
                                                </Form.Select>
                                             </div>
                                          </div>

                                          {/* *************** */}
                                          {/* Location for address prefered 2 */}
                                          
                                          <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }  >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 2</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      onChange={getCityByStateCodeMulti2} 
                                                      name="user_prefered_state[]" 
                                                      id="user_prefered_state2" >
                                                      <option  selected={true} value="" >Select</option> 
                                                      {
                                                      stateListArray.map((item,index) => (
                                                         <option selected={jobFormValue.prefered_state_2 == item.state_code ? true :false}   key={index} value={item.state_code}>{item.state_name}</option>
                                                      ))
                                                      }   
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }   >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 2</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      name="prefered_location[]" 
                                                      id="prefered_location2" 
                                                      aria-label="Default select example">
                                                      <option  selected={true} value="" >Select</option> 
                                                         {
                                                         preferedLocation_2.map((item,index) => (
                                                            <option selected={jobFormValue.prefered_city_2 == item.id ? true :false} key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                         ))
                                                         }
                                                </Form.Select> 
                                             </div>
                                          </div>

                                          {/* Location for address prefered 3 */}
                                          
                                          <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }  >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 3 -{jobFormValue.prefered_state_3}</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      onChange={getCityByStateCodeMulti3} 
                                                      name="user_prefered_state[]" 
                                                      id="user_prefered_state3" >
                                                      <option  selected={true} value="" >Select</option> 
                                                      {
                                                      stateListArray.map((item,index) => (
                                                         <option selected={jobFormValue.prefered_state_3 == item.state_code ? true :false}   key={index} value={item.state_code}>{item.state_name}</option>
                                                      ))
                                                      }   
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }  >
                                             <div className="form-group mb-1">
                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 3</label>
                                                <Form.Select 
                                                      className="form-control no-pad" 
                                                      name="prefered_location[]" 
                                                      id="prefered_location3" 
                                                      aria-label="Default select example">
                                                      <option  selected={true} value="" >Select</option> 
                                                         {
                                                         preferedLocation_3.map((item,index) => (
                                                            <option selected={jobFormValue.prefered_city_3 == item.id ? true :false}  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                         ))
                                                         }
                                                </Form.Select> 
                                             </div>
                                          </div>
                                          {/* end  */}
                                          {/* ********************************************** */}



                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign">
                                                <label className="font-sm color-text-mutted mb-5">Salary*</label>
                                                <Form.Select required className="form-control no-pad" name="salary_range" id="salary_range" aria-label="Default select example">
                                                <option value="" >Select</option> 
                                                    {
                                                    salaryRange.map((item,index) => (
                                                        <option  selected={jobFormValue.salary_range == item.range ? true :false}  key={index} value={item.id}>{item.range}</option>
                                                    ))
                                                    }
                                                </Form.Select>
                                             </div>
                                          </div>


                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign"> 
                                                <label className="font-sm color-text-mutted mb-5">Job Industry*</label>
                                                <Form.Select required className="form-control no-pad" name="job_industry" id="job_industry" aria-label="Default select example">
                                                <option value="" >Select</option> 
                                                    {
                                                    industryType.map((item,index) => (
                                                        <option  selected={jobFormValue.job_industry == item.industry ? true :false} key={index} value={item.id}>{item.industry}</option>
                                                    ))
                                                    }
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign">
                                                <label className="font-sm color-text-mutted mb-5">Job Function*</label>
                                                <Form.Select required className="form-control no-pad" name="job_function" id="job_function" aria-label="Default select example">
                                                <option value="" >Select</option> 
                                                    {
                                                    jobsFunctions.map((item,index) => (
                                                        <option selected={jobFormValue.job_function == item.jobs_functions ? true :false}  key={index} value={item.id}>{item.jobs_functions}</option>
                                                    ))
                                                    }
                                                </Form.Select>

                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10 selectdesign">
                                                <label className="font-sm color-text-mutted mb-5">Employment Type*</label>
                                                <Form.Select required className="form-control no-pad" name="employment_type" id="employment_type" aria-label="Default select example">
                                                <option value="" >Select</option>  
                                                    {
                                                    employmentType.map((item,index) => (
                                                        <option selected={jobFormValue.job_employement == item.employment_type ? true :false} key={index} value={item.id}>{item.employment_type}</option>
                                                    ))
                                                    }
                                                </Form.Select>
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10">
                                                <label className="font-sm color-text-mutted mb-5">Seniority level*</label>
                                                   <Form.Select required className="form-control no-pad" name="seniority_level" id="seniority_level" aria-label="Default select example">
                                                      <option value="" >Select</option>  
                                                      {
                                                         seniorityList.map((item,index) => (
                                                            <option selected={jobFormValue.seniority_level == item.job_position ? true :false}  value={item.id}>{item.job_position}</option>
                                                         ))
                                                      }
                                                   </Form.Select>
                                             </div>
                                          </div>
                                          
                                          
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10">
                                                <label className="font-sm color-text-mutted mb-5">Job deadline</label>
                                                <input className="form-control" min={today}  onChange={handleInput}  value ={jobFormValue.job_deadline} type="date" name="job_deadline" maxLength="8" placeholder="Zipcode" />
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10">
                                                <label className="font-sm color-text-mutted mb-5">Poster Image</label>
                                                <input className="form-control" onChange={handleInput}   type="file" name="job_image" accept="image/png, image/gif, image/jpeg"   placeholder="job_image" />
                                                
                                                {
                                                   jobFormValue.job_image ? 
                                                   <><img src={jobFormValue.job_image} className="job_image_show" /></>
                                                   : ''
                                                }
                                                
                                                
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group mb-10">
                                                <label className="font-sm color-text-mutted mb-5">Job Attachment</label>
                                                <input className="form-control" onChange={handleInput}   type="file" name="job_attachment"  accept="image/png, image/gif, image/jpeg , application/pdf"   placeholder="job_attachment" />
                                                {/* {
                                                   jobFormValue.job_attachment ? 
                                                   <><img src={jobFormValue.job_attachment} className="job_image_show" /></>
                                                   : ''
                                                } */}
                                             </div>
                                          </div>


                                          {/* <div className="col-lg-12">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Job Short Description:</label>
                                                <textarea className="form-control" rows="5" name='short_desc'  placeholder="Short Description" value={jobFormValue.short_desc}   onChange={handleInput}>{jobFormValue.short_desc}</textarea>
                                             </div>
                                          </div> */}
                                          
                                          <div className="col-lg-12">
                                             <div className="form-group mb-10"> 
                                                <label className="font-sm color-text-mutted mb-5">Job Description:</label>
                                                   {
                                                         <Editor
                                                         editorStyle={{ height: '150px' }}
                                                         editorState={editorState}
                                                         onEditorStateChange={setEditorState}
                                                         wrapperClassName="wrapper-class"
                                                         editorClassName="editor-class"
                                                         toolbarClassName="toolbar-class1"
                                                         toolbar={{
                                                         options: ['inline', 'fontSize',  'list', 'textAlign',  'link',  'emoji', 'history'] ,
                                                         }}
                                                      />
                                                      }
                                             </div>
                                          </div>

                                          <div className="col-lg-12">
                                             <div className="form-group mt-10 mb-0">
                                                <input type="hidden" name='job_id' value={jobFormValue.job_id} />
                                                <input type="hidden" name='short_desc' value="" />
                                                <button className="btn btn-default btn-brand icon-tick" disabled={loading} >{btnText}</button>
                                             </div>
                                          </div>
                                       </div>
                                          </Form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        
                     </div>
                  </div>
            </div>
          </div>
        </div>
      </>
   );
  };
export default PostCreateCommonForm;