import React,{useState,useEffect} from "react";
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import axios from "axios";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import Select from 'react-select'

const ResumeUpdate = () => {

   useEffect(() => {  window.scrollTo(0, 0) }, [])

   const [eductionDegree, setEductionDegree] = useState([]);
   const [eduSessionYears, setEduSessionYears] = useState([]);

   const [coreDetailsArray, setCoreDetailsArray] = useState({
                                                         'full_name_in_resume' : '' ,
                                                         'job_title' : '' ,
                                                         'total_expyear' : '' ,
                                                         'total_expmonths' : '' ,
                                                         'total_salary' : '' ,
                                                         'total_salary_hourly' : '' ,
                                                         'job_objective' : '' ,
                                                      });

   const [eduFormArray, setEduFormArray] = useState({
                                                   'degree' : '' ,
                                                   'school_or_collage_name' : '' ,
                                                   'university_name' : '' ,
                                                   'percentage' : '' ,
                                                   'from_year' : '' ,
                                                   'to_year' : '' ,
                                                   'description' : '' ,
                                                   'profile_edu_id' : 0 ,
                                                   'profile_form_btn' : 'Add Eduction Details' ,
                                                });
   const [workExpFormArray, setWorkExpFormArray] = useState({
                                                   'organization_id' : '' ,
                                                   'start_form' : '' ,
                                                   'start_to' : '' ,
                                                   'description' : '' ,
                                                   'workexp_edu_id' : 0 ,
                                                   'workexp_form_btn' : 'Add Workexperience Details' ,
                                                });

   const [profileLanguage, setProfileLanguage] = useState({
                                                   'language_type' : '' ,
                                                   'language_read' : 1 ,
                                                   'language_write' : 1 ,
                                                   'language_speak' : 1 ,
                                                });

   const [userData, setUserData] = useState([]);
   const [userResumeName , setUserResumeName] = useState('');
   const [userResumeURL, setUserResumeURL] = useState('');
   const [userDataGet, setUserDataGet] = useState(0);
   const [userEductionData, setUserEductionData] = useState([]);
   const [userWorkExpData, setUserWorkExpData] = useState([]);
   const [userLanguageData, setUserLanguageData] = useState([]);

   const [validated0, setValidated0] = useState(false);
   const [validated, setValidated] = useState(false);
   const [validated2, setValidated2] = useState(false);
   const [validated4, setValidated4] = useState(false);

   const [jobsSkillKeyArray, setjobsSkillKeyArray] = useState([]);
   const [isHidden, setIsHidden] = useState(false);

   const options = [
      { value: 'English', label: 'English' },
      { value: 'Spanish', label: 'Spanish' },
      { value: 'Chinese', label: 'Chinese' },
      { value: 'Tagalog', label: 'Tagalog' },
      { value: 'Vietnamese', label: 'Vietnamese' },
      { value: 'French', label: 'French' },
      { value: 'Hindi', label: 'Hindi' },
    ]

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));

   const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 1; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {i == 1 ? 'Year' : 'Years'}</option>); } return itemElements }
   const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push( <option value={' '}>Month</option>);  for (let i = 1; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {i == 1 ? 'Month' : 'Months'} </option>); } return itemElements }
  
    const getUserDetails =  () => {
         var storedNames = JSON.parse(localStorage.getItem("userlogin"));
         (async () => {
            try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/resume_profile/"+storedNames.login_token);
               if(responceData.data.status == 1){
                  setUserData(responceData.data.data);
                  setUserEductionData(responceData.data.data.edu_data);
                  setUserWorkExpData(responceData.data.data.expe_data);
                  setUserLanguageData(responceData.data.data.language_data)
                  setUserResumeName(responceData.data.data.userdata.user_resume)
                  setUserResumeURL(responceData.data.data.userdata.user_resume_url)
                  setUserDataGet(1)
                  let key_skills =  responceData.data.data.userdata.key_skills ;
                  setjobsSkillKeyArray(JSON.parse(key_skills));
                  let userdata =  responceData.data.data.userdata ;
                  setCoreDetailsArray({
                     'full_name_in_resume' : userdata.full_name_in_resume ,
                     'job_title' : userdata.job_title ,
                     'total_expyear' : userdata.total_expyear ,
                     'total_expmonths' : userdata.total_expmonths ,
                     'total_salary' : userdata.total_salary ,
                     'total_salary_hourly' : userdata.total_salary_hourly ,
                     'job_objective' : userdata.job_objective ,
                  })
               }
            } catch (error) {
               console.log(error);
            }
      })();
    
    }
   
   useEffect(() => {
   
      getUserDetails();
      (async () => {
          try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/home/api/profile_degree/");
            if(responceData.data.status == 1){
               setEductionDegree(responceData.data.data.degree)
            }
          } catch (error) {
            console.log(error);
          }
      })();
      
      (async () => {
          try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/home/api/eduction_from_to_years");
            if(responceData.data.status == 1){
               setEduSessionYears(responceData.data.data.years)
            }
          } catch (error) {
            console.log(error);
          }
      })();

   }, []);


   const resumeUpload = async (e) => {
      e.preventDefault();

      //
      let fileName = e.target.files[0].name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      var ImageExt  = ['pdf','doc' ,'docx' , 'dotx'];
      if(ImageExt.includes(extension)){
          const formData = new FormData();
          formData.append('user_resume', e.target.files[0]);
          formData.append('logintoken', storedNames.login_token );
          setIsHidden(true);
          try {
              let res = await fetch("https://us.jobchatbox.com/api/profile/profile_resume_upload", {
                  method: "POST",
                  body: formData ,
              });
              let resJson = await res.json();
              if (res.status === 200) {
                  if(resJson.status  == 1){
                     toast.success(resJson.message);
                     setUserResumeName(resJson.data.name)
                     setUserResumeURL(resJson.data.url)
                  }
                  setIsHidden(false);
              } 
          } catch (err) {
              console.log(err);
              setIsHidden(false);
          }
      } else {
         toast.warning("You can upload only pdf and document type files");
      }
   }

   const coreHandleChange  = (event) => {
      let fields =  event.target.name ;
      if(fields == 'full_name_in_resume'){
         coreDetailsArray.full_name_in_resume = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'job_title'){
         coreDetailsArray.job_title = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'total_expyear'){
         coreDetailsArray.total_expyear = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'total_expmonths'){
         coreDetailsArray.total_expmonths = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'total_salary'){
         coreDetailsArray.total_salary = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'total_salary_hourly'){
         coreDetailsArray.total_salary_hourly = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
      if(fields == 'job_objective'){
         coreDetailsArray.job_objective = event.target.value ;
         setCoreDetailsArray({ ...coreDetailsArray })
      }
   }
   
   const addCoreDetails = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.stopPropagation();
      } else {
         const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('full_name_in_resume', event.target.full_name_in_resume.value );
         formData.append('job_title', event.target.job_title.value );
         formData.append('total_expyear', event.target.total_expyear.value );
         formData.append('total_expmonths', event.target.total_expmonths.value );
         formData.append('total_salary', event.target.total_salary.value );
         formData.append('total_salary_hourly', event.target.total_salary_hourly.value );
         formData.append('job_objective', event.target.job_objective.value );
         try {
            let res = await fetch("https://us.jobchatbox.com/api/profile/profile_coredetails_update", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  getUserDetails();
                  toast.success(resJson.message);
               } 
            } 
         } catch (err) {
            console.log(err);
         }
       }
       setValidated0(true);
   }
   
   const addEductinInProfile = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.stopPropagation();
      } else {
         const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('degree', event.target.degree.value );
         formData.append('school_or_collage_name', event.target.school_or_collage_name.value );
         formData.append('university_name', event.target.university_name.value );
         formData.append('percentage', event.target.percentage.value );
         formData.append('from_year', event.target.from_year.value );
         formData.append('to_year', event.target.to_year.value );
         formData.append('description', event.target.description.value );
         formData.append('profile_edu_id', event.target.profile_edu_id.value );
         try {
            let res = await fetch("https://us.jobchatbox.com/api/profile/profile_add_eduction", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  toast.success(resJson.message);
                  setUserEductionData(resJson.data.eduction_data);
                  setEduFormArray({
                     'degree' : '' ,
                     'school_or_collage_name' : '' ,
                     'university_name' : '' ,
                     'percentage' : '' ,
                     'from_year' : '' ,
                     'to_year' : '' ,
                     'description' : '' ,
                     'profile_edu_id' : 0 ,
                     'profile_form_btn' : 'Add Eduction Details' ,
                  })
               } else {
                  toast.warning(resJson.message);
               }
            } 
         } catch (err) {
            console.log(err);
         }
       }
       setValidated(true);
   }
   const actionEduction  = async (edit_id,action) => {
      //event.preventDefault();
      if(action == 'trash'){
         if (window.confirm('Do you want to remove post from list ?') == true) {
            (async () => {
                  try {
                  const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_remove_edution_skills/"+storedNames.login_token+"/"+edit_id);
                  if(responceData.data.status == 1){
                     toast.success(responceData.message);
                     setUserEductionData(responceData.data.data.eduction_data); 
                  }
                  } catch (error) {
                  console.log(error);
                  }
            })();
         }
      } else {
         (async () => {
            try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_get_edution_skills/"+storedNames.login_token+"/"+edit_id);
               if(responceData.data.status == 1){
                  let singleitem  = responceData.data.data.eduction_single_row ;
                  setEduFormArray({
                     'degree' : singleitem.degree ,
                     'school_or_collage_name' : singleitem.school_or_collage_name ,
                     'university_name' : singleitem.university_name ,
                     'percentage' : singleitem.percentage ,
                     'from_year' : singleitem.from_year ,
                     'to_year' : singleitem.to_year ,
                     'description' : singleitem.description ,
                     'profile_edu_id' : edit_id ,
                     'profile_form_btn' : 'Upldate Eduction Details' ,
                  })
               }
            } catch (error) {
               console.log(error);
            }
         })();
      }
   }
   
   const addWorkExperienceInProfile = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.stopPropagation();
      } else {
         const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('organization_name', event.target.organization_id.value );
         formData.append('start_form', event.target.start_form.value );
         formData.append('start_to', event.target.start_to.value );
         formData.append('description', event.target.description.value );
         formData.append('workexp_edu_id', event.target.workexp_edu_id.value );
         try {
            let res = await fetch("https://us.jobchatbox.com/api/profile/profile_add_workexperice", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  toast.success(resJson.message);
                  setUserWorkExpData(resJson.data.experience_data);
                  setWorkExpFormArray({
                     'organization_id' : '' ,
                     'start_form' : '' ,
                     'start_to' : '' ,
                     'description' : '' ,
                     'workexp_edu_id' : 0 ,
                     'workexp_form_btn' : 'Add Workexperience Details' ,
                  })
               } else {
                  toast.warning(resJson.message);
               }
            } 
         } catch (err) {
            console.log(err);
         }
      }
      setValidated2(true);
   }

   const actionExperience  = (work_exp_id,action) => {
      if(action == 'trash'){
         if (window.confirm('Do you want to remove from list ?') == true) {
            (async () => {
                  try {
                     const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_remove_workexperience/"+storedNames.login_token+"/"+work_exp_id);
                     if(responceData.data.status == 1){
                        toast.success(responceData.message);
                        setUserWorkExpData(responceData.data.data.experience_data); 
                     }
                  } catch (error) {
                     console.log(error);
                  }
            })();
         }
      } else {
         (async () => {
            try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_get_workexperience/"+storedNames.login_token+"/"+work_exp_id);
               if(responceData.data.status == 1){
                  let singleitem  = responceData.data.data.data_single_row ;
                  setWorkExpFormArray({
                     'organization_id' : singleitem.organization_name ,
                     'start_form' : singleitem.start_form ,
                     'start_to' : singleitem.start_to ,
                     'description' : singleitem.description ,
                     'workexp_edu_id' : work_exp_id ,
                     'workexp_form_btn' : 'Update Workexperience Details' ,
                  })
               }
            } catch (error) {
               console.log(error);
            }
         })();
      }
   }

   const deleteMyResume = (logintoken)  =>{

      if (window.confirm("Do you want to remove current resume?")) {
         
         (async () => {
            try {
               const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/delete_resume/"+storedNames.login_token);
               if(responceData.data.status == 1){
                  setUserResumeName('');
                  toast.success('Removed Successfully!');
               }
            } catch (error) {
               console.log(error);
            }
         })();
      }
   }

   const teghandleChange =  async (tags) => {
      setjobsSkillKeyArray(tags);

      const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('key_skills', JSON.stringify(tags));
         try {
            let res = await fetch("https://us.jobchatbox.com/api/profile/profile_tags_update", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
            } 
         } catch (err) {
            console.log(err);
         }
      console.log(tags);
   }
   const addLanguage = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.stopPropagation();
      } else {
         const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('language', event.target.language_type.value );
         formData.append('read', event.target.language_read.value );
         formData.append('write', event.target.language_write.value );
         formData.append('speak', event.target.language_speak.value );
         formData.append('lang_edit_id', event.target.lang_edit_id.value );
         try {
            let res = await fetch("https://us.jobchatbox.com/api/profile/profile_language_update", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  toast.success(resJson.message);
                  setUserLanguageData(resJson.data.language_data)
               } 
            } 
         } catch (err) {
            console.log(err);
         }
       }
       setValidated4(true);
   }
   const languageHandleChange  = (event) => {
      let fields =  event.target.name ;
      if(fields == 'language_type'){
         profileLanguage.language_type = event.target.value ;
         setProfileLanguage({ ...profileLanguage })
      }
      if(event.target.name == 'language_read'){
         if (event.target.checked) {
            profileLanguage.language_read = 1 ;
         } else {
            profileLanguage.language_read = 0 ;
         }
         setProfileLanguage({ ...profileLanguage })
      }

      if(event.target.name == 'language_write'){
         if (event.target.checked) {
            profileLanguage.language_write = 1 ;
         } else {
            profileLanguage.language_write = 0 ;
         }
         setProfileLanguage({ ...profileLanguage })
      }

      if(event.target.name == 'language_speak'){
         if (event.target.checked) {
            profileLanguage.language_speak = 1 ;
         } else {
            profileLanguage.language_speak = 0 ;
         }
         setProfileLanguage({ ...profileLanguage })
      }
   }
   const actionLanguage  = (lang_edit_id,action) => {
      if(action == 'trash'){
         if (window.confirm('Do you want to remove from list ?') == true) {
            (async () => {
                  try {
                     const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_remove_language/"+storedNames.login_token+"/"+lang_edit_id);
                     if(responceData.data.status == 1){
                        toast.success(responceData.message);
                        setUserLanguageData(responceData.data.data.language_data); 
                     }
                  } catch (error) {
                     console.log(error);
                  }
            })();
         }
      } else {
         // (async () => {
         //    try {
         //    const responceData = await axios.get("https://us.jobchatbox.com/api/profile/api/profile_get_lang/"+storedNames.login_token+"/"+lang_edit_id);
         //       if(responceData.data.status == 1){
         //          let singleitem  = responceData.data.data.data_single_row ;
                 
         //       }
         //    } catch (error) {
         //       console.log(error);
         //    }
         // })();
      }
   }

    return (
      <>
      <ToastContainer />
        <div className="dashboardsec pt-30 pb-30 viewappliedjobsec ">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                     <div className="section-box resume-sec">
                       
                        <div className="container">
                           {
                              (userDataGet > 0)  ? 
                              <>
                                 <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                       <h5>Update your CV</h5>
                                       <div className="row mt-20 align-items-center">
                                          <div className="col-lg-6 col-md-6">
                                             <div className="form-group ">
                                                
                                                {
                                                   !isHidden &&
                                                   <div className="box-upload">
                                                      <div className="add-file-upload">
                                                         <input onChange={resumeUpload} className="fileupload" type="file" name="file" id="upload_resume" accept=".doc, .docx,.pdf" />
                                                      </div>
                                                      <span for="upload_resume">Click on Plus icon and update your resume</span>
                                                   </div>
                                                }
                                                {
                                                   isHidden && 
                                                   <div class="box-upload p-20 text-success text-bold">Please Wait we are updating you resume.....</div>
                                                }
                                                
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 mtm-20"  >

                                             {
                                                userResumeName ? 
                                                <>
                                                 <span className="mr-2 pr-2"><a   className="btn btn-default btn-shadow text-blue-hyperlink"href={userResumeURL} target="_blank"><span class="fa fa-download" aria-hidden="true"></span>  Download Your Resume</a></span>
                                                 
                                                 <span className="mr-2 pr-2 pl-20">
                                                   <Link  onClick={ () => deleteMyResume(userData.userdata.login_token)} 
                                                   className="btn btn-default btn-danger text-blue-hyperlink" >
                                                   <span class="fa fa-trash" aria-hidden="true"></span>   Delete
                                                  </Link>
                                                </span>
                                                {/* <div className=" form-group d-flex align-items-center box-file-uploaded">
                                                   <a className="text-danger1 cursor_pointer delete_link"  onClick={ () => deleteMyResume(userData.userdata.login_token)} ><span class="fa fa-trash" aria-hidden="true"></span>   Delete</a>
                                                </div> */}
                                                </>
                                                : ''
                                             }
                                             
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                           
                                 <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                       <h5 className="icon-edu"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Profile  <Link to="/profile-edit" className='text-capitalize'></Link></h5>
                                       
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                             <Form noValidate validated={validated0}   onSubmit={addCoreDetails} name="addEductinInProfileForm" >
                                             <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Name</label>
                                                      <input className="form-control"  onChange={coreHandleChange}  name="full_name_in_resume" type="text" value={coreDetailsArray.full_name_in_resume}  placeholder="Name" />
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Job Title</label>
                                                      <input className="form-control" onChange={coreHandleChange} name="job_title" type="text" value={coreDetailsArray.job_title}  placeholder="Job Title" />
                                                   </div>
                                                </div>
                                                
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Total Experience Years</label>
                                                      <select required={true} className="form-control" onChange={coreHandleChange} name="total_expyear" >
                                                         { <YearExpNumberLoop select_attr={coreDetailsArray.total_expyear}  /> }
                                                      </select>
                                                      {/* <input className="form-control" onChange={coreHandleChange} type="number" name="total_expyear"  value={coreDetailsArray.total_expyear} placeholder="Total Experience" /> */}
                                                   </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Total Experience Months</label>
                                                      <select className="form-control" name="total_expmonths" onChange={coreHandleChange}  >
                                                         { <MonthExpNumberLoop select_attr={coreDetailsArray.total_expmonths} />  }
                                                      </select>
                                                      {/* <input className="form-control" onChange={coreHandleChange} type="number" name="total_expmonths" value={coreDetailsArray.total_expmonths} placeholder="Total Experience" /> */}
                                                   </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Expected Salary Yearly (in USD)</label>
                                                      <input className="form-control" maxLength={10} onChange={coreHandleChange} type="text" name="total_salary" value={coreDetailsArray.total_salary_actual}  placeholder="Annual Salary" />
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Expected Salary Hourly  (in USD)</label>
                                                      <input className="form-control" maxLength={3} onChange={coreHandleChange} type="text" name="total_salary_hourly" value={coreDetailsArray.total_salary_hourly}  placeholder="Hourly" />
                                                   </div>
                                                </div>

                                                <div className="col-lg-12">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Objective </label>
                                                      <textarea className="form-control height-100px" onChange={coreHandleChange}  rows="3"  name="job_objective" placeholder="Objective " value={coreDetailsArray.job_objective} ></textarea>
                                                   </div>
                                                </div>
                                                <div className="col-lg-12">
                                                   <div className="form-group mt-10 mb-0">
                                                      <button className="btn btn-default btn-brand icon-tick">Update</button>
                                                   </div>
                                                </div>
                                             </div>
                                             </Form>
                                             <div className="box-timeline mt-50">
                                                <div className="item-timeline1">
                                                   
                                                   <div className="timeline-info1">
                                                      
                                                      <h6 className="color-brand-1 mb-5 ">Name : <span className="f-w-n">{userData.userdata.firstname}</span></h6>
                                                      <h6 className="color-brand-1 mb-5">Job Title : <span className="f-w-n">{userData.userdata.job_title}</span></h6>
                                                      <h6 className="color-brand-1 mb-5">Total Experience : <span className="f-w-n">{userData.userdata.total_expyear} Years {userData.userdata.total_expmonths} Months</span></h6>
                                                      <h6 className="color-brand-1 mb-5">Annual Salary : <span className="f-w-n">{userData.userdata.total_salary} </span></h6>
                                                      <h6 className="color-brand-1 mb-5">Objective : <span className="f-w-n">{userData.userdata.job_objective}</span></h6>
                                                   </div>
                                                </div>                                          
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 {/* eduction */}
                                 <div className="grayborderbox mb-30" id="eduction_section">
                                    <div className="box-padding">
                                       <h5 className="icon-edu"><i class="fa fa-graduation-cap" aria-hidden="true"></i>Education</h5>
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                        
                                             <Form noValidate validated={validated}   onSubmit={addEductinInProfile} name="addEductinInProfileForm" >
                                                <div className="row">
                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">Degree*</label>
                                                         <select className="form-control" name="degree">
                                                               <option value=""  selected={true}>Select</option>
                                                               {
                                                                  eductionDegree.map((item,index) => (
                                                                     <>
                                                                     <option  selected={(eduFormArray.degree == item.key) ? true :''} key={item.key} value={item.key}>{item.value}</option>
                                                                     </>
                                                                  ))
                                                               }
                                                         </select>
                                                      </div>
                                                   </div>  
                                                   
                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">School / Collage Name*</label>
                                                         <Form.Control
                                                            required
                                                            defaultValue={eduFormArray.school_or_collage_name}
                                                            type="text"
                                                            name="school_or_collage_name"
                                                            placeholder=""
                                                         />
                                                      </div>
                                                   </div>  

                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">Board / University Name</label>
                                                         <Form.Control
                                                               required
                                                               defaultValue={eduFormArray.university_name}
                                                               type="text"
                                                               name="university_name"
                                                               placeholder=""
                                                         />
                                                      </div>
                                                   </div>

                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">Percentage*</label>
                                                         <Form.Control
                                                               defaultValue={eduFormArray.percentage}
                                                               required
                                                               type="number"
                                                               name="percentage"
                                                               placeholder=""
                                                         />
                                                      </div>
                                                   </div>

                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">From*</label>
                                                         <Form.Select name="from_year" required={true}>    
                                                               <option value=""  selected={true}>Select</option>
                                                               {
                                                                  eduSessionYears.map((item,index) => (
                                                                     <>
                                                                     <option selected={(eduFormArray.from_year == item.key) ? true :''} key={item.key} value={item.key}>{item.value}</option>
                                                                     </>
                                                                  ))
                                                               }
                                                         </Form.Select>
                                                      </div>
                                                   </div>
                                                   <div className="col-lg-6 col-md-6">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">To*</label>
                                                         <Form.Select name="to_year" required={true}>  
                                                               <option value=""  selected={true}>Select</option>
                                                               {
                                                                  eduSessionYears.map((item,index) => (
                                                                     <>
                                                                     <option selected={(eduFormArray.to_year == item.key) ? true :''} key={item.key} value={item.key}>{item.value}</option>
                                                                     </>
                                                                  ))
                                                               }
                                                         </Form.Select>
                                                      </div>
                                                   </div>
                                                   <div className="col-lg-12">
                                                      <div className="form-group mb-10"> 
                                                         <label className="font-sm color-text-mutted mb-5">Description</label>
                                                         <textarea className="form-control" name="description" rows="5" style={{height : '138px'}} placeholder="Description"  >{eduFormArray.description}</textarea>
                                                      </div>
                                                   </div>

                                                   <div className="col-lg-12">
                                                      <div className="form-group mt-10 mb-0">
                                                         <input type="hidden"  name="profile_edu_id" value={eduFormArray.profile_edu_id} ></input>
                                                         <button  type="submit" className="btn btn-default btn-brand icon-tick">{eduFormArray.profile_form_btn}</button>
                                                      </div>
                                                   </div>
                                                </div>
                                             </Form>

                                             <div className="box-timeline mt-30">
                                                {
                                                   userEductionData.map((item,index) => (
                                                      <>
                                                      <div className="item-timeline">
                                                         <div className="timeline-year"> <span>{item.from_year} - {item.to_year}</span></div>
                                                         <div className="timeline-info"> 
                                                            <h6 className="color-brand-1 mb-5 text-capitalize">{item.degree}</h6>
                                                            <p className="color-text-paragraph-2 mb-15">{item.description}<br/>{item.school_or_collage_name}</p>
                                                         </div>
                                                         <div className="timeline-actions"> 
                                                            <a className="btn btn-default btn-small mr-10" onClick={ (event) => actionEduction(item.id,'edit')}  ><i className="fa fa-pencil-square-o mr-0" aria-hidden="true"></i></a>
                                                            <a className="btn btn-default btn-small"  onClick={ (event) => actionEduction(item.id,'trash')}   ><i className="fa fa-trash-o mr-0" aria-hidden="true"></i></a>
                                                         </div>
                                                      </div>
                                                      </>
                                                   ))
                                                }
                                                


                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                                 {/* work experience  */}
                                 <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                       <h5 className="icon-edu"><i class="fa fa-briefcase" aria-hidden="true"></i>Work &amp; Experience</h5>
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                          <Form  noValidate validated={validated2}  onSubmit={addWorkExperienceInProfile} name="addWorkExperienceInProfileForm" method="post">

                                             <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">Organization*</label>
                                                      <Form.Control
                                                         defaultValue={workExpFormArray.organization_id}
                                                         required
                                                         type="text"
                                                         name="organization_id"
                                                         placeholder=""
                                                      />
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">From*</label>
                                                      <Form.Select name="start_form" required={true}>  
                                                            <option value=""  selected={true}>Select</option>
                                                            {
                                                               eduSessionYears.map((item,index) => (
                                                                  <>
                                                                  <option selected={(workExpFormArray.start_form == item.key) ? true :''} key={item.key} value={item.key}>{item.value}</option>
                                                                  </>
                                                               ))
                                                            }
                                                      </Form.Select>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      <label className="font-sm color-text-mutted mb-5">To*</label>
                                                      <Form.Select name="start_to" required={true}>  
                                                            <option value=""  selected={true}>Select</option>
                                                            {
                                                               eduSessionYears.map((item,index) => (
                                                                  <>
                                                                  <option selected={(workExpFormArray.start_to == item.key) ? true :''} key={item.key} value={item.key}>{item.value}</option>
                                                                  </>
                                                               ))
                                                            }
                                                      </Form.Select>
                                                   </div>
                                                </div>
                                                <div className="col-lg-12">
                                                   <div className="form-group mb-10">
                                                      <label className="font-sm color-text-mutted mb-10">Description</label>
                                                      <textarea className="form-control" name="description" rows="5" style={{height : '80px'}}   >{workExpFormArray.description}</textarea>
                                                   </div>
                                                </div>
                                                <div className="col-lg-12">
                                                   <div className="form-group mt-10">
                                                      <input type="hidden"  name="workexp_edu_id" value={workExpFormArray.workexp_edu_id} ></input>
                                                      <button className="btn btn-default btn-brand icon-tick">{workExpFormArray.workexp_form_btn}</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </Form>

                                             

                                             <div className="box-timeline mt-30 ">
                                                {
                                                   userWorkExpData.map((item,index) => (
                                                      <>
                                                         <div className="item-timeline">
                                                         <div className="timeline-year"> <span>{item.start_form} - {item.start_to}</span></div>
                                                         <div className="timeline-info">
                                                            <h6 className="color-brand-1 mb-5">{item.organization_name}</h6>
                                                            <p className="color-text-paragraph-2 mb-15">{item.description}</p>
                                                         </div>   
                                                         <div className="timeline-actions"> 
                                                            <a  onClick={ (event) => actionExperience(item.id,'edit') } className="btn btn-default btn-small mr-10"><i className="fa fa-pencil-square-o mr-0" aria-hidden="true"></i></a>
                                                            <a  onClick={ (event) => actionExperience(item.id,'trash') } className="btn btn-default btn-small"><i className="fa fa-trash-o mr-0" aria-hidden="true"></i></a>
                                                         </div>
                                                      </div>
                                                      </>
                                                   ))
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 {/* skills tags array */}
                                 <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                       <h5 className="icon-edu"><i class="fa fa-laptop" aria-hidden="true"></i>Skills</h5>
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                             
                                             <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                   <div className="form-group mb-10"> 
                                                      <TagsInput className="inputtypeTagsKey" value={jobsSkillKeyArray} onChange={teghandleChange} />
                                                   </div>
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="grayborderbox mb-30 display_none">
                                    <div className="box-padding">
                                       <h5 className="icon-edu">Languages</h5>
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                             
                                             
                                             {/* <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      
                                                      <input className="form-control" type="text" placeholder="Language" />
                                                   </div>
                                                </div><div className="col-lg-6 col-md-6">
                                                   <div className="form-group mb-10"> 
                                                      
                                                      <button className="btn btn-default btn-brand icon-tick">Add Language</button>
                                                   </div>
                                                </div>
                                             </div> */}
                                             <div className="box-timeline mt-0">
                                                <div className="item-timeline">
                                                   <div className="timeline-info">
                                                   <Select isMulti={true} options={options} />
                                                      {/* <div className="d-flex">
                                                         <h6 className="roundtab mr-5">Hindi<span className="remove ml-5"><i className="fa fa-times" aria-hidden="true"></i></span></h6><h6 className="roundtab mr-5">English<span className="remove ml-5"><i className="fa fa-times" aria-hidden="true"></i></span></h6><h6 className="roundtab mr-5">English <span className="remove ml-5"><i className="fa fa-times" aria-hidden="true"></i></span></h6>
                                                      </div> */}
                                                   </div>
                                                </div>                                          
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                                  {/* konwn language */}
                                 <div className="grayborderbox mb-20 language">
                                    <div className="box-padding">
                                       <h5 className="icon-edu"><i className="fa fa-globe" aria-hidden="true"></i>
                                          Languages
                                       </h5>
                                       <div className="row mt-20">
                                          <div className="col-lg-12">
                                             <div className="box-timeline mt-0">
                                                <div className="item-timeline1">
                                                   <Form noValidate validated={validated4}   onSubmit={addLanguage} name="addLanguageForm" >
                                                   <table className="table  table-responsive">
                                                      <thead>
                                                         <tr>
                                                            <th>Languages</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Speak</th>
                                                            <th>Action</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>

                                                         <tr>
                                                            <td>
                                                               <input type="text" className="form-control langitem_text" name="language_type" onChange={languageHandleChange} value={profileLanguage.language_type}  />
                                                            </td>
                                                            <td>
                                                               <input type="checkbox" name="language_read" onChange={languageHandleChange} value={profileLanguage.language_read} checked={(profileLanguage.language_read == 1) ? true : false }   />
                                                            </td>
                                                            <td>
                                                                <input type="checkbox" name="language_write" onChange={languageHandleChange} value={profileLanguage.language_write} checked={(profileLanguage.language_write == 1) ? true : false } />
                                                            </td>
                                                            <td>
                                                               <input type="checkbox" name="language_speak" onChange={languageHandleChange} value={profileLanguage.language_speak} checked={(profileLanguage.language_speak == 1) ? true : false }  />
                                                            </td>

                                                            <td>
                                                               <input type="hidden" value={0} name="lang_edit_id" ></input>
                                                               <input type="submit" className="btn btn-default btn-small mr-10" value="Add" name="add_language"  />
                                                            </td>
                                                         </tr>
                                                        
                                                        {
                                                          userLanguageData.length ?
                                                          <>
                                                            {
                                                               userLanguageData.map((item,index) => (
                                                                  <>
                                                                  <tr>
                                                                     <td> {item.language}	</td>
                                                                  
                                                                     <td>
                                                                        {
                                                                           item.read  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                     <td>
                                                                        {
                                                                           item.write  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                     <td>
                                                                        {
                                                                           item.speak  == 1 ? 
                                                                           <><i className="fa fa-check" aria-hidden="true"></i></>
                                                                           : '...'
                                                                        }
                                                                     </td>
                                                                     <td>
                                                                           <div class="timeline-actions">
                                                                              <a class="btn btn-default btn-small mr-10 display_none"><i class="fa fa-pencil-square-o mr-0" aria-hidden="true"></i></a>
                                                                              <a class="btn btn-default btn-small" onClick={ (event) => actionLanguage(item.id,'trash') } ><i class="fa fa-trash-o mr-0" aria-hidden="true"></i></a>
                                                                           </div>
                                                                     </td>
                                                                  </tr>
                                                                  </>
                                                               ))
                                                               
                                                            }
                                                          </>
                                                          : ''
                                                        }
                                                         

                                                      </tbody>
                                                   </table>
                                                   </Form>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </> 
                              : ''
                           }
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
    );
  };
export default ResumeUpdate;