import React,{useEffect,useState} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 import TagsInput from 'react-tagsinput'
 import 'react-tagsinput/react-tagsinput.css'



const Invite_member = () => {
     

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [groupUserDataArr, setGroupUserDataArr] = useState([]);
   const [getInivitedEmailArr, setGetInivitedEmailArr] = useState([]);
   const [checked, setChecked] = useState([]);
   const [allEmailTags, setAllEmailTags] = useState([]);
   const [emailIdForInvite, setEmailIdForInvite] = useState('');
   const [isHiddenSearchForm, setIsHiddenSearchForm] = useState(true);
   const [groupId, setGroupId] = useState(0);
   const [searchValue, setSearchValue] = useState('');
   const [dataLodingText, setDataLodingText] = useState('Loading...');

   const getInvitedMemebersFun  =  (searchValue) => {
      var gid = window.location.search.split('id=')[1];
      (async () => {
         try {
            const invites_members_for_group = await axios.get(`https://us.jobchatbox.com/api/user/api/members_for_invitation/${storedNames.login_token}/${gid}?search=${searchValue}`);
            if(invites_members_for_group.data.status == 1){
                 // console.log(invites_members_for_group.data..data.suggested_connection)
                 setGroupUserDataArr(invites_members_for_group.data.data.suggested_connection);
                 setGetInivitedEmailArr(invites_members_for_group.data.data.groupinvitations_byemail);
                 setGroupId(gid)
                 setDataLodingText('Data not found')
               }
            } catch (error) {
            console.log(error);
            }
      })();
   }
   useEffect(() => {
      getInvitedMemebersFun()
   },[]);

   const handleCheck = (event) => {
      var updatedList = [...checked]; if (event.target.checked) { updatedList = [...checked, event.target.value]; } else { updatedList.splice(checked.indexOf(event.target.value), 1);  }
      setChecked(updatedList); 
   };

   const allMemeberInvite = async (event) =>{
      
      if(checked.length  > 0){
         const formData = new FormData();
         formData.append('logintoken', storedNames.login_token );
         formData.append('checked_members', checked);
         formData.append('group_id', groupId);
         try {
            let res = await fetch("https://us.jobchatbox.com/api/user/api/save_invitations", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  toast.success(resJson.message);
                  getInvitedMemebersFun(searchValue)
               } else {
                  toast.warning(resJson.message);
               }
            } 
         } catch (err) {
            console.log(err);
         }
      }
   }

   const  checkInviteMemberTab  =  (type) => { setIsHiddenSearchForm(true); if(type == 'invite_member_email'){ setIsHiddenSearchForm(false); } }
   const searchGroups = (e) => { setSearchValue(e.target.value);  if(e.target.value.lenght > 2){  getInvitedMemebersFun(e.target.value) } }

   /*--------------------"add multiple email and send action functions "-----------------------*/
   

   const allEmailTagsChange =  (tags) => {
      setAllEmailTags(tags);
   }

   const addAllEmailClick = async () => {
      const formData = new FormData();
      formData.append('logintoken', storedNames.login_token );
      formData.append('all_email_for_invite', emailIdForInvite );
      formData.append('group_id', groupId );
      try {
         let res = await fetch("https://us.jobchatbox.com/api/user/api/send_invitation_by_email", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            if(resJson.status  == 1){ setGetInivitedEmailArr(resJson.data.groupinvitations_byemail);setEmailIdForInvite('') ;   toast.success(resJson.message); setAllEmailTags([])  } 
            else {  toast.warning(resJson.message); }
         } 
      } catch (err) {
         console.log(err);
      }

   }

   

    return (
      <>
         <ToastContainer />
         <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
               <div className="row">
                  <div className="col-lg-3">
                     <LeftSidebar />
                  </div>
                  <div className="col-lg-9">

                     <div className="row">
                        <div className="col-12">
                           <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                              <h5>Groups</h5>
                              <Link to="/groups" className="btn btn-default btn-small ml-auto">Back</Link>
                           </div>
                        </div>
                     </div>
                     <div className="connectionbockwrap inviterbockwrap">
                        <div className="list-tabs grouptabs invitetabs">
                           <ul className="nav nav-tabs" role="tablist">
                              <li><a id="nav-tab-job-1"  onClick={(e) => checkInviteMemberTab('invite_member')} href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="true" className="active">Invite Members</a></li>
                              <li><a id="nav-tab-job-2"  onClick={(e) => checkInviteMemberTab('invite_member_email')} href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="false" className="">Invite By Email</a></li>
                           </ul>

                           {
                              isHiddenSearchForm && <div class="topsearch"  >
                                 <form class="form-search">
                                    <input class="searchboxx" type="text" placeholder="Search by group title" value={searchValue} />
                                 </form>
                              </div>
                           }

                        </div>
                        <div className="tab-content grouptabcon invitetabcon" id="myTabContent-1">
                           <div className="tab-pane fade active show" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                              <div className="d-flex flex-wrap mt-15" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                           
                                 {
                                    groupUserDataArr.length  ? 
                                    <>
                                    {
                                        groupUserDataArr.map((item,index) => (
                                             <>
                                                <div className={item.userdata.check_invitation == 0 ? 'card-grid-2 connectionblock mb-5' : 'gridbg2 card-grid-2 connectionblock mb-5'}  > 
                                                   <div className="card-grid-2-image-left">
                                                      <div className="card-grid-2-image-rd">
                                                         <a className="text-capitalize" href="#">
                                                            <figure><img alt="jcbapp" src={item.userdata.profile_pic} /></figure>
                                                         </a>
                                                      </div>
                                                      <div className="card-profile">
                                                         <h6 className="text-left">
                                                            <Link className="text-capitalize" to={item.userdata.usertype == 3 ? `/jobseeker-details?candidate=${item.userdata.unique_id}` : `/recruiter-details?candidate=${item.userdata.unique_id}`}>
                                                              {item.userdata.firstname}
                                                            </Link>
                                                         </h6> 
                                                         <div className="font-xs color-text-mutted mb-5">{item.userdata.usertype_title}</div>
                                                         <div className="font-xs">
                                                            <i className="fa fa-map-marker" aria-hidden="true"></i> 
                                                            {item.userdata.user_state+' '+item.userdata.user_city}
                                                         </div>
                                                      </div>
                                                      {
                                                         item.userdata.check_invitation == 0 &&
                                                         <>
                                                            <label className="checkwrap">
                                                            <input value={item.userdata.unique_id} onChange={handleCheck} type="checkbox" />
                                                            <span></span>
                                                            </label>
                                                         </>
                                                      }
                                                      
                                                   </div>
                                                </div>
                                             </>
                                        ))
                                    }
                                    </> : 
                                    <><h3 className='text-center margin_auto'>{dataLodingText}</h3></>
                                 }
                              </div>
                              {(groupUserDataArr.length > 0) && <div className="invitebtn">
                                 <button  onClick={(e) => allMemeberInvite()} class="btn btn-default btn-small1" href="/">
                                    <i class="fa fa-user-plus" aria-hidden="true"></i> Invite
                                 </button>
                              </div>}
                           </div>
                           <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2">
                              <div className="row">
                                 <div className="col-md-12">
                                    <form className="emailinvteform">
                                       <div className="form-group">
                                           {/* <TagsInput placeHolder="Enter email here" value={allEmailTags} onChange={allEmailTagsChange} className="form-control inputtypeTagsKey"     /> */}
                                           <input type="text" className="form-control"  onChange={(e) => setEmailIdForInvite(e.target.value) }  value={emailIdForInvite}  placeholder="Enter email here (for the multiple email you can add by comma saperate)" />
                                          {/*  */}
                                          <div className="input-group-append">
                                             <button className="btn btn-default" onClick={addAllEmailClick} type="button">Invite</button>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                                 <div className="col-md-12">
                                     {
                                       getInivitedEmailArr.length ? 
                                       <>
                                       <h5>Invited Emails History</h5>
                                       <table className='table mt-5 table-striped border'>
                                       <thead>
                                          <tr>
                                             <th>Invited Email</th>
                                             <th>Date</th>
                                             <th>Status</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                          getInivitedEmailArr.map((item,index) => (
                                               <tr>
                                                 <th>{item.email_id}</th>
                                                 <td>{item.created_date}</td>
                                                 <td>{item.send_status ? 'Sent' : 'Fail'}</td>
                                               </tr>
                                          ) )
                                       }
                                       </tbody>
                                       </table>
                                       </>
                                       : ''
                                     }
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>  
               </div>
          </div>
         </div>
      </>
    );
  };
export default Invite_member;