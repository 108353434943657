import React,{useState,useEffect} from "react";
import { Link , Navigate , useNavigate} from "react-router-dom";
import { Modal, Button , Col, Row, Form } from 'react-bootstrap';
import axios from "axios";
const MyStyle = '';
const PricingPlans = () => {
    
  const [allPostList, setAllPostList] = useState([]);
  const [isResumeModalShow , isSetResumeModalShow] = useState(false);
  const [defaultCurrencyCode , setDefaultCurrencyCode] = useState('$');
  const [selectedPlanID , setSelectedPlanID] = useState(0);



  const [contactNumber, setContactNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const { value } = e.target;
    // Replace all non-numeric characters in the input
    const formattedNumber = value.replace(/\D/g, "");
    setContactNumber(formattedNumber);
  };




   const [baseUrl, setBaseurl] = useState('');
   useEffect(() => {
      (async () => {
         try {
               const getdata = await axios.get("https://us.jobchatbox.com/api/cms/pricing_plan");
               setAllPostList(getdata.data.data.price_plans);
               setBaseurl(getdata.data.data.base_url);
         } catch (error) { console.log(error); }
      })();
   }, []);


  
  /**********currencytype set *********/
  const [ipAddress, setIpAddress] = useState(false);
  const [ipAddressData, setIpAddressData] = useState('');
  useEffect(() => {
    const fetchIpAddress = async () => {
        try {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          //setIpAddress(data.ip) ;
          const getdata = await axios.get(`https://us.jobchatbox.com/api/cms/getdetail_by_ip/${data.ip}`);
          console.log("getdata.data.data.results")
          console.log(getdata.data.data.results)
          const getIpData =  getdata.data.data.results ;
          if(getIpData.geoplugin_countryCode == 'IN'){
            setDefaultCurrencyCode('$') ;
            const getdata = await axios.get(`https://us.jobchatbox.com/api/cms/pricing_plan?country=1&usdprice=${getIpData.geoplugin_currencyConverter}`);
            setAllPostList(getdata.data.data.price_plans);
          }
          //setIpAddressData(getdata.data.data.results);
        } catch (error) { console.error('Error fetching IP address:', error); }
      };  
    fetchIpAddress();
  }, []);


  /* Resume upload by modal*/
  const sharePostResume = (id)   => {
    isSetResumeModalShow(true);
    setSelectedPlanID(id)
  }
  
  const  closeResumeModal = () => {
    isSetResumeModalShow(false)
  };
  /* Resume upload by modal*/

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const formData = new FormData();
      //name contact_number  company_name email service_type service_need group_desc
      formData.append('name', event.target.name.value);
      formData.append('phone', event.target.contact_number.value);
      formData.append('company', event.target.company_name.value);
      formData.append('email_id', event.target.email.value);
      formData.append('service_type_name', selectedPlanID );
      formData.append('service_type', event.target.service_type.value);
      formData.append('service_need', event.target.service_need.value);
      formData.append('message', event.target.group_desc.value);
      formData.append('contact_type_action', 'pricing_plan');
      try {
        let res = await fetch("https://us.jobchatbox.com/api/cms/contact_form_action", {
          method: "POST",
          body: formData ,
        });
        let resJson = await res.json();
        if (res.status === 200) {
          //(resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ; 
            setTimeout(function(){
              navigate("/thank-you");
            },1000)
          // <Navigate to="/thank-you"/>
        } else {
           // notify('error','Some error occured.');
        }
      } catch (err) {
          console.log(err);
      }
    }
    setValidated(true);  
  }


    return(
        <section className="section-box overflow-visible pb-100 pricingsecs">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
                <h6 className="headtop clr-primary mb-10">OUR PRICING</h6>
                <h2 className="section-title mb-20">We offer great and premium prices.</h2>   
               
                {/* <p className="mb-20">Enjoy a <a href="#" className="hover">free 5-day trial</a> and experience the full service. No credit card required!</p> */}
               
            </div>
            
            <div className="col-md-8">
                <div className="max-width-price">
            <div className="block-pricing mt-70">
              <div className="row">
              
                  {
                    allPostList.length > 0 ?
                    <>
                    {
                      allPostList.map((itmeData,index) => (
                          <>
                            {
                            <>
                            <div className="col-xl-6 col-lg-6 col-md-6" data-wow-delay=".1s" style={{MyStyle}}>
                                <div className="box-pricing-item">
                                  <h3>{itmeData.plan_title}</h3>
                                  <div className="box-info-price">
                                    <span className="text-price color-brand-2">{defaultCurrencyCode}{itmeData.plan_price}</span>
                                    <span className="text-month">/{itmeData.plan_type}</span>
                                  </div>
                                  <div className="border-bottom mb-30">
                                    
                                  </div>
                                  <ul className="list-package-feature">
                                    {

                                        itmeData.plan_shortdescription_array.map((itmeValue,index2) => (
                                          <>
                                          {itmeValue ? <li>{itmeValue}</li> :'' } 
                                          </> 
                                        ))

                                    }
                                    
                                    
                                  </ul>
                                  <div>
                                    <Link className="btn btn-border" onClick={(e) => sharePostResume(itmeData.id)} >Choose plan</Link>
                                  </div>
                                </div>
                              </div>
                            </>
                            }
                          </>
                      ))
                    }
                    </>
                    :''
                }
              </div>
            </div>
          </div>           
            </div>
            
          </div>
          
        </div>
 
        <Modal show={isResumeModalShow} onHide={closeResumeModal}>
            
            <Modal.Body>
                <>
                <button className="btn-close" type="button"  onClick={closeResumeModal} data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="text-center">
                      <p className="font-sm text-brand-2">Choose Plan </p>
                      <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your new plan</h3>
                      <p className="font-sm text-muted mb-30">Please fill in below information to choose a plan.</p>
                    </div>
                      <Form  noValidate validated={validated} onSubmit={handleSubmit}   autocomplete="off" >
                        <div className="pricing_plan_form text-start row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Name *</label>
                            <Form.Control
                                required
                                type="text"
                                name="name"
                                placeholder="Enter your name"
                            />
                        </div>
                        
                        <div className="form-group col-md-6">
                          <label className="form-label">Phone Number *</label>
                          <Form.Control
                              required
                              type="tel"
                              name="contact_number"
                              placeholder="Enter your contact number"
                              maxLength={10}
                              value={contactNumber}
                              onChange={handleContactNumberChange}
                              isInvalid={!isValid}
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <label className="form-label">Company Name / Website url *</label>
                            <Form.Control
                                required
                                type="text"
                                name="company_name"
                                placeholder="Company Name / Website url"
                                
                            />
                        </div>

                        <div className="form-group col-md-12">
                          <label className="form-label">Email *</label>
                              <Form.Control
                                required
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                className="form-control"
                              />
                        </div>
                        
                        <div className="form-group col-md-6">
                            <label className="form-label">Services</label>
                            <select name="service_type" className="form-control">
                                <option>RPO</option>
                                <option>Staffing</option>
                                <option>Other</option>
                            </select>
                        </div>

                        <div className="form-group col-md-6"><label className="form-label">Services Need</label>
                          <select name="service_need"  className="form-control">
                              <option>Immediately</option>
                              <option>One Week</option>
                              <option>Two weeks</option>
                              <option>One Month</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          
                          <textarea required="" rows="3" name="group_desc" id="group_action_form" className="form-control"></textarea>
                        </div>
                        <div className="form-group">
                          <button className="btn btn-default hover-up w-100 mt-2" type="submit" name="submit">Submit</button>
                        </div>
                      <div className="text-muted text-center">Do you need support?<a href="/contactus">Contact Us</a></div>
                      </div>
                    </Form>
                </>
            </Modal.Body>
        </Modal>
      </section>

      

      

      
        );
}

export default PricingPlans;