import React from "react";
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

class JobseekerSignup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileImgFile: null,
            profileeErrorMessage: '',
                fields: {} ,
                error_class: {} ,
                errors : {} ,
                messages_res : {} ,
                locationFetchData : [] ,
                locationDataisLoaded: false ,
                loading : false ,
                submitbtntext : 'Register Now',
                industryTypeList : [] ,
                stateListFetchData : [] ,
                recStatusListData : [] ,
                instrustedIn : [] ,
                cityListFetchData : [] ,
                cityListFetchDataMulti :  [] ,
                cityListFetchDataMulti2 :  [] ,
                cityListFetchDataMulti3 :  [] ,
                multiSelectedCity : [] ,
                multi_input : [] ,
                location_2_hide :1 ,
                showPassword: false,
        };
        this.state.messages_res["message"] = "";
        this.state.messages_res["status"] = "";

        //this.state.city_name = ['Mumbai','Pune','Indore'];

 

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        fetch("https://us.jobchatbox.com/api/home/api/locations")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    locationFetchData: json,
                    locationDataisLoaded: true
                });
         });

         (async () => {
            try {
            const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/states_list");
            if(states_list.data.status == 1){
                this.setState({
                    stateListFetchData: states_list.data.data.results,
                });
            }
            } catch (error) {
                console.log(error);
            }
        })();

        
        (async () => {
            try {
            const states_list = await axios.get("https://us.jobchatbox.com/api//home/api/recruiter_jobstype");
            if(states_list.data.status == 1){
                this.setState({
                    recStatusListData: states_list.data.data.results
                });
            }
            } catch (error) {
            console.log(error);
            }
        })();
        
        (async () => {
            try {
            const states_list = await axios.get("https://us.jobchatbox.com/api//home/api/industry_type_list");
            if(states_list.data.status == 1){
                this.setState({
                    industryTypeList: states_list.data.data.results
                });
            }
            } catch (error) {
            console.log(error);
            }
        })();

        if(this.state.fields.user_state){
            //console.log("0")
            this.getCityByStateCodeData(this.state.fields.user_state,'usa_city_list','0');
        }
        
        if(this.state.fields.prefered_state_1){
            //console.log("1")
            this.getCityByStateCodeData(this.state.fields.prefered_state_1,'usa_city_list','1');
        }
        if(this.state.fields.prefered_state_2){
           // console.log("2")
            this.getCityByStateCodeData(this.state.fields.prefered_state_2,'usa_city_list','2');
        }
        if(this.state.fields.prefered_state_3){
            //console.log("3")
           // console.log(this.state.fields.user_state);
            this.getCityByStateCodeData(this.state.fields.prefered_state_3,'usa_city_list','3');
        }
    }

   
    getCityByStateCodeData  =  (statecode='0',apitype='usa_city_list',argPass) => {
        (async () => {
            try {
            const city_list = await axios.get("https://us.jobchatbox.com/api/home/api/"+apitype+"/"+statecode);
            if(city_list.data.status == 1){ 
                if(argPass == 1){ 
                    this.setState({cityListFetchDataMulti: city_list.data.data.results});
                } else if(argPass == 2){
                    this.setState({cityListFetchDataMulti2: city_list.data.data.results});
                } else if(argPass == 3){
                    this.setState({cityListFetchDataMulti3: city_list.data.data.results});
                } else {
                    this.setState({cityListFetchData: city_list.data.data.results});
                }
            }
            } catch (error) {
                    console.log(error);
            }
        })();
    }

    getCityByStateCode = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','0');
    } 
   
    getCityByStateCodeMulti = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','1');
    } 

    getCityByStateCodeMulti2 = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','2');
    } 

    getCityByStateCodeMulti3 = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','3');
    }
    

    handleClickLocation1 = (event) => {
        event.preventDefault();
        let ddd  = (this.state.location_2_hide == 1) ? 2 : ((this.state.location_2_hide == 2) ? 3 : 1)
        this.setState({location_2_hide: ddd});
    }

    removeLocation2 = (event) => {
        event.preventDefault();
        this.setState({location_2_hide: 1});
    }
    
    removeLocation3 = (event) => {
        event.preventDefault();
        this.setState({location_2_hide: 2});
    }
    
    handleFileChange(event){
       // alert();
       console.log(event.target.files[0]);
       //setFile(event.target.files[0])
    }

    handleChange(event) {
        let fields = this.state.fields;
        fields[event.target.name] = event.target.value;
        this.setState({
            fields
        });

        if((event.target.name  == 'password'  || event.target.name  == 're_password') ){
            let errors = {}; let error_class = {}; 
            const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            if(!strongRegex.test(fields.password)){ error_class["password"] = "error_class" ;  errors["password"] = "*Password must contain at least 8 characters, including uppercase letters, lowercase letters, and numbers.";  this.setState({ errors: errors});this.setState({ error_class: error_class});  }
            else {  errors["password"] = ""; error_class["password"] = "" ; this.setState({ errors: errors});this.setState({ error_class: error_class});  }
            if((fields.re_password.length > 0) && (fields.password != fields.re_password)){ error_class["re_password"] = "error_class" ; errors["re_password"] = "*Confirm-password  should be match with Password"; this.setState({ errors: errors});this.setState({ error_class: error_class}); } 
            else { errors["re_password"] = ""; error_class["re_password"] = "" ; this.setState({ errors: errors});this.setState({ error_class: error_class}); }
        }
    }

    //handleSubmit(event) {
    handleSubmit = async (event) => {
        event.preventDefault();
        console.log(this.state.fields);
        let messages_res = {};
        
        event.preventDefault();
        const formData = new FormData();

        formData.append('profile_image', event.target.company_logo.files[0]);
        
        formData.append('company_name', event.target.company_name.value);
        formData.append('full_name', event.target.full_name.value);
        formData.append('designation', event.target.designation.value);
        formData.append('email', event.target.email_address.value);
        formData.append('phone_number', event.target.phone_number.value);
        formData.append('username', event.target.username.value);
        formData.append('password', event.target.password.value);

        formData.append('key_skills', JSON.stringify(this.state.instrustedIn)); 
        formData.append('short_desc', event.target.short_desc.value);
        formData.append('user_state', event.target.user_state.value);
        formData.append('user_city', event.target.user_city.value);
        formData.append('join_status', event.target.join_status.value);
        formData.append('job_industry', event.target.hireing_for.value);

        // join_status    hireing_for 
        formData.append('website', event.target.website.value);
        formData.append('facebook_url', event.target.facebook_url.value);
        formData.append('twitter_url', event.target.twitter_url.value);
        formData.append('google_plus_url', event.target.google_plus_url.value);
        formData.append('linkedin_url', event.target.linkedin_url.value);
        formData.append('accept_agreeiment', event.target.accept_agreeiment.value);

        var input = document.getElementsByName('user_prefered_state[]');
                var user_prefered_array =  [];
                for (var i = 0; i < input.length; i++) {
                    let a = input[i];
                    user_prefered_array[i] = a.value
                }

            var input2 = document.getElementsByName('prefered_location[]');
            var prefered_location_array =  [];
            for (var i = 0; i < input2.length; i++) {
                let a = input2[i];
                prefered_location_array[i] = a.value
            }

        formData.append('user_prefered_state', user_prefered_array);
        formData.append('user_prefered_location', prefered_location_array ); 

        if (this.validateForm()) {
            this.state.loading = true ;
            this.state.submitbtntext = 'Please Wait...' ;
            try {
            let res = await fetch("https://us.jobchatbox.com/api/signup/recruiter", {
                method: "POST",
                body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                this.setState({ loading: false});
                this.setState({ submitbtntext: 'Register Now'});
                (resJson.status == 1) ? 
                this.notify('success',resJson.message) : 
                this.notify('warning',resJson.message) ;
                if(resJson.status == 1){
                    this.state.submitbtntext = resJson.message ;
                    setTimeout(function() {  window.location.href = "/thank-you"; }, 1000);
                }
            } else {
                this.notify('error','Some error occured.');
            }
            } catch (err) {
            console.log(err);
            }
        }
 
    }

    validated  = (toggle = false) => {
        return true
        return this.setState({isValidation: toggle})
    }


    handleProfileFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (!selectedFile.type.startsWith('image/')) {
            this.setState({ profileeErrorMessage: 'Please select an image file (JPEG, PNG).' });
            this.resetFileInput();
            return;
            }

            // Check file size (5MB limit)
            const maxSize = 5 * 1024 * 1024; // 5MB in bytes
            if (selectedFile.size > maxSize) {
            this.setState({ profileeErrorMessage: 'File size should be less than 5MB.' });
            this.resetFileInput();
            return;
            }

            // File is valid, update state and do further processing (e.g., upload)
            this.setState({
                profileImgFile: selectedFile,
                profileeErrorMessage: '',
            });
        }
    };
    resetFileInput = () => {
        this.fileInput.value = '';
    };

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        
        if (!fields["company_name"]) {
            formIsValid = false;
            errors["company_name"] = "*Please enter your company name.";
        }
        
        if (!fields["full_name"]) {
            formIsValid = false;
            errors["full_name"] = "*Please enter your Full Name.";
        }
        
        // if (!fields["location"]) {
        //     formIsValid = false;
        //     errors["location"] = "*Please select location.";
        // }
        
        // if (!fields["username"]) {
        //   formIsValid = false;
        //   errors["username"] = "*Please enter your username.";
        // }
  
        // if (typeof fields["username"] !== "undefined") {
        //   if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        //     formIsValid = false;
        //     errors["username"] = "*Please enter alphabet characters only.";
        //   }
        // }
  
        if (!fields["email_address"]) {
          formIsValid = false;
          errors["email_address"] = "*Please enter your email-ID.";
        }
  
        if (typeof fields["email_address"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["email_address"])) {
            formIsValid = false;
            errors["email_address"] = "*Please enter valid email-ID.";
          }
        }
  
        if (!fields["phone_number"]) {
          formIsValid = false;
          errors["phone_number"] = "*Please enter your mobile no.";
        }
  
        if (typeof fields["phone_number"] !== "undefined") {
          if (!fields["phone_number"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["phone_number"] = "*Please enter valid mobile no.";
          }
        }
  
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
        
        if (!fields["company_logo"]) {
          formIsValid = false;
          errors["company_logo"] = "*Please select company logo.";
        }
  
        // if (typeof fields["password"] !== "undefined") {
        //   if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        //     formIsValid = false;
        //     errors["password"] = "*Please enter secure and strong password.";
        //   }
        // }

        

        if (!fields["re_password"]) {
            formIsValid = false;
            errors["re_password"] = "*Please enter your confirm password.";
        } else if(fields["re_password"] != fields["password"]){
            formIsValid = false;
            errors["re_password"] = "*Confirm-password  should be match with Password";
        }
  
        this.setState({
          errors: errors
        });
        return formIsValid;

    }

    notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    teghandleChange =  (tags) => {
     this.setState({instrustedIn:tags})
    }

    handlePasswordToggle = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        return(
            <>
                <ToastContainer />
                <div className={this.state.messages_res.status}  >{this.state.messages_res.message}</div>
                <Form validated={this.validated} onSubmit={this.handleSubmit} className="login-register text-start mt-20" >
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Enter Company Name *</label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="company_name"
                                        placeholder="Enter Company Name"
                                        value={this.state.fields.company_name}  
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.company_name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Enter Your Name *</label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="full_name"
                                        placeholder="Enter Your Name"
                                        value={this.state.fields.full_name}  
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.full_name}</div>
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-10">
                                    <label class="form-label">Designation*</label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="designation"
                                        placeholder="Profile designation"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Email *</label>
                                    <Form.Control
                                        required
                                        type="email"
                                        name="email_address"
                                        placeholder="stevenjob@gmail.com"
                                        value={this.state.fields.email_address}  
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.email_address}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Phone Number *</label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="phone_number"
                                        maxlength='10'
                                        placeholder="Phone Number"
                                        value={this.state.fields.phone_number}  
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.phone_number}</div>
                                </div>
                            </div>

                            {/* Location for address */}
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Address State</label>
                                    <Form.Select 
                                        required
                                        className="form-control no-pad" 
                                        onChange={this.getCityByStateCode} 
                                        name="user_state" 
                                        id="user_state" >
                                        <option  selected={true} value="0" >Select</option> 
                                        {
                                        this.state.stateListFetchData.map((item,index) => (
                                            <option   key={index} value={item.state_code}>{item.state_name}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                    <div className="errorMsg">{this.state.errors.location}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Address City</label>
                                    
                                    <Form.Select
                                        required
                                        className="form-control no-pad" 
                                        name="user_city" 
                                        id="user_city" 
                                        aria-label="Default select example">
                                        <option  selected={true} value="" >Select</option> 
                                            {
                                            this.state.cityListFetchData.map((item,index) => (
                                                <option  key={index} value={item.id}>{item.city_name}</option>
                                            ))
                                            }
                                    </Form.Select> 
                                    <div className="errorMsg">{this.state.errors.location}</div>
                                </div>
                            </div>

                            {/* Location for address prefered 1 */}
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered State 1*</label>
                                    <Form.Select 
                                        required 
                                        className="form-control no-pad" 
                                        onChange={this.getCityByStateCodeMulti} 
                                        name="user_prefered_state[]" 
                                        id="user_prefered_state" >
                                        <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.stateListFetchData.map((item,index) => (
                                            <option selected={(this.state.fields.prefered_state_1 == item.state_code) ? true : false }   key={index} value={item.state_code}>{item.state_name}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered City 1*</label>
                                    <Form.Select 
                                        required
                                        className="form-control no-pad" 
                                        name="prefered_location[]" 
                                        id="prefered_location" 
                                        aria-label="Default select example">
                                        <option  selected={true} value="" >Select</option> 
                                            {
                                            this.state.cityListFetchDataMulti.map((item,index) => (
                                                <option selected={(this.state.fields.prefered_location_1 == (item.id+'__'+item.city_name)) ? true : false }   key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                            ))
                                            }
                                    </Form.Select>
                                </div>
                            </div>
                            
                            {/* *************** */}
                            {/* Location for address prefered 2 */}
                            <div  className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 3) ? 'display_none' : ''  } >
                                <a onClick={this.removeLocation2} className="remove_location_2 remove_location_button">Remove Location 2</a>
                            </div>
                            <div className={  (this.state.location_2_hide == 1) ? 'display_none' : 'col-md-6'  } >
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered State 2</label>
                                    <Form.Select 
                                        className="form-control no-pad" 
                                        onChange={this.getCityByStateCodeMulti2} 
                                        name="user_prefered_state[]" 
                                        id="user_prefered_state2" >
                                        <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.stateListFetchData.map((item,index) => (
                                            <option  selected={(this.state.fields.prefered_state_2 == item.state_code) ? true : false }  key={index} value={item.state_code}>{item.state_name}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={  (this.state.location_2_hide == 1) ? 'display_none' : 'col-md-6'  }  >
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered City 2</label>
                                    <Form.Select 
                                        className="form-control no-pad" 
                                        name="prefered_location[]" 
                                        id="prefered_location2" 
                                        aria-label="Default select example">
                                        <option  selected={true} value="" >Select</option> 
                                            {
                                            this.state.cityListFetchDataMulti2.map((item,index) => (
                                                <option  selected={(this.state.fields.prefered_location_2 == (item.id+'__'+item.city_name)) ? true : false } key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                            ))
                                            }
                                    </Form.Select> 
                                </div>
                            </div>

                            {/* Location for address prefered 3 */}
                            <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2 || this.state.loginscreen_status == 'login') ? 'display_none' : ''  }  >
                                <a onClick={this.removeLocation3} className="remove_location_3 remove_location_button">Remove Location 3</a>
                            </div>
                            <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2) ? 'display_none' : 'col-md-6'  } >
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered State 3</label>
                                    <Form.Select 
                                        className="form-control no-pad" 
                                        onChange={this.getCityByStateCodeMulti3} 
                                        name="user_prefered_state[]" 
                                        id="user_prefered_state3" >
                                        <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.stateListFetchData.map((item,index) => (
                                            <option selected={(this.state.fields.prefered_state_3 == item.state_code) ? true : false }  key={index} value={item.state_code}>{item.state_name}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2) ? 'display_none' : 'col-md-6'  } >
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="input-7">Prefered City 3</label>
                                    <Form.Select 
                                        className="form-control no-pad" 
                                        name="prefered_location[]" 
                                        id="prefered_location3" 
                                        aria-label="Default select example">
                                        <option  selected={true} value="" >Select</option> 
                                            {
                                            this.state.cityListFetchDataMulti3.map((item,index) => (
                                                <option selected={(this.state.fields.prefered_location_3 == (item.id+'__'+item.city_name)) ? true : false }  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                            ))
                                            }
                                    </Form.Select> 
                                </div>
                            </div>
                            {/* end  */}
                            <div>
                                <button  className={  (this.state.location_2_hide == 3) ? 'display_none' : 'btn btn-primary add_more_location_btn'  } onClick={this.handleClickLocation1} >Add More Location+</button>
                            </div>

                             {/* password fields */}
                            <div className="col-md-6">
                                <div className="form-group mb-10 passwordwrap">
                                    <label className="form-label" htmlFor="input-4">Password *</label>
                                    <Form.Control
                                        required
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="password"
                                        placeholder="************"
                                        value={this.state.fields.password}  
                                        onChange={this.handleChange}
                                        className={this.state.error_class.password}
                                    />
                                    <span class="eyebtn"  onClick={this.handlePasswordToggle} >
                                        <img src="front/assets/imgs/eye.svg" />
                                    </span>
                                    <div className="errorMsg">{this.state.errors.password}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10 passwordwrap">
                                    <label className="form-label" htmlFor="input-5">Re-Password *</label>
                                    <Form.Control
                                        required
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="re_password"
                                        placeholder="************"
                                        value={this.state.fields.re_password}  
                                        onChange={this.handleChange}
                                        className={this.state.error_class.re_password}
                                    />
                                    <span class="eyebtn"  onClick={this.handlePasswordToggle} >
                                        <img src="front/assets/imgs/eye.svg" />
                                    </span>
                                    <div className="errorMsg">{this.state.errors.re_password}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Website</label>
                                    <input value={this.state.fields.website} onChange={this.handleChange}   name="website"  className="form-control" type="text" required="" placeholder="Website" />
                                    <div className="errorMsg">{this.state.errors.website}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                    <label className="form-label">Company Logo</label>
                                    {/* onChange={this.handleChange} */}
                                    <input value={this.state.fields.company_logo}  ref={(ref) => (this.fileInput = ref)} onChange={this.handleProfileFileChange}   name="company_logo"  className="form-control" type="file" accept="image/png, image/gif, image/jpeg" />
                                    <div className="errorMsg">{this.state.errors.company_logo}</div>
                                    {this.state.profileeErrorMessage && <p style={{ color: 'red' }}>{this.state.profileeErrorMessage}</p>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-10">
                                    <label className="form-label">Description *</label>
                                    <textarea onChange={this.handleChange} name="short_desc"  className="form-control" >{this.state.fields.description}</textarea>
                                    <div className="errorMsg">{this.state.errors.description}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-5">Join Status *</label>
                                    <Form.Select 
                                        required
                                        className="form-control no-pad" 
                                        name="join_status" 
                                        id="join_status" >
                                        <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.recStatusListData.map((item,index) => (
                                            <option   key={index} value={item.job_type_value}>{item.job_type_value}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group selectdesign">
                                    <label className="form-label">Hiring for *</label>
                                    <Form.Select
                                        required
                                        className="form-control no-pad" 
                                        name="hireing_for" 
                                        id="hireing_for" >
                                        <option  selected={true}  value="0" >Select</option> 
                                        {
                                        this.state.industryTypeList.map((item,index) => (
                                            <option   key={index} value={item.id}>{item.industry}</option>
                                        ))
                                        }   
                                    </Form.Select>
                                    <div className="errorMsg">{this.state.errors.intrusted_in}</div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-5">Skills/Roles I hire for *</label>
                                    <TagsInput className="inputtypeTagsKey" value={this.state.instrustedIn} onChange={this.teghandleChange} />
                                </div>
                            </div>
                           
                            <div className="col-md-12">
                                {/* <div className="login_footer form-group d-flex justify-content-between">
                                    <label className="cb-container">
                                        <input   onChange={this.handleChange}  name="accept_agreeiment"  type="checkbox" />
                                        <span className="text-small">By clicking, you agree with <NavLink  to="/privacy-policy"  >privacy policy</NavLink> and terms of use</span>
                                        <span className="checkmark"></span>
                                    </label>
                                    <a className="text-muted" href="page-contact.html">Lean more</a>
                                </div> */}
                                <div className="login_footer form-group d-flex justify-content-between">
                                    <label className="cb-container1"> 
                                        <Form.Check
                                            required
                                            className={'accept_agreeiment'}
                                            label={<><span className="text-small">By clicking, you agree with 
                                            <NavLink className='text-blue-hyperlink' target="_blank"  to="/privacy-policy"  > privacy policy</NavLink> and 
                                            <NavLink className='text-blue-hyperlink' target="_blank"  to="/terms-of-service"  > terms of use </NavLink></span>
                                            </>}
                                            feedback="You must agree before submitting."
                                            feedbackType="invalid"
                                            checked={((this.state.fields.accept_agreeiment == 'accepted' || this.state.fields.accept_agreeiment == 'on') ? true : false) } onChange={this.handleChange}  name="accept_agreeiment"
                                        />
                                        {/* <input required={true}   type="checkbox" /> */}
                                        <div className="errorMsg">{this.state.errors.accept_agreeiment}</div>
                                    </label>
                                    <NavLink className='text-blue-hyperlink' target="_blank" to="/aboutus"  >Lean more</NavLink> 
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-10">
                                <input value={this.state.fields.username} onChange={this.handleChange}  name="username" type="hidden"  />
                                <input value={this.state.fields.facebook_url} onChange={this.handleChange}  name="facebook_url"  type="hidden"  />
                                <input value={this.state.fields.twitter_url} onChange={this.handleChange}   name="twitter_url"   type="hidden"  />
                                <input value={this.state.fields.google_plus_url} onChange={this.handleChange}   name="google_plus_url"   type="hidden" />
                                <input value={this.state.fields.linkedin_url} onChange={this.handleChange}  name="linkedin_url"  type="hidden" />

                                <button  className="btn btn-brand-1 hover-up w-100" type="submit" name="login" disabled={this.state.loading}>
                                    {this.state.submitbtntext}
                                </button>
                                </div>
                            </div>
                            
                        </div>
                </Form>   
            </>
        );
    }
}
export default JobseekerSignup;