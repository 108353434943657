import React  , {useEffect,useState} from 'react';

import { BrowserRouter as Router, Routes, Route ,  Link  , Navigate , useNavigate }  from 'react-router-dom';

import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Col, Row, Form } from "react-bootstrap";
import axios from "axios";

const ContactUs = () => {

  const [footerAddress, setFooterAddress] = useState([]);
  const [baseUrl, setBaseurl] = useState('');
  const [isApiLoad, setIsApiLoad] = useState(0);

  const [contactNumber, setContactNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const { value } = e.target;
    // Replace all non-numeric characters in the input
    const formattedNumber = value.replace(/\D/g, "");
    setContactNumber(formattedNumber);
  };



  useEffect(() => {
      (async () => {
          try {
              const getdata = await axios.get("https://us.jobchatbox.com/api/cms/contact_and_address");
              setFooterAddress(getdata.data.data.cms_option);
              setBaseurl(getdata.data.data.base_url);
              setIsApiLoad(1);

          } catch (error) { console.log(error); }
      })();
  }, []);


  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
    useEffect(() => {  window.scrollTo(0, 0) }, [])
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;



      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {

          const validNumberRegex = /^[0-9]{10}$/; // Matches 10 digits
          const isValidNumber = validNumberRegex.test(contactNumber);
            setIsValid(isValidNumber);
            if (isValidNumber) {
                // Handle the valid contact number submission here
                console.log("Valid contact number:", contactNumber);
                const formData = new FormData();
                formData.append('name', event.target.name.value);
                formData.append('company', event.target.company.value);
                formData.append('email_id', event.target.email.value);
                formData.append('phone', event.target.phone.value);
                formData.append('message', event.target.message.value);
                formData.append('privacy_policy', event.target.privacy_policy.value);
                try {
                let res = await fetch("https://us.jobchatbox.com/api/cms/contact_form_action", {
                method: "POST",
                body: formData ,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ; 
                setTimeout(function(){
                navigate("/thank-you");
                },2000)
                // <Navigate to="/thank-you"/>
                } else {
                notify('error','Some error occured.');
                }
                } catch (err) {
                console.log(err);
                }
            } else {
              // Handle the invalid contact number submission here
              console.log("Invalid contact number:", contactNumber);
            }
        
      }
      setValidated(true);  
    }

    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    return (
        <>
         <ToastContainer />
      <PageTopHeaderSec pageTitle='Contact Us' PageLink='contactus'  />
      <section className="section-box mt-80">
        <div className="container">
          <div className="box-info-contact">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 mb-30"><img src="front/assets/imgs/newlogo.png" alt="jcbapp" /></div>
                
                {
                  footerAddress.length ? 
                  <>
                    {
                      footerAddress.map((footItem,indexKey) => (
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-30"><h5 className="mb-20">{ indexKey == 0 ? 'INDIA' : 'USA'}</h5>
                            <div className="font-sm color-text-paragraph">
                            <ul className="menu-footer fcontactmenu">
                                <li>
                                    <span className="contactsicon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                    <span>{footItem.address}</span>
                                </li>
                                <li>
                                    <span className="contactsicon"><i className="fa fa-volume-control-phone" aria-hidden="true"></i></span>
                                    <a href={"email:"+footItem.contact} >{footItem.contact}</a>
                                </li>
                                <li>
                                    <span className="contactsicon"><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                                    <a href={"email:"+footItem.email} >{footItem.email}</a>
                                </li>
                            </ul>
                          </div>
                        </div>
                      ))
                    }
                  </>
                  :''
                }
                
                
                {/* <div className="col-lg-4 col-md-4 col-sm-12 mb-30"><h5 className="mb-20">USA</h5>
                    <div className="font-sm color-text-paragraph">
                    <ul className="menu-footer fcontactmenu">
                      <li><span className="contactsicon"><i className="fa fa-map-marker" aria-hidden="true"></i></span><span>Career Hamster Ltd,Reg No: 11283931,16 Medway Road,Crayford, artford, DA1 4PN, US</span></li>
                      <li><span className="contactsicon"><i className="fa fa-volume-control-phone" aria-hidden="true"></i></span><a href="tel:+1 2034554929">+1 2034554929</a></li>
                      <li><span className="contactsicon"><i className="fa fa-envelope-o" aria-hidden="true"></i></span><a href="mailto:info@careerhamster.com">info@careerhamster.com</a></li>
                    </ul>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>
      
      <section className="section-box mt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-40"><span className="font-md color-brand-2 mt-20 d-inline-block">Contact us</span>
              <h2 className="mt-5 mb-10">Get in touch</h2>
              
              <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="contact-form1" className="login-register text-start" autoComplete="off">
                <div className="row wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <Form.Control
                          required
                          type="text"
                          name="name"
                          placeholder="Enter your name*"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <Form.Control
                          required = {false}
                          type="text"
                          name="company"
                          placeholder="Comapy (optioanl)"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <Form.Control
                          required
                          type="email"
                          name="email"
                          placeholder="Enter your email*"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <Form.Control
                          required
                          type="text"
                          name="phone"
                          placeholder="Phone number*"
                          maxLength={10}
                          value={contactNumber}
                          onChange={handleContactNumberChange}
                          isInvalid={!isValid}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                          as="textarea" 
                          rows={6}
                          name="message"
                          className="font-sm color-text-paragraph-2"
                          placeholder="Tell us about yourself"
                      />
                    </Form.Group>
                    <button className="submit btn btn-send-message" type="submit">Send message</button>
                    <label className="ml-20">
                      <input className="float-start mr-5 mt-6" name='privacy_policy' type="checkbox" /> By clicking contact us button, you agree our terms and policy.
                    </label>
                  </div>
                </div>
                </Form>
              <p className="form-messege"></p>
            </div>
            <div className="col-lg-4 text-center d-none d-lg-block"><img src="front/assets/imgs/img11.png" alt="jcbapp" /></div>
          </div>
        </div>
      </section>
      <SubscribeModule />
        </>
    );
  };
export default ContactUs;