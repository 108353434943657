import React,{useState,useEffect} from "react";
import axios from "axios";

const WhyChooseUs = () => {

   const [allPostList, setAllPostList] = useState([]);
    const [baseUrl, setBaseurl] = useState('');
    useEffect(() => {
        (async () => {
            try {
                const getdata = await axios.get("https://us.jobchatbox.com/api/cms/why_choose_us");
                setAllPostList(getdata.data.data.blog_data);
                setBaseurl(getdata.data.data.base_url);
            } catch (error) { console.log(error); }
        })();
    }, []);

    return(
        <section className="section-box overflow-visible pt-100 pb-100 graybg whychoose">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
                <img src="front/assets/imgs/whyusimg.png" />            
            </div>
            <div className="col-md-8">
                <h6 className="headtop clr-primary mb-10">WHY CHOOSE US?</h6>
                <h2 className="section-title mb-20">Jobchatbox stands out as unique from other job portals due to its commitment to helping job seekers find career success. </h2>
                <div className="row">
                  {
                     allPostList.length > 0 ?
                     <>
                     {
                        allPostList.map((itmeData,index) => (
                           <>
                              {
                              <>
                              <div className="col-md-6 col-12 mt-20">
                                 <div className="whychoosbox">
                                       <div className="d-flex">
                                          <span className="whyicon">
                                             <img src={baseUrl+'/'+itmeData.blog_image_url} />
                                          </span>
                                          <div className="whyright">
                                                <h5 className="mb-10">{itmeData.blog_name}</h5>
                                                <p className="mb-20" dangerouslySetInnerHTML={{__html: itmeData.blog_shortdescription}} ></p>
                                          </div>
                                       </div>
                                 </div> 
                              </div>
                              </>
                              }
                           </>
                        ))
                     }
                     </>
                     :''
                  }
                </div>

            </div>
          </div>
          
        </div>
      </section>
        );
}

export default WhyChooseUs;