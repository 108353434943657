import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';

const Resume_folder = () => {

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [resumeFolders, setResumeFolders] = useState([]);
   const [folderName, setFolderName] = useState([]);
   const [validated, setValidated] = useState(false);
   
   
   useEffect(() => {
      (async () => {
          try {
            const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/resume_folders/"+storedNames.login_token);
               if(responceData.data.status == 1){
                  setResumeFolders(responceData.data.data.folders);
               }
            } catch (error) {
               console.log(error);
            }
      })();
   }, []);

   const folderValueChange  =  (e) => {
      setFolderName(e.target.value)
   }

   const reload = () => {
      window.location.reload(false);
    }
   
   const addFolderDetails  = async (event) => {
      event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
           event.stopPropagation();
        } else {
           const formData = new FormData();
           formData.append('logintoken', storedNames.login_token );
           formData.append('folder_name', event.target.folder_name.value );
           try {
              let res = await fetch("https://us.jobchatbox.com/api/user/api/save_folder_name", {
                 method: "POST",
                 body: formData ,
              });
              let resJson = await res.json();
              if (res.status === 200) {
                 if(resJson.status  == 1){
                  toast.success(resJson.message);
                     setFolderName('')
                     setTimeout(function() {  reload();},1000)
                 } else {
                  toast.warning(resJson.message);
                 }
              } 
           } catch (err) {
              console.log(err);
           }
         }
         setValidated(true);
   }

   const deleteFolder = (id,resumeList) => {
      if(resumeList > 0){   
         alert('You can not remove this folder because folder having '+resumeList+' ');
      } else {
         if (window.confirm('Do you want to remove folder?') == true) {
            (async () => {
               try {
                  const responceData = await axios.get("https://us.jobchatbox.com/api/user/api/delete_resume_folder/"+storedNames.login_token+'/'+id);
                  if(responceData.data.status == 1){
                     toast.success(responceData.data.message);
                     setResumeFolders(responceData.data.data.folders);
                  } else {
                     toast.warning(responceData.data.message);
                  }
               } catch (error) {
               console.log(error);
               }
            })();
         }
      }
   }

    return (
      <>
      <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                <div className="section-box jobpost-sec">
                    <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>Resumes</h5><a href="#" data-bs-toggle="modal" data-bs-target="#createfolder" className="btn btn-default btn-small ml-auto">+ Create Folder </a>
                        </div>
                        <div className="row">
                           {
                              resumeFolders.length  ? 
                              <>
                                 {
                                    resumeFolders.map((item,index) => (
                                       <>
                                       <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                                          <div className="card-grid-1 py-4 px-2" >
                                          <div className="image-box mb-5">
                                             <Link to={`/resume-folder-list?itemid=${item.id}`}>
                                             <img src="front/assets/imgs/folder.png" alt="jcbapp" />
                                             </Link>

                                          </div>
                                          <h6><Link to={`/resume-folder-list?itemid=${item.id}`} className='text-capitalize'>{item.folder_name} (<span toltip="Total resume in folder">{item.total_resume_list}</span>)</Link></h6>
                                             <Link onClick={ () => deleteFolder(item.id,item.total_resume_list)} className="btn btn-default btn-small mt-10" href="#">
                                                <i className="fa fa-trash-o" aria-hidden="true"></i> Delete
                                             </Link>
                                          </div>
                                       </div>
                                       </>
                                    ))
                                 }
                              </>
                              : <><h3>Data Not Found</h3></>
                           }
                              
                        </div>
                    </div>
                </div>
                </div>


            </div>
          </div>
        </div>
        <div className="modal fade" id="createfolder" tabindex="-1" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content apply-job-form">
               <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
               <div className="modal-body pl-30 pr-30 pt-50">
                  <div className="text-center">
                     <p className="font-sm text-brand-2">Create Folder </p>
                     <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with the new folder</h3>
                  </div>
                  

                  <Form noValidate validated={validated}   onSubmit={addFolderDetails} className="login-register addnoteform text-start mt-20 pb-30" name="addEductinInProfileForm" >
                     <div className="form-group">
                        <label className="form-label">Folder Name</label>
                        <Form.Control
                              value={folderName}
                              required
                              type="text"
                              name="folder_name"
                              placeholder="Folder Name"
                              onChange={folderValueChange}
                        />
                     </div>
                     
                     <div className="form-group">
                        <button  className="btn btn-default hover-up w-100" type="submit" name="login">Create older</button>
                     </div>
                     <div className="text-muted text-center">Do you need support? <Link to="/contactus" target="_blank">Contact Us</Link></div>
                  </Form>

               </div>
            </div>
         </div>
      </div>
      </>
    );
  };
export default Resume_folder;