import React ,{useState , useEffect} from "react";
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import SubscribeModule from './SubscribeModule';
import axios from "axios";
const JobSeekerDetails = () => {

    const [jobseekerdata, setJobSeekerData] = useState("");      
    const [jobseekerAllDetail, setJobseekerAllDetail] = useState([]);
    const [jobseekerLoadStatus, setJobseekerLoadStatus] = useState(0);
 
 
    const get_userdata = async url => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                 setJobSeekerData(json.data.results);
                    console.log(json.data)
                } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    };
 
    
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const candidate = queryParameters.get("candidate");
        get_userdata("https://us.jobchatbox.com/api/userdetails/"+candidate)
        window.scrollTo(0, 0);
        (async () => {
            try {
              const responceData = await axios.get("https://us.jobchatbox.com/api/home/api/jobseeker_details/"+candidate);
                 if(responceData.data.status == 1){
                    setJobseekerAllDetail(responceData.data.data);
                    setJobseekerLoadStatus(1)
                 }
              } catch (error) {
                 console.log(error);
              }
        })();
    }, [])
   
    let key_skills  = [];
    if(jobseekerLoadStatus  > 0 ){
        key_skills = jobseekerAllDetail.userdata.key_skills;
        key_skills = JSON.parse(key_skills);
    } 
    return(
        <>
            {/* {jobseekerAllDetail.userdata.profile_bg_img_url} */}

            {
                jobseekerLoadStatus > 0 ?
                <>
                    <section className="section-box-2">
                        <div className="container">
                        <div className="banner-hero banner-image-single smallbanner">
                            <img src={jobseekerAllDetail.userdata.profile_bg_img_url} alt="jcbapp" /></div>
                        <div className="box-company-profile">
                            <div className="image-compay"><img className="jobseeker_img" src={jobseekerAllDetail.userdata.profile_pic_url} alt="jcbapp" /></div>
                            <div className="row mt-10">
                            <div className="col-lg-8 col-md-12">
                                <h5 className="f-18 text-capitalize">{ jobseekerAllDetail.userdata.firstname } 
                                <span className="card-location font-regular ml-20">{ jobseekerAllDetail.userdata.user_state+' '+jobseekerAllDetail.userdata.user_city }</span>
                                </h5>
                                <p className="mt-0 font-md color-text-paragraph-2 mb-15">{jobseekerAllDetail.userdata.job_title }</p>
                            
                            </div>
                                <div className="col-lg-4 col-md-12 text-lg-end">
                                    {
                                        jobseekerAllDetail.userdata.user_resume_url ? 
                                        <><a className="btn btn-download-icon btn-apply btn-apply-big"  target="_blank" href={jobseekerAllDetail.userdata.user_resume_url}>Download CV</a></>
                                        : ''
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="box-nav-tabs mt-40 mb-5">
                          
                            <ul className="nav" role="tablist">
                            <li><a className="btn btn-border aboutus-icon mr-15 mb-5 active" href="#tab-short-bio" data-bs-toggle="tab" role="tab" aria-controls="tab-short-bio" aria-selected="true">Short Bio</a></li>
                            <li><a className="btn btn-border recruitment-icon mr-15 mb-5" href="#tab-skills" data-bs-toggle="tab" role="tab" aria-controls="tab-skills" aria-selected="false">Skills</a></li>
                            <li><a className="btn btn-border people-icon mb-5  mr-15" href="#tab-work-experience" data-bs-toggle="tab" role="tab" aria-controls="tab-work-experience" aria-selected="false">Working Experience</a></li>
                            <li><NavLink to={`/mytimeline?candidate_id=${jobseekerdata.unique_id}`} className="btn btn-border people-icon mb-5" >My Timeline</NavLink></li>
                            </ul>
                        </div>
                        <div className="border-bottom pt-10 pb-10"></div>
                        </div>
                    </section>
                    <section className="section-box mt-30">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div className="content-single">
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="tab-short-bio" role="tabpanel" aria-labelledby="tab-short-bio">
                                            <h4>About Me</h4> 
                                            <p>{jobseekerAllDetail.userdata.short_desc}</p>
                                            <h4>Education</h4>
                                            <div className="box-timeline">
                                                
                                                {
                                                    jobseekerAllDetail.edu_data.map((item,index) => (
                                                        <>
                                                        <div className="item-timeline">
                                                            <div className="timeline-year"> <span>{item.from_year} - {item.to_year}</span></div>
                                                            <div className="timeline-info">
                                                                <h6 className="color-brand-1 mb-5">{item.school_or_collage_name}</h6>
                                                                <p className="color-text-paragraph-2 mb-15">{item.description}</p>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))
                                                }
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tab-skills" role="tabpanel" aria-labelledby="tab-skills">
                                            {/* 
                                            <ul>
                                                <li>Necessitatibus quibusdam facilis</li>
                                                <li>Velit unde aliquam et voluptas reiciendis non sapiente labore</li>
                                                <li>Commodi quae ipsum quas est itaque</li>
                                                <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit</li>
                                                <li>Deleniti asperiores blanditiis nihil quia officiis dolor</li>
                                            </ul> */}
                                            <h4>Job Objective</h4>
                                            <div dangerouslySetInnerHTML={{__html: jobseekerAllDetail.userdata.job_objective}} ></div>
                                         
                                            <h4>Personal Skills</h4>
                                            <div className="box-timeline mt-0">
                                                <div className="item-timeline">
                                                <div className="timeline-info">
                                                    <div className="d-flex">
                                                    { 
                                                        key_skills.map((key,index) => (
                                                            <h6 className="roundtab mr-5">{key}</h6>
                                                        ))
                                                    }
                                                        
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tab-work-experience" role="tabpanel" aria-labelledby="tab-work-experience">
                                            <h4>Work Experiences</h4>
                                            <div className="box-timeline mt-50">
                                                
                                                {
                                                    jobseekerAllDetail.expe_data.map((item,index) => (
                                                        <>
                                                        <div className="item-timeline">
                                                            <div className="timeline-year"> <span>{item.start_form} - {item.start_to}</span></div>
                                                                <div className="timeline-info">
                                                                <h6 className="color-brand-1 mb-5">{item.organization_name}</h6>
                                                                <p className="color-text-paragraph-2 mb-15">{item.description}</p>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))
                                                }
                                               
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                                    <div className="sidebar-border">
                                    <h5 className="f-18">Overview</h5>
                                    <div className="sidebar-list-job">
                                        <ul>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-briefcase"></i></div>
                                                <div className="sidebar-text-info">
                                                    <span className="text-description">Experience</span>
                                                    <strong className="small-heading">{jobseekerAllDetail.userdata.total_experience_year_range}</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                                                <div className="sidebar-text-info">
                                                    <span className="text-description">Preferred Locations</span>
                                                    <strong className="small-heading">{jobseekerAllDetail.userdata.user_state+' '+jobseekerAllDetail.userdata.user_city}</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-dollar"></i></div>
                                                <div className="sidebar-text-info">
                                                    <span className="text-description">Expected Salary</span>
                                                    <strong className="small-heading">${jobseekerAllDetail.userdata.total_salary}</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                                                <div className="sidebar-text-info"><span className="text-description">Language</span><strong className="small-heading">English, German</strong></div>
                                            </li>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-time-fast"></i></div>
                                                <div className="sidebar-text-info"><span className="text-description">Education Level</span><strong className="small-heading">Master Degree</strong></div>
                                            </li>
                                            <li>
                                                <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div> 
                                                <div className="sidebar-text-info">
                                                    <span className="text-description">Joining Status</span>
                                                    <strong className="small-heading">{jobseekerAllDetail.userdata.join_status}</strong>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                
                                    </div>
                                    <div class="contactbox">
                        
                        
                        
                        <div class="">
                        <ul class="">
                            
                            <li><i class="fa fa-envelope-o mr-10" aria-hidden="true"></i> Email: {jobseekerAllDetail.userdata.email}</li>
                            <li><i class="fa fa-phone mr-10" aria-hidden="true"></i> Phone: {jobseekerAllDetail.userdata.mobile}</li>
                            <li><i class="fa fa-map-marker mr-10" aria-hidden="true"></i>Location : {jobseekerAllDetail.userdata.user_city+' '+jobseekerAllDetail.userdata.user_state}</li>
                            
                        </ul>
                        {/* <div class="mt-20"><a class="btn btn-default mr-10 btn-small" href="#">View</a>
        <a class="btn btn-default btn-small" href="#">Connect</a></div> */}
                        </div>
                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                : ''
            }
            
            <SubscribeModule />
        </>
    );

} 
export default JobSeekerDetails;