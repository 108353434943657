import React,{useState,useEffect} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Nav, NavLink, NavMenu } from "../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import JobseekerSignup from './forms_temp/jobseeker_signup';
import RecruiterSignup from './forms_temp/recruiter_signup';
import SubscribeModule from './template_parts/SubscribeModule';
import { Link } from "react-router-dom";


//console.log(API_ENDPOINT);
//class JobSeekerSearchPage extends React.Component {
const JobSeekerSearchPage = (props) => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [allPostList, setAllPostList] = useState([]);
  const [allRecruiterList, setAllRecruiterList] = useState([]);
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [totalRecruiterCount, setTotalRecruiterCount] = useState(0);
  const [responce, setResponce] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [favoriteJobs, setFavoriteJobs] = useState([]);
  const auth = localStorage.getItem('userlogin');
  
  const search_and_ajax_results  = async (skills,state_id,skills_tag=null) => {
      const formData = new FormData();
      formData.append('skills', skills);
      formData.append('skills_tag', skills_tag);
      formData.append('state_id', state_id);
      try {
          let res = await fetch("https://us.jobchatbox.com/api/home/api/jobs_search", {
          method: "POST",
          body: formData ,
          });
          let resJson = await res.json();
          if (res.status === 200) {
              if(resJson.status == 1){ 
                  setAllPostList(resJson.data.jobs) ;
                  setAllRecruiterList(resJson.data.recruiters) ;
                  setTotalResultCount(resJson.data.total_result) ;
                  setTotalRecruiterCount(resJson.data.total_recruiters) ;
              }
              else { setAllPostList([]) }
          } 
      } catch (err) {
          console.log(err);
      }
      
  }


  const applied_jobs = (e) => {
      let userdata  = JSON.parse(localStorage.getItem("userloginSession"));

      if(userdata.usertype == 3){

          if(userdata.user_resume){
              const  API =  'https://us.jobchatbox.com/api/home/api/jobs_apply';
              const formData = new FormData();
              formData.append('job_id', e.currentTarget.id);
              formData.append('user_id', userdata.id );
              (async () => {
                  try {
                      let res = await fetch(API , {
                          method: "POST",
                          body: formData ,
                      });
                      let resJson = await res.json();
                      (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                      if(resJson.status){
                          setAppliedJobs(resJson.data.applied_id)
                          setFavoriteJobs(resJson.data.favorite_id)
                      }
                  } catch (err) {
                      console.log(err);
                  }
              })();
          } else {
              notify('warning','Please Add resume in your profile.')
          }
      } else {
          notify('warning','You have no access for apply this job.')
      }
  }

  const favorite_jobs = (e) => {
      let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
      if(userdata.usertype == 3){
          const  API =  'https://us.jobchatbox.com/api/home/api/jobs_apply';
          const formData = new FormData();
          formData.append('job_id', e.currentTarget.id);
          formData.append('user_id', userdata.id );
          formData.append('joblisting_type', 'favorite' );
          (async () => {
              try {
                  let res = await fetch(API , {
                      method: "POST",
                      body: formData ,
                  });
                  let resJson = await res.json();
                  (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                  if(resJson.status){
                      setAppliedJobs(resJson.data.applied_id)
                      setFavoriteJobs(resJson.data.favorite_id)
                  }
              } catch (err) {
                  console.log(err);
              }
          })();
      } else {
          notify('warning','You have no access for add job in favorite list.')
      }
      
  }

  const notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;

          case 'success':
              toast.success(message)  
          break;

          case 'info':
              toast.info(message)  
          break;

          case 'error':
              toast.error(message)  
          break;
      }
  };

  const checkJobApplied = (val)  => {
      return appliedJobs.some(item => val === item);
  }

  const checkJobAddedinFavorite = (val)  => {
      return favoriteJobs.some(item => val === item);
  }

  useEffect(() => {
    (async () => {
      const queryParameters = new URLSearchParams(window.location.search)
            const skills = queryParameters.get("skills");
            const location = queryParameters.get("location");
            const skills_tag = queryParameters.get("skills_tag");
            //if(skills){
            search_and_ajax_results(skills ,  location ,skills_tag)
            //}
    })();
  }, []);


        const  JobsSearchHtml  = (attrData) => {

            let coltype  =  attrData.coltype == 6 ? 'col-xl-12 col-md-12 col-12' : 'col-xl-12 col-md-12 col-12';
            let jobItem  =  attrData.responcedata ;
            return  (
              <>
              <div key={jobItem.id} className={coltype}>
                  <div className="card-grid-2 hover-up"><span className="flash"></span>
                  <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card-grid-2-image-left">
                            <div className="image-box"> 
                                <img className="profile_pic" src={`https://us.jobchatbox.com/api/uploads/users/${jobItem.companydata.profile_pic}`} alt="jcbapp"  />
                            </div>
                            <div className="right-info"> 
                                <Link className="name-job text-capitalize" to={`/recruiter-details?candidate=${jobItem.companydata.unique_id}&jobid=${jobItem.id}`} >{jobItem.companydata.company_name}</Link>
                                <span className="location-small">{jobItem.prefered_state_location}</span> 
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-12 text-start text-md-end col-md-7 col-sm-12">
                      <div className="pl-15 mb-1 mt-1 float_left jobstaggs text-left">
                              {
                                  jobItem.job_skills.map((skill_item,index2) => (
                                      (index2 < 4)  ? <><Link key={index2+1} className="btn btn-grey-small mr-5 text-capitalize mb-1" to={`?skills=${skill_item}`} >{skill_item}</Link></> : ''
                                  ))
                              }
                      </div>
                      </div>
                  </div>
                  <div className="card-block-info">
                      <h5><Link to={`/job-details?jobid=${jobItem.id}`} className='text-capitalize'>{jobItem.job_title}</Link></h5>
                      <div className="mt-5 mb-10">
                          <span className="card-briefcase">{jobItem.employment_type}</span>
                          <span className="card-time"><span>{jobItem.create_date}  ago</span></span>
                      </div>
                      <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Location :</strong>{jobItem.city_data+' ('+jobItem.state_data+')'}</p></div>
                          <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                          <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                          <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                      </div>
                      <div className="row">
                          <div className="col-lg-4 col-4"><span className="card-text-price">{jobItem.salary_range} </span></div>
                          <div className="col-lg-8 col-8 text-end">
                              {
                                  auth ? 
                                  <>
                                      {   checkJobApplied(jobItem.id) ? 
                                      
                                          <><button className="btn btn-applied-jobs" >Applied</button></> : 
                                          <>
                                          {
                                              checkJobAddedinFavorite(jobItem.id) ? 
                                              <><button className="btn btn-applied-jobs" >Added in Favorite</button></> : 
                                              <>
                                                  <button className="btn btn-apply-now" onClick={favorite_jobs} id={jobItem.id} >Favorite Job</button>
                                                  <button className="btn btn-apply-now ml-5" onClick={applied_jobs} id={jobItem.id} >Apply now</button>
                                              </>
                                          }
                                          </>
                                      }
                                  
                                  
                                  </> : 
                                  <><Link className="btn btn-apply-now" to={'/login?jobsid='+jobItem.id}  >Apply now</Link></>
                              }
                              
                          </div>
                      </div>
                  </div>
                  </div>
              </div>
              </>
              
            )
        }

        const  RecruiterSearchHtml  = (attrData) => {
            let recruiter =  attrData.responcedata ;
            //console.log(recruiter)
            //recruiter.profile_pic_url
            //recruiter.firstname recruiter.lastname
            //recruiter.user_state recruiter.user_city
            //recruiter.designation
            //recruiter.gender
            //recruiter.job_industry
            //recruiter.user_prefered_state
            //recruiter.user_prefered_location
            //recruiter.jobsprefered_state_location
            // profile_pic_url , firstname , lastname , user_state ,user_city ,designation , gender , job_industry , user_prefered_state
            // user_prefered_location ,  jobsprefered_state_location

            return (
              <>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="card-grid-2 hover-up menblock">
                      <div className="card-grid-2-image-left">
                        <div className="card-grid-2-image-rd online">
                            <Link className="sc-gswNZR" to={`/recruiter-details?candidate=${recruiter.unique_id}`}>
                              <figure><img alt="img-52" src={recruiter.profile_pic_url} /></figure>
                            </Link>
                        </div>
                        <div className="card-profile pt-10">
                            <Link className="sc-gswNZR" to={`/recruiter-details?candidate=${recruiter.unique_id}`}>
                              <h6 className="text-capitalize" >{((recruiter.firstname) ? recruiter.firstname : '')+' '+((recruiter.lastname) ? recruiter.lastname : '')}</h6>
                            </Link>
                            <span className="font-xs color-text-mutted">{recruiter.designation}</span>
                        </div>
                      </div>
                      <div className="card-block-info">
                        <div className="font-xs color-text-mutted mb-5"><i className="fa fa-envelope-o" aria-hidden="true"></i>{recruiter.email}</div>
                        <div className="d-flex">
                            <div className="font-xs color-text-mutted mb-5 mr-20"><i className="fa fa-user-o" aria-hidden="true"></i> {(recruiter.gender) ? recruiter.gender : 'Male'}</div>
                            <div className="font-xs color-text-mutted mb-5"><i className="fa fa-map-marker" aria-hidden="true"></i> {recruiter.user_city}</div>
                        </div>
                        <div className="font-xs color-text-mutted mb-5"><strong>Hiring for :</strong> {recruiter.job_industry}</div>
                        <div className="font-xs color-text-mutted"><strong>Preferred Locations :</strong> {recruiter.jobsprefered_state_location}</div>
                        <div className="employers-info align-items-center justify-content-center mt-15">
                            <div className="card-2-bottom card-2-bottom-candidate">
                              <div className="text-start">
                                <Link className="roundtab mr-5" to={`/jobs?candidate_id=${recruiter.unique_id}`}>{recruiter.total_jobs_count} jobs open</Link>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
            </div>
              </>
            )
        }

        return(
            <>
                <section className="section-box mt-0 job_recruter">
                  <div className="section-box wow animate__fadeIn" >
                      <div className="container">
                        <div className="text-start">
                            <div className="list-tabs list-tabs-2 mt-30">
                              <ul className="nav nav-tabs" role="tablist">
                                  <li><a className={ (props.search_type == 'jobseeker') ? 'active': '' }  id="nav-tabjobs" href="#tabjobs" data-bs-toggle="tab" role="tab" aria-controls="tabjobs" aria-selected="true">Search Job</a></li>
                                  <li><a className={ (props.search_type == 'recruiter') ? 'active': '' }  id="nav-tabjobrecruter" href="#tabjobrecruter" data-bs-toggle="tab" role="tab" aria-controls="tabjobrecruter" aria-selected="false" >Search (Job + Recruter)</a></li>
                              </ul>
                            </div>
                        </div>
                        <div className="mt-0">
                            <div className="tab-content" id="myTabContent-1">
                             
                              <div  className={ (props.search_type == 'jobseeker') ? 'tab-pane fade active show': 'tab-pane fade' }  id="tabjobs" role="tabpanel" aria-labelledby="tabjobs">
                                  <div className="content-page"> 
                                    <h4 className="">Jobs</h4>
                                    <div className="box-filters-job">
                                      <div className="row">
                                        <div className="col-xl-5 col-lg-5"><span className="text-small text-showing">Showing <strong>{totalResultCount}</strong> jobs</span></div>
                                        <div className="col-xl-7 col-lg-7 text-lg-end mt-sm-15">
                                          <div className="display-flex2">
                                            {/* <div className="box-border mr-10"><span className="text-sortby">Show:</span>
                                              <div className="dropdown dropdown-sort">
                                                <button className="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i className="fi-rr-angle-small-down"></i></button>
                                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                                  <li><a className="dropdown-item active" href="#">10</a></li>
                                                  <li><a className="dropdown-item" href="#">12</a></li>
                                                  <li><a className="dropdown-item" href="#">20</a></li>
                                                </ul>
                                              </div>
                                            </div> */}
                                            <div className="box-border"><span className="text-sortby">Sort by:</span>
                                              <div className="dropdown dropdown-sort">
                                                <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span><i className="fi-rr-angle-small-down"></i></button>
                                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                                  <li><a className="dropdown-item active" href="#">Newest Post</a></li>
                                                  <li><a className="dropdown-item" href="#">Oldest Post</a></li>
                                                  <li><a className="dropdown-item" href="#">Rating Post</a></li>
                                                </ul>
                                              </div>
                                            </div>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row display-list">
                                      
                                      {
                                        allPostList.length ? 
                                        <>
                                        {
                                            allPostList.map((jobItem,index) => (
                                              <JobsSearchHtml responcedata = {jobItem}  coltype="6" />
                                            ))
                                        }
                                        </> :
                                        ''
                                      }
                                        
                                    </div>
                                  </div>
                              </div>
                               
                              <div className={ (props.search_type == 'recruiter') ? 'tab-pane fade  active show': 'tab-pane fade' } id="tabjobrecruter" role="tabpanel" aria-labelledby="tabjobrecruter">
                                  <div className="row">
                                      <div className="col-md-6">
                                          <div className="content-page">
                                            <h4 className="">Jobs</h4> 
                                            <div className="box-filters-job">
                                              <div className="row">
                                                <div className="col-xl-5 col-lg-5"><span className="text-small text-showing">Showing <strong>{totalResultCount}</strong> jobs</span></div>
                                                <div className="col-xl-7 col-lg-7 text-lg-end mt-sm-15">
                                                  <div className="display-flex2">
                                                    {/* <div className="box-border mr-10"><span className="text-sortby">Show:</span>
                                                      <div className="dropdown dropdown-sort">
                                                        <button className="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i className="fi-rr-angle-small-down"></i></button>
                                                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                                          <li><a className="dropdown-item active" href="#">10</a></li>
                                                          <li><a className="dropdown-item" href="#">12</a></li>
                                                          <li><a className="dropdown-item" href="#">20</a></li>
                                                        </ul>
                                                      </div>
                                                    </div> */}
                                                    <div className="box-border"><span className="text-sortby">Sort by:</span>
                                                      <div className="dropdown dropdown-sort">
                                                        <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span><i className="fi-rr-angle-small-down"></i></button>
                                                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                                          <li><a className="dropdown-item active" href="#">Newest Post</a></li>
                                                          <li><a className="dropdown-item" href="#">Oldest Post</a></li>
                                                          <li><a className="dropdown-item" href="#">Rating Post</a></li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                    
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row display-list">
                                            
                                                {
                                                  allPostList.length ? 
                                                  <>
                                                  {
                                                      allPostList.map((jobItem,index) => (
                                                        <JobsSearchHtml responcedata = {jobItem} coltype="12" />
                                                      ))
                                                  }
                                                  </> :
                                                  ''
                                                }
                                            </div>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="content-page">
                                              <h4 className="">Recruiters</h4>
                                              <div className="box-filters-job">
                                                <div className="row">
                                                  <div className="col-xl-5 col-lg-5"><span className="text-small text-showing">Showing <strong>{totalRecruiterCount}</strong> jobs</span></div>
                                                  <div className="col-xl-7 col-lg-7 text-lg-end mt-sm-15">
                                                    <div className="display-flex2">
                                                      {/* <div className="box-border mr-10"><span className="text-sortby">Show:</span>
                                                        <div className="dropdown dropdown-sort">
                                                          <button className="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i className="fi-rr-angle-small-down"></i></button>
                                                          <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                                            <li><a className="dropdown-item active" href="#">10</a></li>
                                                            <li><a className="dropdown-item" href="#">12</a></li>
                                                            <li><a className="dropdown-item" href="#">20</a></li>
                                                          </ul>
                                                        </div>
                                                      </div> */}
                                                      <div className="box-border"><span className="text-sortby">Sort by:</span>
                                                        <div className="dropdown dropdown-sort">
                                                          <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span><i className="fi-rr-angle-small-down"></i></button>
                                                          <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                                            <li><a className="dropdown-item active" href="#">Newest Post</a></li>
                                                            <li><a className="dropdown-item" href="#">Oldest Post</a></li>
                                                            <li><a className="dropdown-item" href="#">Rating Post</a></li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                  
                                                  {
                                                      allRecruiterList.length ? 
                                                      <>
                                                      {
                                                          allRecruiterList.map((recruiterItem,index) => (
                                                            <RecruiterSearchHtml responcedata = {recruiterItem}  />
                                                          ))
                                                      }
                                                      </> :
                                                      ''
                                                    }
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                            </div>
                        </div>
                      </div>
                  </div>
                </section>
                <SubscribeModule />
            </>
        );
}

export default JobSeekerSearchPage;