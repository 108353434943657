import React,{useState,useEffect} from "react";
import { Link , Navigate , useNavigate} from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { Col, Row, Form } from "react-bootstrap";
import axios from "axios";

const RightSidebar = () => {

    const [isResumeModalShow , isSetResumeModalShow] = useState(false);
    const [selectedPlanID , setSelectedPlanID] = useState(0);

    const [smallAddList , setSmallAddList] = useState([]);
    const [smallAddList2 , setSmallAddList2] = useState([]);
    const [bigAddList , setBigAddList] = useState([]);
    const [rootPath , setRootPath] = useState('');

    const addvertismentCall = () => {
        (async () => {
            try {
                const addlist = await axios.get("https://us.jobchatbox.com/api/random_add");
                if(addlist.data.status == 1){
                    setRootPath(addlist.data.data.root_path);
                    setSmallAddList(addlist.data.data.small_banner);
                    setSmallAddList2(addlist.data.data.small_banner2);
                    setBigAddList(addlist.data.data.large_banner);
                }
            } catch (error) { console.log(error); }
        })();
    }
    useEffect(() => {
        addvertismentCall();
        const interval = setInterval(() => {
            addvertismentCall()
          }, 6000);
          return () => clearInterval(interval);
     }, []);


    /* Resume upload by modal*/
    const sharePostResume = (id)   => {
        isSetResumeModalShow(true);
        setSelectedPlanID(id)
    }

    const  closeResumeModal = () => {
        isSetResumeModalShow(false)
    };
    /* Resume upload by modal*/
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        } else {
        const formData = new FormData();
        //name contact_number  company_name email service_type service_need group_desc
        formData.append('name', event.target.name.value);
        formData.append('email_id', event.target.email.value);
        formData.append('phone', event.target.contact_no.value);
        formData.append('company', event.target.comapany_weblink.value);
        formData.append('address', event.target.address.value);

        formData.append('no_of_days_for_add', event.target.no_of_days_for_add.value);
        formData.append('companylogo', event.target.logo.files[0]);

        formData.append('message', event.target.description.value);
        formData.append('contact_type_action', 'addvertisement_lead');
        try {
            let res = await fetch("https://us.jobchatbox.com/api/cms/contact_form_action", {
            method: "POST",
            body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
            //(resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ; 
                setTimeout(function(){
                    navigate("/thank-you");
                },1000)
            // <Navigate to="/thank-you"/>
            } else {
            // notify('error','Some error occured.');
            }
        } catch (err) {
            console.log(err);
        }
        }
        setValidated(true);  
    }


    return(
        <>
        <div className=" dashboardright">
            <div className="aidimgwrap">
               
                {
                    1 ? 
                    <>
                     {
                        smallAddList.map((item,index) => (
                            <>
                                <a href="#" className="aidimgblock">
                                    <img src={rootPath+'/'+item.banner_image} />
                                    <h5><Link  onClick={(e) => sharePostResume(item.id)} >Click here for advertisement.</Link></h5>
                                </a>
                            </>
                        ))
                     }
                    </>
                    : <></>
                }

                {
                    1 ? 
                    <>
                     {
                        bigAddList.map((item,index) => (
                            <>
                                <a href="#" className="aidimgblock fulladd">
                                    <img src={rootPath+'/'+item.banner_image} />
                                    <h5><Link  onClick={(e) => sharePostResume(item.id)} >Click here for advertisement.</Link></h5>
                                </a>
                            </>
                        ))
                     }
                    </>
                    : <></>
                }

                {
                    1 ? 
                    <>
                     {
                        smallAddList2.map((item,index) => (
                            <>
                                <a href="#" className="aidimgblock">
                                    <img className="smallimage_add smallimage_add2" src={rootPath+'/'+item.banner_image} />
                                    <h5><Link  onClick={(e) => sharePostResume(item.id)} >Click here for advertisement.</Link></h5>
                                </a>
                            </>
                        ))
                     }
                    </>
                    : <></>
                }
                
               
            </div>
        </div>

        <Modal show={isResumeModalShow} onHide={closeResumeModal}>
            {/* <Modal.Body> */}
                <>
                <div className="modal-content apply-job-form">
                    <button className="btn-close" type="button" onClick={closeResumeModal} ></button>
                    <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="text-center">
                            <p className="font-sm text-brand-2">Advertisement </p>
                            <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your advertisement now.</h3>
                            <p className="font-sm text-muted mb-30">Please fill in below information to add an dvertisement. </p>
                        </div>
                        <Form  className="row" noValidate validated={validated} onSubmit={handleSubmit}   autocomplete="off" >
                            <div className="form-group col-md-6">
                                <label className="form-label">Name *</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label">Logo</label>
                                <Form.Control
                                    type="file"
                                    name="logo"
                                    placeholder="Please add logo"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label">Email *</label>
                                <Form.Control
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Enter your name"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label">Phone Number *</label>
                                <Form.Control
                                    required
                                    type="tel"
                                    name="contact_no"
                                    placeholder="Enter your contact no."
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label className="form-label">Address *</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="address"
                                    placeholder="Enter your address"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label">Company Weblink / Url</label>
                                <Form.Control
                                    type="text"
                                    name="comapany_weblink"
                                    placeholder="Enter your Company Weblink"
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label className="form-label">No. of days to display advertisement</label>
                                <select className="form-control"  name="no_of_days_for_add">
                                    <option>10 Days</option>
                                    <option>20 days</option>
                                    <option>30 days</option>
                                    <option>2 Months</option>
                                    <option>3 Months</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Description</label>
                                <textarea rows="3" name="description" id="group_action_form" className="form-control"></textarea>
                            </div>

                            <div className="form-group"><button className="btn btn-default hover-up w-100" type="submit" name="submit">Submit</button></div>
                            <div className="text-muted text-center">Do you need support?
                            <Link to="/contactus">Contact Us</Link></div>
                        </Form>
                    </div>
                </div>
                </>
            {/* </Modal.Body> */}
        </Modal>
        </>
    )
}
export default RightSidebar;
// class RightSidebar1 extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             fields: {} ,
//             errors : {}
//         };
//         this.handleChange = this.handleChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }

//     handleChange(event) {
//         let fields = this.state.fields;
//         fields[event.target.name] = event.target.value;
//         this.setState({ fields });
//     }

//     handleSubmit = async (event) => {
//         event.preventDefault();
//         console.log(this.state.fields);

//     }
    
//     render() {
//     return(
//         <>
//         <div className=" dashboardright">
//             <div className="aidimgwrap">
//                 <a href="#" className="aidimgblock">
//                     <img src="front/assets/imgs/add1.jpg" />
//                     <h5><a data-bs-toggle="modal" data-bs-target="#Modaladvertisement" href="#">Click here for advertisement.</a></h5>
//                 </a>
//                 <a href="#" className="aidimgblock">
//                     <img src="front/assets/imgs/add2.jpg" />
//                     <h5><a data-bs-toggle="modal" data-bs-target="#Modaladvertisement" href="#">Click here for advertisement.</a></h5>
//                 </a>
//                 <a href="#" className="aidimgblock">
//                     <img src="front/assets/imgs/add3.jpg" />
//                     <h5><a data-bs-toggle="modal" data-bs-target="#Modaladvertisement" href="#">Click here for advertisement.</a></h5>
//                 </a>
//                 <a href="#" className="aidimgblock">
//                     <img src="front/assets/imgs/add4.jpg" />
//                     <h5><a data-bs-toggle="modal" data-bs-target="#Modaladvertisement" href="#">Click here for advertisement.</a></h5>
//                 </a>
//                 <a href="#" className="aidimgblock fulladd">
//                     <img src="front/assets/imgs/add2.jpg" />
//                     <h5><a data-bs-toggle="modal" data-bs-target="#Modaladvertisement" href="#">Click here for advertisement.</a></h5>
//                 </a>
//             </div>
//         </div>

//         <div className="modal fade" id="Modaladvertisement" tabIndex="-1" aria-hidden="true">
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content apply-job-form">
//                     <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
//                     <div className="modal-body pl-30 pr-30 pt-50">
//                         <div className="text-center">
//                             <p className="font-sm text-brand-2">Advertisement </p>
//                             <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your advertisement now.</h3>
//                             <p className="font-sm text-muted mb-30">Please fill in below information to add an dvertisement. </p>
//                         </div>
//                         <form id="" autocomplete="off" className="login-register text-start row">
//                             <div className="form-group col-md-6"><label className="form-label">Name *</label><input name="Name" type="text" className="form-control" value=""/></div>
//                             <div className="form-group col-md-6"><label className="form-label">Logo</label><input required="" name="groupImage" type="file" className="form-control" value=""/></div>
//                             <div className="form-group col-md-6"><label className="form-label">Email *</label><input name="Name" type="email" className="form-control" value=""/></div>
//                             <div className="form-group col-md-6"><label className="form-label">Phone Number *</label><input name="Name" type="tel" className="form-control" value=""/></div>
//                             <div className="form-group col-md-12"><label className="form-label">Address </label><input name="Name" type="text" className="form-control" value=""/></div>
//                             <div className="form-group col-md-6"><label className="form-label">Company Weblink / Url</label><input name="Name" type="text" className="form-control" value=""/></div>
//                             <div className="form-group col-md-6">
//                                 <label className="form-label">No. of days to display advertisement</label>
//                                 <select className="form-control">
//                                     <option>10 Days</option>
//                                     <option>20 days</option>
//                                     <option>30 days</option>
//                                     <option>2 Months</option>
//                                     <option>3 Months</option>
//                                 </select>
//                             </div>
//                             <div className="form-group"><label className="form-label">Description</label><textarea rows="3" name="groupDesc" id="group_action_form" className="form-control"></textarea></div>
//                             <div className="form-group"><button className="btn btn-default hover-up w-100" type="submit" name="submit">Submit</button></div>
//                             <div className="text-muted text-center">Do you need support?<a href="/contactus">Contact Us</a></div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
//     )
//     }
// }
