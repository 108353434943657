import React , {useState, useEffect , Component} from 'react';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


import {useNavigate} from 'react-router-dom';
import axios from "axios";

import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const PostCreateFormModal = () => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [required, setRequired] = useState(true);
    const [displayNone, setDisplayNone] = useState(true);
    const [displayNone1, setdisplayNone1] = useState(true);
    const [isPostJob, setIsPostJob] = useState('1');
    const [isPostJobCheked, setIsPostJobCheked] = useState(true);
    const [btnText, setBtntxt] = useState('Post');
    const [allStateList, setAllStateList] = useState([]);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );
    const [convertedContent, setConvertedContent] = useState(null);
    const [jsJobsTypeData, setjsJobsType] = useState([]);
    
    const [multiselected, setSelected] = useState([]);

    const [stateListArray, setStateListArray] = useState([]);
    const [cityListArray, setCityListArray] = useState([]);

    const [preferedLocation_1, setPreferedLocation_1] = useState([]);
    const [preferedLocation_2, setPreferedLocation_2] = useState([]);
    const [preferedLocation_3, setPreferedLocation_3] = useState([]);


    const jobseeker_jobstype = async url => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setjsJobsType(json.data.results);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    };

    useEffect(() => {
      let html = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        setDisplayNone(false)
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        if(storedNames.usertype == 3){
          jobseeker_jobstype("https://us.jobchatbox.com/api//home/api/jobseeker_jobstype")
        } 
        if(storedNames.usertype == 6){
          jobseeker_jobstype("https://us.jobchatbox.com/api//home/api/recruiter_jobstype")
        } 

        (async () => {
          try {
            const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/states_list");
            if(states_list.data.status == 1){
                setStateListArray(states_list.data.data.results)
            }
            } catch (error) {
                console.log(error);
            }
        })();

        (async () => {
            try {
            const states_list = await axios.get("https://us.jobchatbox.com/api/home/api/states_list/multi");
            if(states_list.data.status == 1){
                setAllStateList(states_list.data.data.results)
            }
            } catch (error) {
            console.log(error);
            }
        })();


    }, []);

    const  getCityByStateCodeData  =  (statecode='0',apitype='usa_city_list',argPass) => {
        (async () => {
            try {
            const city_list = await axios.get("https://us.jobchatbox.com/api/home/api/"+apitype+"/"+statecode);
              if(city_list.data.status == 1){ 
                  //setCityListArray(city_list.data.data.results)
                  if(argPass == 1){ 
                    setPreferedLocation_1(city_list.data.data.results)
                  } else if(argPass == 2){
                    setPreferedLocation_2(city_list.data.data.results)
                  } else if(argPass == 3){
                    setPreferedLocation_3(city_list.data.data.results)
                  } else {
                      setCityListArray(city_list.data.data.results)
                  }
              }
            } catch (error) {
            console.log(error);
            }
        })();
    }

    const getCityByStateCode = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','0');
    } 
  
    const getCityByStateCodeMulti1 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','1');
    } 

    const getCityByStateCodeMulti2 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','2');
    } 

    const getCityByStateCodeMulti3 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','3');
    } 
  

    const navigate = useNavigate();
    
  
    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    const handleFileUpload = (e) => {
      e.preventDefault();

      let fileName = e.target.files[0].name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      var ImageExt  = ['jpeg' ,'JPEG' ,'png' ,'PNG'];
      var FileExtenstion  = ['pdf' ,'docx' ,'doc' ];
    //   setDisplayNone(true)
    //   setRequired(false)
    //   setIsPostJob('0')
      //if(FileExtenstion.includes(extension)){
        setDisplayNone(false)
        setRequired(true)
        setIsPostJobCheked(true)
        setIsPostJob('1')
      //}
    }


    const handleJobTypeChange  = (e) => {
      setDisplayNone(true)
      setRequired(false)
      setIsPostJob('0')
      if (e.target.checked) {
        setDisplayNone(false)
        setRequired(true)
        setIsPostJob('1')
        setIsPostJobCheked(true)
      } else {
        setIsPostJobCheked(false)
      }
    }


    const cancelCourse = () => { 
      document.getElementById("post_create_form").reset();
    }

    const reload = () => {
      window.location.reload(false);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {

        
          const formData = new FormData();
          var storedNames = JSON.parse(localStorage.getItem("userlogin"));
          formData.append('upload_post_file', event.target.upload_post_file.files[0]);
          formData.append('jobtype', event.target.jobtype.value);
         // formData.append('postTittle', event.target.post_tittle.value);
          formData.append('postTittle', 'post title');
          formData.append('ispostjob', event.target.ispostjob.value);
          formData.append('post_textarea', convertedContent);
          // formData.append('prefered_state', event.target.prefered_state.value);
          // formData.append('prefered_location', JSON.stringify(multiselected));
          formData.append('login_token', storedNames.login_token);

          var input = document.getElementsByName('user_prefered_state[]');
                  var user_prefered_array =  [];
                  for (var i = 0; i < input.length; i++) {
                      let a = input[i];
                      user_prefered_array[i] = a.value
                  }

              var input2 = document.getElementsByName('prefered_location[]');
              var prefered_location_array =  [];
              for (var i = 0; i < input2.length; i++) {
                  let a = input2[i];
                  prefered_location_array[i] = a.value
              }
          formData.append('user_prefered_state', user_prefered_array);
          formData.append('user_prefered_location', prefered_location_array ); 

          setLoading(true);
          setBtntxt('Please Wait...');
          try {
            let res = await fetch("https://us.jobchatbox.com/api/jobseeker/post_action", {
                method: "POST",
                body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
              (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;
              setTimeout(function() {
                 navigate('/loading');
               
                //cancelCourse()
                //setLoading(false);
               // setBtntxt('Post');
                //navigate('/dashboard?updated');
               // reload();
              }, 1000);
            } else {
              notify('error','Some error occured.');
            }
          } catch (err) { 
            console.log(err);
          }
        }
        setValidated(true);  
    }


    const [location_2_hide, set_location_2_hide] = useState(1);
    const handleClickLocation1 = (event) => {
        //location_2_hide
        event.preventDefault();
        let ddd  = (location_2_hide == 1) ? 2 : ((location_2_hide == 2) ? 3 : 1)
        set_location_2_hide(ddd)
    }
    const removeLocation2 = (event) => {
        event.preventDefault();
        set_location_2_hide(1)
    }

    const removeLocation3 = (event) => {
        event.preventDefault();
        set_location_2_hide(2)
    }

    var storedNames = JSON.parse(localStorage.getItem("userlogin"));


    
    //multi search code 
    // Array of all options
    return (
      <>
        <ToastContainer />
        <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="post_create_form" className="login-register text-start" autoComplete="off">
            <div className="row">

               
                    <Form.Control
                        type="hidden"
                        name="post_tittle"
                        defaultValue="post title"
                    />

                    <div className="col-md-12">
                        <div className="form-group">

                            {/*<label className="form_label mt-2 mb-0" htmlFor="input-1">Message</label>*/}
                            {
                                    <Editor
                                    editorStyle={{ height: '150px' }}
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class1"
                                    toolbar={{
                                    options: ['inline', 'fontSize',  'list', 'textAlign',  'link',  'emoji', 'history'] ,
                                    }}
                                />
                            }
                            {/* <textarea className="form-control"></textarea> */}
                            <div className="errorMsg"></div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="form_label mb-0">{ storedNames.usertype == 6 ? 'Choose file' : 'Upload Resume,Photo' } </label>
                        <input type="file"  name="upload_post_file" onChange={handleFileUpload} accept="image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"  className="form-control upload_post_file" />
                    </div>
                
                    <div  className={ displayNone ? 'col-md-6 jobtyle_select_div  display_none' : 'col-md-6 jobtyle_select_div' } >
                        <label className="form_label mb-0">Status For Post/Join *</label>
                        <Form.Select   required={required}  className="form-control no-pad" name="jobtype" id="jobtype" aria-label="Default select example">
                        <option value="" >Select</option> 
                            {
                            jsJobsTypeData.map((item,index) => (
                                <option key={index} value={item.index}>{item.job_type_value}</option>
                            ))
                            }
                        </Form.Select>
                    </div>



                         {/* Location for address prefered */}  
                        <div  className={ displayNone ? 'display_none' : 'col-md-6 mb-0' } >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered State 1 { required}*</label>
                                <Form.Select 
                                    required={required} 
                                    className="form-control no-pad" 
                                    onChange={getCityByStateCodeMulti1} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    stateListArray.map((item,index) => (
                                        <option   key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        
                        <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }  >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered City 1*</label>
                                <Form.Select 
                                    required={required}
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                          preferedLocation_1.map((item,index) => (
                                              <option   key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                          ))
                                        }
                                </Form.Select>
                            </div>
                        </div>

                        {/* *************** */}
                         {/* Location for address prefered 2 */}
                        
                         <div  className={  (location_2_hide == 1 || location_2_hide == 3 || displayNone) ? 'display_none' : ''  } >
                            <a onClick={removeLocation2} className="remove_location_2 remove_location_button">Remove Location 2</a>
                        </div>

                        <div className={ (location_2_hide == 1 || displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered State 2</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    onChange={getCityByStateCodeMulti2} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state2" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    stateListArray.map((item,index) => (
                                        <option  key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className={ (location_2_hide == 1 || displayNone) ? 'display_none' : 'col-md-6 mb-0' }   >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered City 2</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location2" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                        preferedLocation_2.map((item,index) => (
                                            <option key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select> 
                            </div>
                        </div>

                        {/* Location for address prefered 3 */}
                        
                        <div className={  (location_2_hide == 1 || location_2_hide == 2 || displayNone) ? 'display_none' : ''  }  >
                            <a onClick={removeLocation3} className="remove_location_3 remove_location_button">Remove Location 3</a>
                        </div>

                        <div className={ (location_2_hide == 1 || location_2_hide == 2 || displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered State 3</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    onChange={getCityByStateCodeMulti3} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state3" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    stateListArray.map((item,index) => (
                                        <option  key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className={ (location_2_hide == 1 || location_2_hide == 2 || displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor="input-7">Prefered City 3</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location3" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                        preferedLocation_3.map((item,index) => (
                                            <option   key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select> 
                            </div>
                        </div>

                        <div>
                            <button  className={  (location_2_hide == 3 || displayNone) ? 'display_none' : 'btn btn-primary add_more_location_btn'  } onClick={handleClickLocation1} >Add More Location+</button>
                        </div>
                        {/* end  */}
                              

                <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 form-group">
                  <label className={ displayNone ? 'checkbox-inline  mt-10' : 'checkbox-inline'} >
                    <input className="ispostjob_checkbo" type="checkbox" checked={isPostJobCheked}  onChange={handleJobTypeChange} name="ispostjob" id="ispostjob_checkbo"  value={isPostJob}  />
                    This post is related to Job
                  </label>
                </div>

                <div className="col-md-12">
                    <div className="form-group mt-2">
                        <button disabled={loading} className="btn btn-brand-1 hover-up w-100" type="submit" name="login">{btnText}</button>
                    </div>
                </div>
            </div>
        </Form>
      </>
    );
  };
export default PostCreateFormModal;