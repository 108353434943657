import React, { useState ,useEffect } from 'react';
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const Forgot_Password = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem('userlogin');
    if(auth){
        navigate('/')
    }
  });
  


  const [email , setEmail] = useState("");
  const [responceMessgae , setResponceMessgae] = useState("");
  const [responceStatus , setResponceStatus] = useState(0);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginFormData = {
      email : email ,
    }

    try {
      let res = await fetch("https://us.jobchatbox.com/api/home/signup/forgot_password", {
          method: "POST",
          body: JSON.stringify(loginFormData),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log(resJson);

        if(resJson.status == 1){
            setResponceMessgae(resJson.message);
            setResponceStatus('success');
            setTimeout(function() {
                navigate('/reset-password?genrate_id='+resJson.data.id);
            }, 1000);
           } else {
            notify('warning','Email Id or Password is wrong.');
            setResponceMessgae(resJson.message);
            setResponceStatus('failed');

          }
      } else {
          notify('error','Some error occured.');
      }
      } catch (err) {
      console.log(err);
      }
  }


  const notify = (status_type,message) => {
    switch (status_type) {
        case 'warning':
            toast.warning(message)  
        break;
  
        case 'success':
            toast.success(message)  
        break;
  
        case 'info':
            toast.info(message)  
        break;
  
        case 'error':
            toast.error(message)  
        break;
    }
  };


  const validateForm = () => {      
    let formIsValid = true;
    let errors = {};

    if (!email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

  }
  return (
      <>
      <ToastContainer />
      <section className="pt-70 login-register">
        <div className="container"> 
            <div className="row login-register-cover pb-70">
            <div className="col-lg-4 col-md-4 col-sm-12">
           </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                {/* <p className="font-sm text-brand-2">Forgot Password</p> */}
                <h2 className="mt-10 mb-5 text-brand-1">Forgot Password</h2>
                <p className="font-sm text-muted mb-30">Enter the email address associated with your account and we will send you a link to reset your password. </p>
                 
                
              </div>
              {
                responceStatus == 'success' ? 
                <><div class="alert alert-success">{responceMessgae}</div></>
                : 
                <></>
              }
              {
                responceStatus == 'failed' ? 
                <><div class="alert alert-warning">{responceMessgae}</div></>
                : 
                <></>
              }
              
              
              <form onSubmit={handleSubmit} className="login-register text-start mt-20" action="#">
                
                <div className="form-group">
                  <label className="form-label" for="input-2">Email *</label>
                  <input className="form-control" id="input-2" type="email" required="" name="emailaddress" placeholder="stevenjob@gmail.com"  onChange = { (e) => setEmail(e.target.value)} />
                </div>
                
                
                
                
                <div className="form-group">
                  <button className="btn btn-brand-1 hover-up w-100" type="submit" name="login">Continue</button>
                </div>
                <div className="text-muted text-center">Do not have an account? 
                    <Link to="/register"> Sign Up</Link>
                </div>
              </form>
            </div>
           </div>
           <div className="col-lg-4 col-md-4 col-sm-12">
           </div>

         
        </div>
      </section>
           
      </>
    );
  };
export default Forgot_Password;