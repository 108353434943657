import React,{useState,useEffect} from "react";
import axios from "axios";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HappyCustomer = () => {

   const [allPostList, setAllPostList] = useState([]);
   const [baseUrl, setBaseurl] = useState('');
   useEffect(() => { 
      (async () => {
         try {
               const getdata = await axios.get("https://us.jobchatbox.com/api/cms/testimonial");
               setAllPostList(getdata.data.data.testimonial_data);
               setBaseurl(getdata.data.data.base_url);
         } catch (error) { console.log(error); }
      })();
   }, []);

    return(
        <section className="section-box overflow-visible pt-50 pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="testimonialsec">
                 <h6 className="headtop clr-primary mb-10 text-center">HAPPY CUSTOMERS</h6>
                <h2 className="section-title mb-40 text-center">Don't take our word for it. See what<br/> customers are saying about us.</h2>

                <div className="row testimonialsecinner">
                  <div className="col-md-12">
                     {
                        allPostList.length  ? 
                        <>
                        <OwlCarousel className='owl-theme' loop margin={0} items={1} dots={'false'} nav>
                        {
                                    
                                    allPostList.map((itmeData,index) => (
                                       <>
                                          {
                                          <>
                                          <div className="item">
                                             <div className="row">
                                                <div className="col-md-5">
                                                  
                                                   <img src={baseUrl+'/'+itmeData.image_url} /> 
                                                </div>
                                                <div className="col-md-7">
                                                   <div className="starrating">
                                                      <i className="fa fa-star" aria-hidden="true"></i>
                                                      <i className="fa fa-star" aria-hidden="true"></i>
                                                      <i className="fa fa-star" aria-hidden="true"></i>
                                                      <i className="fa fa-star" aria-hidden="true"></i>
                                                      <i className="fa fa-star" aria-hidden="true"></i>
                                                   </div>
                                                   <p>{itmeData.discription}</p>
                                                   <h4 className="text-capitalize">{itmeData.name}</h4>
                                                   <p>{itmeData.designation}</p>
                                                </div>
                                             </div>
                                          </div>
                                          </>
                                          }
                                       </>
                                    ))
                                 }
                        </OwlCarousel>
                        </>
                        :''
                     }

                    
                  </div>
               </div>

              </div>

            </div>
            
          </div>
          
        </div>
      </section>
        );
}

export default HappyCustomer;