import React,{useState,useEffect} from "react";
import LeftSidebar from '../include/left_sidebar';
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AppliedJobs = () => {
   
   
   const [appliedJobs, setAppliedJobs] = useState([]);
   const [noteData, setNoteData] = useState(
                                            {
                                                'hr_name' : '' ,
                                                'hr_date' : '' ,
                                                'hr_time' : '' ,
                                                'description' : '' 
                                             }
                                          );
   const [appliedJobId, setAppliedJobId] = useState('');

   const [totalResultCount, setTotalResultCount] = useState(1);
    const [paginationLenght, setPaginationLenght] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [startPage , setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);


   const auth = localStorage.getItem('userlogin');
   let userdata  = JSON.parse(auth);
   
   useEffect(() => {  window.scrollTo(0, 0) }, [])

   
   const getjobdatafun = async currentPage => {
      console.log('calling....');
      (async () => {
         try {
         const jobdata = await axios.get("https://us.jobchatbox.com/api/home/api/applied_jobs/"+userdata.id+"?currentpage="+currentPage+"&search="+searchValue);
         if(jobdata.data.status == 1){
           setAppliedJobs(jobdata.data.data.applied_jobs);
           setPaginationLenght(jobdata.data.total_page_lenght);
           setTotalResultCount(jobdata.data.total_count);
           setStartPage(jobdata.data.start_point);
           setEndPage(jobdata.data.end_point);
         }
         } catch (error) {
         console.log(error);
         }
     })();
  };


   useEffect(() => {
      getjobdatafun(currentPage)
   }, [currentPage ,searchValue ]);

   const deleteMyJobs = (e) => {
      if (window.confirm('Do you want to remove job from list ?') == true) {
          //alert(e.target.id);
          let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        const  API =  'https://us.jobchatbox.com/api/home/api/applied_job_remove';
        const formData = new FormData();
        formData.append('applied_job_id', e.currentTarget.id);
        formData.append('user_id', userdata.id );
        formData.append('user_type', userdata.usertype );
        formData.append('login_token', userdata.login_token );
        (async () => {
            try {
                let res = await fetch(API , {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                if(resJson.status){
                    setAppliedJobs(resJson.data.applied_jobs)
                }
            } catch (err) {
                console.log(err);
            }
        })();
       }
   }

   const notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;

          case 'success':
              toast.success(message)  
          break;

          case 'info':
              toast.info(message)  
          break;

          case 'error':
              toast.error(message)  
          break;
      }
   };

   /********modal show and hide*********/
   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {

      setAppliedJobId(e.target.id);

      const formData = new FormData();
      var storedNames = JSON.parse(localStorage.getItem("userlogin"));
         formData.append('user_id', storedNames.id);
         formData.append('applied_job_id', e.target.id);
         (async () => {
            try {
               let res = await fetch( 'https://us.jobchatbox.com/api/home/api/get_hr_note' , {
                  method: "POST",
                  body: formData ,
               });
               let resJson = await res.json();
              
               if(resJson.status == 1){
                  setNoteData({
                     'hr_name' : resJson.data.hrnotdata.hr_name ?? '' ,
                     'hr_date' : resJson.data.hrnotdata.hr_date ?? '' ,
                     'hr_time' : resJson.data.hrnotdata.hr_time  ?? '',
                     'description' : resJson.data.hrnotdata.description ?? ''
                  });
                  setShow(true)
               }
            } catch (err) {
               console.log(err);
            }
      })();
      
  };

  /******/
  const [validated, setValidated] = useState(false);
  const  handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation(); 
      } else {
         //alert()
         const formData = new FormData();
         var storedNames = JSON.parse(localStorage.getItem("userlogin"));
          formData.append('user_id', storedNames.id);
          formData.append('applied_job_id', appliedJobId);
          formData.append('hr_name', event.target.hr_name.value);
          formData.append('hr_date', event.target.hr_date.value);
          formData.append('hr_time', event.target.hr_time.value);
          formData.append('description', event.target.description.value);
          (async () => {
               try {
                  let res = await fetch( 'https://us.jobchatbox.com/api/home/api/applied_job_hr_note' , {
                     method: "POST",
                     body: formData ,
                  });
                  let resJson = await res.json();
                  (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
               } catch (err) {
                  console.log(err);
               }
         })();
      }
      setValidated(true);
  }

  const  PaginationLenghtCompnent = () => {
      const pageNumbers = [];
         for (let i = 1; i <= paginationLenght; i++) {
            pageNumbers.push(
                  <li className="ml-1"><a onClick={() => setCurrentPage(i)}  className={currentPage === i ? 'pager-number active' : 'pager-number'} href="#"  >{i}</a></li>
         );
      }
      return pageNumbers;
   }

   
   const  searchAppliedJob  = (e) => {
        let value = e.target.value ;  setSearchValue(value) ; 
   }


    return (
      <>
       <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                     <div className="row">
                        <div className="col-12"> 
                           <div className="head-border mb-10 applied_heading_seaction">
                              <h5 className="applied_heading">Applied Jobs</h5>
                              <div className="topsearch"  >
                                 <form className="form-search">
                                    <input className="searchboxx" onChange={searchAppliedJob} type="text" placeholder="Search..."  />
                                 </form>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div className="row display-list">
                        {
                           appliedJobs.length ? 
                           <>
                            {
                              appliedJobs.map((jobItem,index) => (
                                 <>
                                    <div key={jobItem.id} className="col-xl-12 col-12">
                                           <div className="card-grid-2 hover-up alliedjobgrid"><span className="flash"></span>
                                           <div className="row">
                                               <div className="col-lg-6 col-md-6 col-sm-12">
                                               <div className="card-grid-2-image-left">
                                                   <div className="image-box">
                                                       <img className="profile_pic" src={`https://us.jobchatbox.com/api/uploads/users/${jobItem.companydata.profile_pic}`} alt="jcbapp"  />
                                                   </div>
                                                   <div className="right-info"> 
                                                      <Link className="name-job text-capitalize" to={`/recruiter-details?candidate=${jobItem.companydata.unique_id}`}> 
                                                         {jobItem.companydata.company_name}
                                                      </Link>
                                                       <span className="location-small">{jobItem.location_id+' '+jobItem.state_id}</span> 
                                                   </div>
                                               </div>
                                               </div>
                                               <div className="col-lg-12 text-start text-md-end pr-60 col-md-12 col-sm-12">
                                                       {
                                                           jobItem.job_skills.map((skill_item,index2) => (
                                                              (index2 < 4) ? <><Link key={index2+1} className="btn btn-grey-small mr-5 text-capitalize mb-5" target="_blank" to={`/jobs-search?skills=${skill_item}`} >{skill_item}</Link></> : <></>
                                                           ))
                                                       }
                                               </div>
                                           </div>
                                           <div className="card-block-info">
                                               <h6><Link to={`/job-details?jobid=${jobItem.id}`} className='text-capitalize'>{jobItem.job_title}</Link></h6>
                                               <div className="mt-5 mb-5"><span className="card-briefcase">{jobItem.employment_type}</span><span className="card-time"><span>{jobItem.create_date}  ago</span></span></div>
                                               <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Location :</strong>{jobItem.city_data+' ('+jobItem.state_data+')'}</p></div>
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                                               </div>
                                               <div className="row">
                                                   <div className="col-lg-3 col-3"><span className="card-text-price">
                                                   <h6>{jobItem.salary_range}</h6> </span></div>
                                             
                                                   <div className="col-lg-9 col-9 text-end">
                                                       {
                                                         <>
                                                         <div className={`btn btn-apply-now  ${jobItem.note_added == 1 ? 'btn-success' : ''}`} id={jobItem.apply_job_data.id} onClick={handleShow} > {jobItem.note_added == 1  ?  'Notes added' :  'Add Note'} </div>
                                                         <button className="btn btn-apply-now ml-5" id={jobItem.apply_job_data.id}  onClick={deleteMyJobs} ><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                                                         </>
                                                       }
                                                   </div>
                                               </div>
                                           </div>
                                           </div>
                                       </div>
                                 </>
                              ))
                            }
                           </> 
                           : 
                           <>
                              <h3 className="text-center"  >You have no applied job list</h3>
                           </> 
                        }
                     </div> 

                     {
                        appliedJobs.length ?
                        <>
                           {totalResultCount > 10 &&
                           <div class="paginations">
                              <ul class="pager">
                                 <li><a   onClick={() => setCurrentPage(currentPage - 1)}   className={ (currentPage === 1) ? 'pager-prev disabled_page_link active' : 'pager-prev'} href="#"   ></a></li>
                                 <PaginationLenghtCompnent />
                                 <li><a onClick={() => setCurrentPage(currentPage + 1)}  className={ (currentPage === paginationLenght) ? 'disabled_page_link pager-next active' : 'pager-next' }  href="#"  ></a></li>
                              </ul>
                           </div>}
                        </>
                        : '' 
                     }
                     
                  </div>
               </div>
            </div>
        </div>
    
         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Add/Edit Note</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
               <Form  noValidate validated={validated} onSubmit={handleSubmit}  id="post_create_form" className="login-register text-start" autoComplete="off">
                  <div className="row" >
                     <div className="col-md-12">
                        <div className="form-group mb-1">
                        <label className="form_label mb-0">Hr Name</label>
                        <Form.Control
                           type="text"
                           name="hr_name"
                           defaultValue= {noteData.hr_name}
                        />
                        </div>
                     </div>
                     <div className="col-md-12">
                           <div className="form-group mb-1">
                           <label className="form_label mb-0">Date</label>
                           <Form.Control
                              required 
                              type="date"
                              name="hr_date"
                              defaultValue= {noteData.hr_date}
                           />
                           </div>
                     </div>
                     <div className="col-md-12">
                           <div className="form-group mb-1">
                           <label className="form_label mb-0">Date Time</label>
                           <Form.Control
                              type="time"
                              name="hr_time"
                              defaultValue= {noteData.hr_time}
                           />
                           </div>
                     </div>
                     <div className="col-md-12">
                           <div className="form-group mb-1">
                              <label className="form_label mb-0">Description</label>
                              <textarea required name="description"  rows="3" style={{minHeight:  '100px'}} defaultValue={noteData.description} ></textarea>
                           </div>
                     </div>

                     <div className="col-md-12">
                     <div className="form-group mt-2">
                           <button className="btn btn-brand-1 hover-up w-100" type="submit" name="login">Add Note</button>
                     </div>
                     </div>
                  </div>
               </Form>
            </Modal.Body>
         </Modal>

      </>
    );
  };
export default AppliedJobs;