import React, { useState ,useEffect } from 'react';
import { Nav, NavLink, NavMenu } from "../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { GoogleLogin } from 'react-google-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GmailLoginApp from './GmailLoginApp';

const Login = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem('userlogin');
    if(auth){ navigate('/') }
  } , []); ;
  
  const [email , setEmail] = useState("");
  const [password , setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const   getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
  }

  useEffect(() => {
    const storedUsername = getCookie('login_username');
    const storedPassword = getCookie('login_password');
    const storedRememberMe = getCookie('login_rememberMe');
   
    // console.log(storedUsername);
    if (storedRememberMe === 'true') {

      setEmail(storedUsername || '');
      setPassword(storedPassword || '');
      setRememberMe(true);
    }
  }, []);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginFormData = {
      email : email ,
      password : password 
    }

    try {
      let res = await fetch("https://us.jobchatbox.com/api/home/api/login", {
          method: "POST",
          body: JSON.stringify(loginFormData),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log(resJson);
          console.log(res.status);
          if(resJson.status == 1){
            toast.success('Loggedin successfully.');

            if (rememberMe) {
              console.log('stored')
              document.cookie = "login_username="+email ;
              document.cookie = "login_password="+password ;
              document.cookie = "login_rememberMe="+rememberMe ;

            } else {
              console.log('not stored')
              document.cookie = "login_username= ; expires = Thu, 01 Jan 1970 00:00:00 GMT" ;
              document.cookie = "login_password= ; expires = Thu, 01 Jan 1970 00:00:00 GMT" ;
              document.cookie = "login_rememberMe= ; expires = Thu, 01 Jan 1970 00:00:00 GMT" ;

            }


            if(resJson.data.user_data.usertype  == 3){
              localStorage.setItem('userlogin',JSON.stringify(resJson.data.user_data))
              localStorage.setItem('userloginSession',JSON.stringify(resJson.data.user_data))
              

              setTimeout(function() {
                navigate('/dashboard');
              }, 1000);
            } else if(resJson.data.user_data.usertype  == 6){
                localStorage.setItem('userlogin',JSON.stringify(resJson.data.user_data))
                localStorage.setItem('userloginSession',JSON.stringify(resJson.data.user_data))
                setTimeout(function() { navigate('/dashboard'); }, 1000);
            } else {
                toast.warning('Something went wrong!');
                setErrorMessage('There is no information found from your credentials');
                setTimeout(function() { setErrorMessage(''); }, 5000);
                navigate('/')
            }
            
          } else {
            setErrorMessage(resJson.message);
            setTimeout(function() { setErrorMessage(''); }, 5000);
            toast.warning(resJson.message);
          }
      } else {
        setErrorMessage(resJson.message);
        setTimeout(function() { setErrorMessage(''); }, 5000);
        //toast.error('Some error occured!');
      }
    } catch (err) { 
      console.log(err); 
    }
  }
 
  const validateForm = () => {      
    let formIsValid = true;
    let errors = {};

    if (!email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
  
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

  }


  const handleRememberMeChange = () => {
    //alert()
    setRememberMe(!rememberMe);
  };
  

  const [showPassword, setShowPassword] = useState(false);
  const hideAndShowPass = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);

  }
  
  return (
      <>
      <ToastContainer />
      <section className="pt-30 login-register">
        <div className="container"> 
            <div className="row login-register-cover pb-70">
              <div className="col-lg-4 col-md-4 col-sm-12">
              </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="formwraper">
              <div className="text-center">
                <p className="font-sm text-brand-2">Welcome back! </p>
                <h3 className="mt-10 mb-5 text-brand-1">Member Login</h3>


                <GoogleOAuthProvider clientId="1065101199341-jg32tco3n0s9t84if1ukkd345vg130kf.apps.googleusercontent.com">
      <GmailLoginApp />
    </GoogleOAuthProvider>

                
                <div className="divider-text-center"><span>Or continue with</span></div>
              </div>
              <form onSubmit={handleSubmit} className="login-register text-start mt-20" action="#">
                <div className="form-group">
                  <label className="form-label" htmlFor="input-1">Username or Email address *</label>
                  <input 
                  className="form-control" id="input-1" type="text" required="" 
                  name="fullname" placeholder="Steven Job" 
                  value={email}
                  onChange = { (e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group passwordwrap">
                  <label className="form-label" htmlFor="input-4">Password *</label>
                  <input 
                  className="form-control" 
                  id="input-4" 
                  type={showPassword ? 'text' : 'password'} 
                  required="" 
                  name="password" 
                  placeholder="************" 
                  value={password}
                  onChange = { (e) => setPassword(e.target.value)}
                  />
                  <span class="eyebtn"  onClick={hideAndShowPass} ><img src="front/assets/imgs/eye.svg" /></span>
                </div>
                <div className="login_footer form-group d-flex justify-content-between">
                  <label className="cb-container">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    <span className="text-small">Remember me</span><span className="checkmark"></span>
                  </label> 
                  <NavLink className="text-muted" to="/forgot-password">Forgot Password</NavLink>
                </div>
                <div className={`form-group ${errorMessage ? 'mb-0' : ''}`}>
                  <button className="btn btn-brand-1 hover-up w-100 mb-0" type="submit" name="login">Login</button>
                </div>
                {
                  errorMessage ? <><p class="text-danger text-center error_responce" >{errorMessage}</p></> : ''
                }
                
                <div className="text-muted text-center">Don't have an Account? 
                    <NavLink  to="/register" className={'signupbtn'}  > Sign up</NavLink> 
                </div>
              </form>
              <div className="img-2 pt-70"><img src="front/assets/imgs/img-3.svg" alt="jcbapp" /></div>
            </div>
           </div>
           <div className="col-lg-4 col-md-4 col-sm-12">
           </div>

          </div>
        </div>
      </section>
           
      </>
    );
  };
export default Login;