import React  , {useEffect,useState} from 'react';
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';
import axios from "axios";
import { Link } from "react-router-dom";

const Faq = () => {

  useEffect(() => {  window.scrollTo(0, 0) }, [])

  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    (async () => {
          try {
              const getdata = await axios.get("https://us.jobchatbox.com/api/cms/faq");
              setFaqData(getdata.data.data.faq_data);
          } catch (error) { console.log(error); }
      })();
  }, []);
 
    return (
        <>
      <PageTopHeaderSec pageTitle='FAQ' PageLink='faq'  />
      
      <section className="section-box mt-50">
        <div className="post-loop-grid">
          <div className="container">
            <div className="text-center">
              <h6 className="f-18 color-text-mutted text-uppercase">fAQ</h6>
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Frequently Asked Questions</h2>
            </div>
            <div className="row mt-70">
                  <div className="accordion" id="accordionExample">

                    {
                      faqData.length  ? 
                      <>
                        {
                           faqData.map((item,index) => (
                             <>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingOne_${index}`}>
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne_${index}`} aria-expanded="true" aria-controls={`collapseOne_${index}`}>
                                    {index+1}. {item.faq_title}
                                  </button>
                                </h2>
                                <div id={`collapseOne_${index}`} className={ index == 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse' }  aria-labelledby={`headingOne_${index}`} data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                  <div dangerouslySetInnerHTML={{__html: item.faq_description}} ></div>
                                  </div>
                                </div>
                              </div>
                             </>
                           ))
                        }
                      </>
                      : ''
                    }
                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          1. Is registration on the Career hamster free?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>Yes. Registration is free for job seekers, Vendors (Only to register) and for Recruiters connect +</p>
                          <p>Note: For any further Enquires - Please mail us (<a href="mailto:info@careerhamster.com">info@careerhamster.com</a>) </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          2. I'm new to the Career Hamster . How can I register ?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>Firstly Create an Account which is an Primay intiative (Complete all the required information  then click on the "Register Now" button)</p>
                          <p>Then register as a Job Seeker - Login into the Job seekers dash Board</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          3. How does the Jobs Portal work for job seekers?
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>Registered Job Seekers can subscribe to job adverts and job categories. You will also have an on-CV / RESUME  profile that can be searched by job advertisers and Recruiters. We advise job seekers to complete their online CV profile so that they can be included in our website's Job Seeker Search. </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          4. What can I do so that multiple employers will contact me?
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>Ensure that you complete and update your online CV / RESUME profile and upload your CV / RESUME in MSword or pdf format.</p>
                          <p>NOTE:</p>
                          <ol>
                          <li>If you have an existing CV/ RESUME - you can go to Upload - option </li>
                          <li>If you have already created ONLINE Resume you need not upload it.</li>
                          <li>Or the Recruiter might ask to Upload the CV / RESUME - through MAIL.</li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          5. How do I search for job adverts on the job's portal?
                        </button>
                      </h2>
                      <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>Use our Job Search Filter  which is on the HOME PAGE . This option lets you filter the job adverts by location, industry and/or job type. After selecting your preferences click on the "FIND NOW" button.</p>
                          <p>The other option is to type the keywords you want to search by into the text box under Job Keyword Search. Click on "FIND NOW" after you have typed in your keyword/s </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          6. How do I post jobs as a VENDOR ?
                        </button>
                      </h2>
                      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>VENDOR - Register First - Go thorugh the Pricing ( It might be free some times as promotional activity) - Pick a Plan - and you are good to go with Job Posting.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          7. How do I receive Free Email Alerts?
                        </button>
                      </h2>
                      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>You need to be register as a Job Seeker and logged in.</p>
                          <p>On your right you will find ''Register as a Job seeker'' to receive the Job Alerts.</p>
                          <p>We would also send Emails regarsd to walking and Off campus Drives ( If Informed by the company)</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                          8.  I forgot my password, how can I reset it?
                        </button>
                      </h2>
                      <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>To reset your password, go to Request a New Password</p>
                          <p>Type you email address that you used to create your user account and click on "Email new password" Then follow the easy steps as the email explains.</p>
                        </div>
                      </div>
                    </div> */}


                  </div>
            </div>

            <div className="text-center mt-30">
                <h6>Have Any Queries, Please Contact us through Email.</h6>
            </div>
          </div>
        </div>
      </section>

      <SubscribeModule />
        </>
    );
  };
export default Faq;