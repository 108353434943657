import React,{useEffect,useState} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import axios from "axios";

const Connection_conversation = () => {


   useEffect(() => {  window.scrollTo(0, 0) }, [])
   const [groupDataArr, setGroupDataArr] = useState([]);
   const [connectionId, setConnectionId] = useState(0);

   const [groupConversation, setGroupConversation] = useState([]);
   
   const [searchValue, setSearchValue] = useState('');
   const [connectionMembers, setConnectionMembers] = useState([]);
   const [connectionMembersCopy, setConnectionMembersCopy] = useState([]);

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));

   const getConnectionData = (connectionid) => {
         (async () => {
         try {
            const conn_data = await axios.get(`https://us.jobchatbox.com/api/user/api/my_connections/${storedNames.login_token}/${connectionid}`);
            if(conn_data.data.status == 1){ 
               setConnectionMembers(conn_data.data.data.myconnection);
               setConnectionMembersCopy(conn_data.data.data.myconnection);
               setGroupConversation(conn_data.data.data.default_group_chat);
               const members =  conn_data.data.data.myconnection; 
               //console.log(members.length)
               if(members.length){
                  checkConnectionConversation(parseInt(members[0].userdetails.id));
               }
            }
         } catch (error) { console.log(error); }
      })();
   }

   useEffect(() => {
      var connectionid = window.location.search.split('connectionid=')[1];
      getConnectionData(connectionid);
     // setConnectionId(connectionid);

      //const interval = setInterval(() => { getConnectionData(connectionida)  }, 6000); return () => clearInterval(interval);
      //group_details
   },[]);  

   const addMessageInConversation =  async (login_token,groupid,groupCode,chat_message,user_id,send_by_id) => {
      const formData = new FormData();
      formData.append('logintoken', login_token );
      formData.append('groupid', storedNames.id );
      formData.append('conversationType', 'connection' );
      formData.append('groupcode', storedNames.id );
      formData.append('chat_message', chat_message );
      formData.append('encode_user_id', user_id );
      formData.append('send_by_id', send_by_id );
      try {
            let res = await fetch("https://us.jobchatbox.com/api/user/api/conversation_add_message", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  document.getElementById("groupchatvalue").value  = '';
                  setGroupConversation(resJson.data.default_group_chat);
               }
            } 
      } catch (err) {
            console.log(err);
      }
   }

   const messageSendToGroup = async (groupid,groupCode) => {
      let chat_message =  document.getElementById("groupchatvalue").value ;
      let send_by_id  = document.getElementById("form_send_by_id").value ; 
      //alert('hirr..');
      if(chat_message){
         addMessageInConversation(
            storedNames.login_token ,
            groupid ,
            groupCode ,
            chat_message ,
            storedNames.id ,
            send_by_id
         );
      }
   }

   const checkConnectionConversation = async (send_by_id) => {
     // document.getElementById("form_send_by_id").value = send_by_id ;
      setConnectionId(send_by_id);
      const formData = new FormData();
      formData.append('logintoken', storedNames.login_token );
      formData.append('groupid', storedNames.id );
      formData.append('conversation_type', 'connection' );
      formData.append('send_by_id', send_by_id );
      try {
            let res = await fetch("https://us.jobchatbox.com/api/user/api/get_connection_conversation", {
               method: "POST",
               body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
               if(resJson.status  == 1){
                  document.getElementById("groupchatvalue").value  = '';
                  setGroupConversation(resJson.data.default_group_chat);
               }
            } 
      } catch (err) {
            console.log(err);
      }
   }

   const filter = (e) => {
      const keyword = e.target.value;
  
      if (keyword !== '') {
        const results = connectionMembers.filter((user) => {
          return user.userdetails.firstname.toLowerCase().startsWith(keyword.toLowerCase());
          // Use the toLowerCase() method to make it case-insensitive
        });
        //console.log(results)
        setConnectionMembers(results);
        //setFoundUsers(results);
      } else {
         //connectionMembersCopy
         setConnectionMembers(connectionMembersCopy);
        // If the text field is empty, show all users
      }
  
      setSearchValue(keyword);
    };


    const handleKeyPress = (event , groupid,groupCode) => {
       if(event.key  == 'Enter'){
         messageSendToGroup(groupid,groupCode) ;
       }
    };


    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                  <div className="row">
                     <div className="col-12">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">

                           <h5>Connection Conversations</h5>
                           <Link class="btn btn-default btn-small ml-auto" to="/connections">
                              <i class="fa fa-arrow-left" aria-hidden="true"></i>Back To Groups
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div class="section-box jobpost-sec">
                     <div className="groupchatboxwrap">
                        <div className="">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="card" id="chat3">
                                    <div className="card-body">
                                       <div className="row">
                                          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                                             <div className="p-3 converleft">
                                                <div className="input-group rounded mb-3">
                                                   <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                                                      aria-describedby="search-addon" value={searchValue} onChange={filter} />
                                                   <span className="input-group-text border-0" id="search-addon">
                                                   <i className="fa fa-search"></i>
                                                   </span>
                                                </div>
                                                <div data-mdb-perfect-scrollbar="true">
                                                   <ul className="list-unstyled mb-0">
                                                      {
                                                         connectionMembers.length ?
                                                         <>
                                                            {
                                                               connectionMembers.map((item,index) => (
                                                                  <li className={`p-2 border-bottom ${(connectionId == item.userdetails.id) ? 'active' : ''} ${item.userdetails.id}`}  >
                                                                     <div  onClick={(e) => checkConnectionConversation(item.userdetails.id)} className="cursor_pointer d-flex justify-content-between">
                                                                        
                                                                        <div className="d-flex flex-row">
                                                                           <div>
                                                                              <img  src={item.userdetails.profile_pic_url} alt="avatar" className="chat_banner_img d-flex align-self-center me-3 chat_banner_img" width="60" />
                                                                              <span className="badge bg-success badge-dot"></span>
                                                                           </div>
                                                                           <div className="pt-1">
                                                                           <p className="fw-bold mb-0 text-capitalize">{item.userdetails.firstname} </p>
                                                                           <p className="small text-muted">
                                                                              {
                                                                                 item.userdetails.usertype == 3 ? 'Jobseeker' : ((item.userdetails.designation) ? item.userdetails.designation : 'Not found' )
                                                                              }
                                                                           </p>
                                                                           
                                                                           </div>
                                                                        </div>

                                                                     </div>
                                                                  </li>
                                                               ))
                                                            }
                                                         </>
                                                         : ''
                                                      }
                                                      

                                                   </ul>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-md-6 col-lg-7 col-xl-8">
                                             <div className="pt-3 pe-3 convright" data-mdb-perfect-scrollbar="true">
                                                
                                            
                                                {
                                                   groupConversation.length ? 
                                                   <>
                                                   {
                                                      groupConversation.map((item,index) => (

                                                         item.user_info.encode_user_id == storedNames.id ? 
                                                         <>
                                                         <div className="d-flex flex-row justify-content-start">
                                                            <img className='chat_banner_img' src={item.user_info.profile_pic_url} alt="avatar 1" />
                                                            <div>
                                                               <p className="small p-2 ms-3 mb-1 rounded-3">
                                                               {item.pMessage}
                                                               </p>
                                                               <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                                                               {item.time_ago}
                                                               </p>
                                                            </div>
                                                         </div>
                                                         </> 
                                                         :
                                                         <>
                                                          <div className="d-flex flex-row justify-content-end">
                                                            <div>
                                                               <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                                               {item.pMessage}
                                                               </p>
                                                               <p className="small me-3 mb-3 rounded-3 text-muted">{item.time_ago}</p>
                                                            </div>
                                                            <img className='chat_banner_img'  src={item.user_info.profile_pic_url} alt="avatar 1" />
                                                         </div>
                                                         </>
                                                         
                                                      ))
                                                   }
                                                   </>
                                                   :'Conversation is not found for this member'
                                                }
                                                
                                                {/* */}

                                             </div>
                                                   
                                             <div className="bottommessagesec text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                                                <img className='chat_banner_img' src={storedNames.profile_pic_url} alt="avatar 3" />
                                        
                
                                                <input type="text" className="form-control form-control-lg" id="groupchatvalue"
                                                   placeholder="Type message" onKeyPress={(e) => handleKeyPress(e , groupDataArr.id , groupDataArr.groupCode)} />
                                                <input type="hidden" id="form_send_by_id" value={connectionId} />
                                                {/* <a className="ms-1 text-muted" href="#!"><i class="fa fa-paperclip" aria-hidden="true"></i></a>
                                                <a className="ms-3 text-muted" href="#!"><i class="fa fa-smile-o" aria-hidden="true"></i></a> */}
                                                <span onClick={(e) => messageSendToGroup(groupDataArr.id , groupDataArr.groupCode)} className="ms-3" href="#!"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></span>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Connection_conversation;