import React , {useState, useEffect } from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';


import { EditorState,ContentState , convertToRaw , convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import {useNavigate} from 'react-router-dom';

const PostCreateForm = () => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnText, setBtntxt] = useState('Post');

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );

    
    const [convertedContent, setConvertedContent] = useState(null);
    const [jsJobsTypeData, setjsJobsType] = useState([]);
    
      const jobseeker_jobstype = async url => {
          const fetchData = async () => {
              try {
                  const response = await fetch(url);
                  const json = await response.json();
                  setjsJobsType(json.data.results);
              } catch (error) {
                  console.log("error", error);
              }
          };
          fetchData();
      };

      useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
      }, [editorState]);

      useEffect(() => {
        jobseeker_jobstype("https://us.jobchatbox.com/api//home/api/jobseeker_jobstype")
      }, []);

      const navigate = useNavigate();
     
    
      const notify = (status_type,message) => {
          switch (status_type) {
              case 'warning':
                  toast.warning(message)  
              break;

              case 'success':
                  toast.success(message)  
              break;

              case 'info':
                  toast.info(message)  
              break;

              case 'error':
                  toast.error(message)  
              break;
          }
      };

      const handleFileUpload = () => {
        //alert();
      }



    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {

        
          const formData = new FormData();
          var storedNames = JSON.parse(localStorage.getItem("userlogin"));
          formData.append('upload_post_file', event.target.upload_post_file.files[0]);
          formData.append('jobtype', event.target.jobtype.value);
          formData.append('postTittle', event.target.post_tittle.value);
          formData.append('post_textarea', convertedContent);
          formData.append('login_token', storedNames.login_token);
          setLoading(true);
          setBtntxt('Please Wait...');
          try {
            let res = await fetch("https://us.jobchatbox.com/api/jobseeker/post_action", {
                method: "POST",
                body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
              (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;
              setTimeout(function() { navigate('/dashboard');}, 1000);
            } else {
              notify('error','Some error occured.');
            }
          } catch (err) {
            console.log(err);
          }
        }
        setValidated(true);  
    }

    return (
      <>
           <ToastContainer />
          <div className="dashboardsec pt-70 pb-70">
           <div className="container">
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-6">
                <div className="form_create_wrap">

      
                        <Form  noValidate validated={validated} onSubmit={handleSubmit}  className="login-register text-start" autoComplete="off">
                            <div className="row">

                              <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label className='form_label mb-0'>Post Title</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="post_tittle"
                                  defaultValue=""
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              </Form.Group>



                                <div className="col-md-12">
                                    <div className="form-group">

                                        <label className="form_label mt-2 mb-0" htmlFor="input-1">Message</label>
                                        {
                                             <Editor
                                             editorState={editorState}
                                             onEditorStateChange={setEditorState}
                                             wrapperClassName="wrapper-class"
                                             editorClassName="editor-class"
                                             toolbarClassName="toolbar-class"
                                           />
                                        }
                                        {/* <textarea className="form-control"></textarea> */}
                                        <div className="errorMsg"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="form_label mb-0">Upload Resume,Photo,Video</label>
                                    <input type="file" name="upload_post_file" onChange={handleFileUpload}  className="form-control upload_post_file" />
                                </div>
                              
                                <div className="col-md-12 jobtyle_select_div " >
                                  <label className="form_label mt-2 mb-0">Status For Post/Join</label>
                                
                                  <Form.Select required className="form-control no-pad" name="jobtype" id="jobtype" aria-label="Default select example">
                                  <option value="" >Select</option> 
                                      {
                                        jsJobsTypeData.map((item,index) => (
                                          <option key={index} value={item.index}>{item.job_type_value}</option>
                                        ))
                                      }
                                  </Form.Select>


                                </div>

                                <div className="col-md-12">
                                    <div className="form-group mt-2">
                                        <button disabled={loading} className="btn btn-brand-1 hover-up w-100" type="submit" name="login">{btnText}</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                </div>
              </div>
              <div className="col-md-3">
                    <RightSidebar />
                </div>
            </div>
          </div>
        </div>
      </>
    );
  };
export default PostCreateForm;