import React,{useState,useEffect} from "react";
import LeftSidebar from '../include/left_sidebar';
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const FavoriteJobs = () => {
   
    useEffect(() => {  window.scrollTo(0, 0) }, [])
   const [appliedJobs, setAppliedJobs] = useState([]);
   const [totalResultCount, setTotalResultCount] = useState(0);
    const [paginationLenght, setPaginationLenght] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [startPage , setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

   const [noteData, setNoteData] = useState(
                                            {
                                                'hr_name' : '' ,
                                                'hr_date' : '' ,
                                                'hr_time' : '' ,
                                                'description' : '' 
                                             }
                                          );
   const [appliedJobId, setAppliedJobId] = useState('');
   const auth = localStorage.getItem('userlogin');
   let userdata  = JSON.parse(auth);



   const getjobdatafun = async currentPage => {
        (async () => {
            try {
            
            const jobdata = await axios.get("https://us.jobchatbox.com/api/home/api/favorite_jobs/"+userdata.id+"?currentpage="+currentPage+"&search="+searchValue);
                if(jobdata.data.status == 1){
                    setAppliedJobs(jobdata.data.data.applied_jobs);
                    setPaginationLenght(jobdata.data.total_page_lenght);
                    setTotalResultCount(jobdata.data.total_count);
                    setStartPage(jobdata.data.start_point);
                    setEndPage(jobdata.data.end_point);
                }
            } catch (error) {
            console.log(error);
            }
        })();
    };

    useEffect(() => {
        getjobdatafun(currentPage)
     }, [currentPage , searchValue]);
   
     const  searchAppliedJob  = (e) => {
            let value = e.target.value ;  setSearchValue(value) ; 
    }


   const fav_applied_jobs = (e) => {
      let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
      const  API =  'https://us.jobchatbox.com/api/home/api/favorite_job_apply';
      const formData = new FormData();
      formData.append('favourite_job_id', e.currentTarget.id);
      formData.append('user_id', userdata.id );
      formData.append('apply_by', 'favorite' );
      (async () => {
          try {
              let res = await fetch(API , { method: "POST", body: formData , });
              let resJson = await res.json();
              if(resJson.status){ setAppliedJobs(resJson.data.applied_jobs); notify('success',resJson.message)
              } else {  notify('error',resJson.message) }
          } catch (err) { console.log(err); }
      })();
  }

   const deleteMyJobs = (e) => {
      if (window.confirm('Do you want to remove job from list ?') == true) {
          //alert(e.target.id);
          let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        const  API =  'https://us.jobchatbox.com/api/home/api/applied_job_remove';
        const formData = new FormData();
        formData.append('applied_job_id', e.currentTarget.id);
        formData.append('user_id', userdata.id );
        formData.append('user_type', userdata.usertype );
        formData.append('login_token', userdata.login_token );
        formData.append('calling_type', 'favourite' );
        (async () => {
            try {
                let res = await fetch(API , {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                (resJson.status) ? notify('success',resJson.message) : notify('error',resJson.message) ;
                if(resJson.status){
                    setAppliedJobs(resJson.data.applied_jobs)
                }
            } catch (err) {
                console.log(err);
            }
        })();
       }
   }

   const notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;

          case 'success':
              toast.success(message)  
          break;

          case 'info':
              toast.info(message)  
          break;

          case 'error':
              toast.error(message)  
          break;
      }
   };


 
   const  PaginationLenghtCompnent = () => {
        const pageNumbers = [];
        for (let i = 1; i <= paginationLenght; i++) {
            pageNumbers.push(
                    <li className="ml-1"><a onClick={() => setCurrentPage(i)}  className={currentPage === i ? 'pager-number active' : 'pager-number'} href="#"  >{i}</a></li>
        );
        }
        return pageNumbers;
    }


    return (
      <>
       <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                     <div className="row">
                        <div className="col-12">
                           <div className="head-border mb-10 applied_heading_seaction">
                              <h5 className="applied_heading" >Favorite Jobs</h5>
                              <div className="topsearch"  >
                                 <form className="form-search">
                                    <input className="searchboxx" onChange={searchAppliedJob} type="text" placeholder="Search..."  />
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row display-list">
                        {
                           appliedJobs.length ? 
                           <>
                            {
                              appliedJobs.map((jobItem,index) => (
                                 <>
                                    <div key={jobItem.id} className="col-xl-12 col-12">
                                           <div className="card-grid-2 hover-up"><span className="flash"></span>
                                           <div className="row">
                                               <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="card-grid-2-image-left">
                                                    <div className="image-box">
                                                        <img className="profile_pic" src={`https://us.jobchatbox.com/api/uploads/users/${jobItem.companydata.profile_pic}`} alt="jcbapp"  />
                                                    </div>
                                                    <div className="right-info"> 
                                                        <a className="name-job text-capitalize" href="#">{jobItem.companydata.company_name}</a>
                                                        <span className="location-small">{jobItem.location_id+' '+jobItem.state_id}</span> 
                                                    </div>
                                                </div>
                                               </div>
                                               <div className="col-lg-12 text-start text-md-end pr-60 col-md-12 col-sm-12">
                                                    <div className="pl-15 mb-1 mt-1">
                                                            {
                                                                jobItem.job_skills.map((skill_item,index2) => (
                                                                    <Link key={index2+1} className="btn btn-grey-small mr-5 text-capitalize" to={`?skills=${skill_item}`} >{skill_item}</Link>
                                                                ))
                                                            }
                                                    </div>
                                               </div>
                                           </div>
                                           <div className="card-block-info">
                                               <h6><Link to="job-details" className='text-capitalize'>{jobItem.job_title}</Link></h6>
                                               <div className="mt-5 mb-5"><span className="card-briefcase">{jobItem.employment_type}</span><span className="card-time"><span>{jobItem.create_date}  ago</span></span></div>
                                               <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Location :</strong>{jobItem.city_data+' ('+jobItem.state_data+')'}</p></div>
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                                                   <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                                               </div>
                                               <div className="row">
                                                   <div className="col-lg-7 col-7"><span className="card-text-price">
                                                   <h6>{jobItem.salary_range}</h6> </span></div>
                                                 
                                                   <div className="col-lg-5 col-5 text-end">
                                                       {
                                                         <>
                                                         <div className="btn btn-apply-now" id={jobItem.apply_job_data.id} onClick={fav_applied_jobs} >Apply</div>
                                                         <button className="btn btn-apply-now ml-5" id={jobItem.apply_job_data.id}  onClick={deleteMyJobs} ><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                                                         </>
                                                       }
                                                   </div>
                                               </div>
                                           </div>
                                           </div>
                                       </div>
                                 </>
                              ))
                            }
                           </> 
                           : 
                           <>
                              <h3>Data Not found</h3>
                           </> 
                        }
                        


                     </div>
                     {
                        appliedJobs.length ?
                        <>
                           <div class="paginations">
                              <ul class="pager">
                                 <li><a   onClick={() => setCurrentPage(currentPage - 1)}   className={ (currentPage === 1) ? 'pager-prev disabled_page_link active' : 'pager-prev'} href="#"   ></a></li>
                                 <PaginationLenghtCompnent />
                                 <li><a onClick={() => setCurrentPage(currentPage + 1)}  className={ (currentPage === paginationLenght) ? 'disabled_page_link pager-next active' : 'pager-next' }  href="#"  ></a></li>
                              </ul>
                           </div>
                        </>
                        : '' 
                     }
                  </div>
               </div>
            </div>
        </div>
      </>
    );
  };
export default FavoriteJobs;